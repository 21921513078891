.showConnectingLine::after {
	content: '';
	background: #bbc4c4;
	z-index: 0;
	width: 2px;
	height: 40px;
	position: absolute;
	left: 49%;
}
.automation-card {
	.avatar-pic {
		width: 23px;
		height: 23px;
		min-height: 23px;
		min-width: 23px;
		background-color: #dadada;
		text-align: center;
		line-height: 24px;
		font-weight: 600;
		background: #77858d;
		color: white;
		font-size: 12px;
	}

	.automation-status-body {
		.list {
			.item {
				margin: 1rem 0;
			}
		}
	}
}
.automation-list-container {
	.avatar-pic {
		width: 45px;
		height: 45px;
		min-height: 23px;
		min-width: 23px;
		background-color: #dadada;
		text-align: center;
		line-height: 45px;
		font-weight: 600;
		background: #77858d;
		color: white;
		font-size: 25px;
		border-radius: 4px;
	}
	.btn {
		padding: 0.7rem 2rem;
	}
	.btn--edit {
		font-weight: bold;
	}
	.btn--add {
		border-color: #11a1ae;
	}
	.list {
		.item {
			background-color: #f3f7f9 !important;
			padding: 0.9rem 1.8rem;
			margin-bottom: 1.8rem;

			.more-icon {
				font-size: 30px;
				line-height: 1.5;
			}
			.dropdown--stage {
				top: 40px;
				right: 0px;
			}

			.automation-status-body-title {
				font-size: 18px;
				font-weight: bold;
				white-space: nowrap;
				width: 250px;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
.automation-container {
	.bot-container {
		> .bot-label {
			font-weight: 600;
			font-size: 18px;
		}
		> .bot-name {
			font-size: 18px;
		}
	}
	.modal__footer {
		.btn {
			height: fit-content;
		}
	}
	.tabs-container {
		padding: 0;
		margin: 0 2.5rem;
	}
	.action-wrap-body {
		.actions-details {
			padding: 0px;
		}
	}
	.status-toggle {
		float: right;
		position: inherit !important;
	}
	.sms-area {
		height: 130px;
		width: 100%;
	}
}
