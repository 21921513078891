.email-template-main {
	width: 90%;
	@media only screen and (max-width: 1350px) {
		width: 100%;
	}
	.header-section {
		margin-bottom: 0px !important;
		padding-bottom: 20px;
		border-bottom: 1px solid #dadada;
	}
	.support-link {
		color: #11a1ae;
		font-weight: bold;
		font-size: 16px;
		.support-link-icon {
			position: relative;
			font-size: 14px;
			font-weight: bold;
			top: 1px;
		}
	}
	.email-body-container {
		height: 100%;
		.email-list {
			border-right: 1px solid #dadada;
			padding-right: 15px;
			min-width: 290px;
			max-width: 290px;
			@include respond-to(mobile) {
				max-width: 100%;
				width: 100%;
			}
			&.modal-list {
				padding-right: 15px;
				padding-left: 15px;
			}
		}
		.global-settings_search-wrap {
			background: #ffffff;
		}
		.channel-icon {
			width: 20px;
		}
		.email-list-container {
			overflow-y: auto;
			height: calc(100vh - 280px);
			&.modal-list {
				height: calc(100% - 66px);
				padding-right: 0px;
				@supports (-webkit-touch-callout: none) {
					height: calc(100% - 70px);
				}
			}
		}
		.search-modal {
			padding-top: 15px;
		}
		.filters-card {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 10%;
			.content {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				line-height: 30px;
				gap: 10px;
				span {
					font-weight: bold;
					margin-bottom: -8px;
				}
				.template-btn {
					padding: 1rem 2.8rem !important;
					width: auto !important;
				}
			}
		}
	}
	.filters-card {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.content {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			line-height: 30px;
			gap: 10px;
			span {
				font-weight: bold;
			}
		}
	}
	.email-margin {
		margin-top: -30px;
	}
	.email-des {
		padding-left: 4rem;
		padding-top: 20px;
		height: calc(100vh - 198px);
		width: 100%;
		&.modal-list {
			height: 100%;
			padding: 1.5rem;
			padding-bottom: 0px;
			.email-template-description {
				height: 78%;
				overflow: auto;
				@include respond-to(mobile) {
					height: 75%;
				}
			}
		}
		.email-template-description {
			width: 100%;
			height: 85%;
			overflow: auto;
			color: var(--primary-text-color);
			font-weight: $medium;
			letter-spacing: 0.2px;
			line-height: 1.9rem;
			iframe {
				height: 100%;
				width: 100%;
				overflow: hidden;
			}
			pre > img {
				max-width: 250px;
				max-height: 250px;
			}
			ul {
				list-style: inherit;
				margin-left: 2rem;
				margin-left: 4rem;
				margin-top: 12px;
				margin-bottom: 12px;
			}
			ol {
				list-style: decimal;
				margin-left: 2rem;
				margin-left: 4rem;
				margin-top: 12px;
				margin-bottom: 12px;
			}
			li {
			}
			.subject-wrap {
				justify-content: space-between;
				.bolt-icon {
					color: gold;
					font-size: 18px;
				}
				.automated-text {
					color: #11a1ae;
					font-weight: 600;
				}
			}
		}
	}
	.email-desc-right {
		margin-right: 18rem !important;
	}

	.create-template-button {
		background: #f2f7f9;
		border-radius: 3px;
		padding: 5px 12px;
		font-weight: 500;
		text-align: center;
		&:disabled {
			background: #f2f7f9;
			opacity: 0.4;
			cursor: not-allowed;
		}
	}

	.btn--cancel,
	.btn--primary {
		padding: 5px 10px;
	}
	.email-edit-btn {
		background: #c9c9c9;
		color: #ffffff;
	}
	.email-title-design {
		.email-search-box {
			margin-bottom: 10px;
			width: 209px;
			padding-left: 1rem !important;
			margin-left: -9px;
			.search-icon {
				margin-left: -30px;
			}
		}
	}

	.email-template-item {
		padding: 9px 10px 9px 15px;
		border: 1px solid #ebedee;
		background: #ffffff;
		box-sizing: border-box;
		cursor: pointer;
		.title-text-box {
			width: 212px;
		}
		.subject-box {
			white-space: nowrap;
			font-size: 14px;
			line-height: 17px;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-top: 5px;
			padding-right: 1.2rem;
			@include respond-to(mobile) {
				width: 200px;
			}
		}

		&.active {
			background: #ffffff;
			border: 1px solid #11a1ae;
		}
	}

	.email-body-title {
		font-size: 16px;
		font-weight: 700;
		&.handle-overflow {
			width: 349px;
		}
	}
	.email-body-subject {
		margin-top: 8px;
		font-size: 14px;
		font-weight: 600;
	}
	.email-body-sub-title {
		font-size: 14px;
		font-weight: 400;
		margin-left: 5px;
		&.handle-overflow {
			width: 336px;
		}
	}
	.email-body-desc {
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		margin-top: 10px;
	}
	.email-end-text-one {
		font-size: 14px;
		margin-top: 20px;
		font-weight: 500;
	}
	.email-end-text-two {
		font-weight: 500;
		font-size: 14px;
		margin-top: 5px;
	}
	.email-Attachments {
		font-weight: 600;
		font-size: 14px;
		margin-top: 10px;
	}
	.email-attachments-container {
		white-space: nowrap;
		width: 340px;
		overflow: hidden;
		text-overflow: ellipsis;
		@include flexbox();
		.email-attachment-name {
			font-size: 14px;
			color: #5e7281;
			font-weight: 500;
		}
	}
	.email-attachment-sec {
		.email-attachment-wrap {
			position: relative;
			@include flexbox();
			align-items: center;
			background-color: #f3f7f9;
			margin-top: 5px;
			border-color: #cbd6e2;
			height: 37px;
			padding: 6px 8px 6px 5px;
		}
		.email-attach-logo {
			margin-left: 5px;
			background: #ffffff;
			border-radius: 50%;
			padding: 5px;
			@include flexbox();
			justify-content: center;
			align-items: center;
			.material-icons {
				font-size: 16px;
			}
		}
	}
	.email-hr-one {
		border: 1px solid #d9d9d9;
		margin-right: 18rem;
		width: 100%;
	}
	.email-hr-two {
		border: 1px #cbd6e2;
		border-left: 1px solid #f5f2f2;
		height: 700px;
		position: absolute;
		left: 43%;
		margin-left: -3px;
	}
	.more-icon {
		font-size: 20px;
	}
}
.email-overflow {
}
