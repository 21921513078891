.global-settings {
	overflow-y: auto;
	background-color: #f1f6f8;
	.breadcrumb-menu {
		padding-left: 3rem;
	}
	&-section {
		border-bottom: 1px solid #f1efef;
		margin-top: 20px;
		.title {
			background: transparent;
			color: #1c2e59;
			display: inline-block;
			padding: 15px 0px;
			margin-right: 25px;
			font-weight: $semibold;
			font-size: 1.6rem;
			border-radius: 10px 10px 0 0;
			border-bottom: 0;
		}
	}
	.btn {
		padding: 0.8rem 2.8rem !important;
		width: auto !important;
		&--smaller {
			padding: 0.5rem 1rem;
		}
	}

	.upgrade-btn {
		background-color: #54b1bc;
		color: #fff;
		font-weight: 800;
		padding: 4px 10px;
		border-radius: 4px;
	}

	&_multiple_delete {
		position: absolute;
		margin-top: -45px;
		right: 0;
	}

	.btn--pagination {
		padding: 0.4rem 1.3rem !important;
	}

	&-container {
		min-height: calc(100vh - 54px);
	}
	&-padding-top {
		padding-top: 25px;
	}
	&__aside {
		border-right: 1px solid rgba(218, 218, 218, 0.47);
		padding-left: 2rem;
		background: #fff;
		margin: 0 10px;
		margin-left: 20px;
		border-radius: 8px;
		.category__menu-list {
			padding: 0 0.5rem;
			margin-right: 3rem;
			.category__menu-header {
				padding: 1.2rem 10rem 1.2rem 0rem;
				font-size: 16px;
				font-weight: 600;
				border-bottom: 1px solid rgba(218, 218, 218, 0.47);
				margin-bottom: 10px;
				cursor: pointer;
				color: #1f2236;
				.collapse-icon {
					position: absolute;
					top: 10px;
					right: 0px;
					transform: rotate(90deg);
					&-expand {
						transform: rotate(270deg);
						position: absolute;
						top: 10px;
						right: 6px;
					}
					&.header-menu-active {
						color: #00abba;
					}
				}
			}
			.menu-item {
				display: block;
				padding: 0.5rem 4rem 0.5rem 0rem;
				color: #687992;
				font-size: 14px;
				font-weight: 500;
				&.active {
					color: #00abba;
				}
			}
		}
		.category__menu-list:not(:last-child) {
		}
		.menu_list {
			overflow: scroll;
			height: calc(100vh - 90px);
			margin-top: 10px;
		}
	}
	&__right {
		flex: 1;
		padding: 3rem;
		background: #fff;
		margin: 0 10px;
		border-radius: 8px;
		margin-right: 30px;
		.clientActivity {
			&-body {
				height: calc(100vh - 187px);
				overflow-y: auto;
				@include respond-to(mobile) {
					height: calc(100% - 46px);
				}
			}
			&-container {
				padding: 2rem 3rem;
				position: relative;
				padding-right: 4rem;
				@include respond-to(mobile) {
					padding: 2rem 1rem 6.5rem;
				}
			}
			&-item {
				margin-top: 10px;
				position: relative;
				.content {
					margin-left: 30px;
					margin-bottom: 15px;
					padding: 10px 15px;
					background: $white;
					box-shadow: 0px 0px 6px 0px rgba(57, 114, 120, 0.18);
				}
				&:not(:last-child) {
					.clientActivity-bullet {
						&::after {
							content: '';
							background: #bbc4c4;
							z-index: 0;
							width: 1px;
							height: 100%;
							position: absolute;
							left: 10px;
							top: 30px;
						}
					}
				}
			}
			&-bullet {
				float: left;
				height: 20px;
				width: 20px;
				margin-top: 10px;
				border: 1px solid $primary-brand-color--light;
				background-color: $comment-background;
				border-radius: 50%;
				@include flexbox();
				justify-content: center;
				align-items: center;
				.material-icons {
					color: $primary-brand-color--light;
					font-size: 13px;
					font-weight: 800;
				}
			}
		}
		.section-container {
			height: calc(100vh - 130px);
			overflow: scroll;
			.header {
				&-section {
					margin-bottom: 20px;
					.top {
						@include flexbox();
						justify-content: space-between;
						align-items: center;
						min-height: 38px;
						.title {
							font-size: 2rem;
							font-weight: $bold;
						}
						.button-container {
							@include flexbox();
							align-items: center;
							justify-content: center;
						}
					}
					.description {
						font-size: 16px;
						max-width: 750px;
					}
				}
			}
			.time-of-day {
				height: calc(100vh - 250px);
				overflow: auto;
				.days-of-week {
					width: 100px;
					font-weight: bold;
				}
				.toggle-slider {
					&__ball {
						height: 18px;
					}
					&__ball:before {
						height: 14px;
					}
				}
				.time-of-day-secondary {
					font-weight: normal;
				}
			}
			.tod-support-links {
				a {
					color: #393f53;
					font-weight: bold;
					.material-icons {
						font-size: 16px;
						font-weight: normal;
						color: #393f53cc;
					}
				}
			}
		}
		.email-wrap {
			.status-pill {
				border: none;
			}
		}
	}
	.plans-container {
		padding: 0 !important;
	}
	.user-page-container {
		overflow-y: auto;
		height: calc(100vh - 54px);
	}
	.input-bg {
		background-color: #f3f7f9;
		border-color: #cbd6e2;
		&:focus {
			border-color: #7d90a5;
		}
	}
	.signature {
		@include flexbox();
		@include flex-direction(column);
		margin-top: 20px;
		width: 500px;
		.title {
			font-weight: bold;
			font-size: 1.6rem;
		}
		.sub-title {
			font-size: 1.3rem;
			color: #a0a0a1;
		}
		&-content {
			background: #fafafa;
			padding: 1rem;
			border: 1px solid #f3f3f3;
		}
		.editor-container .editor-body {
			max-height: 150px;
			min-height: 60px;
		}
	}
	.input-icon {
		padding: 0.7rem 2.5rem 0.7rem 1rem;
		&-placement {
			position: absolute;
			right: 5px;
			top: 5px;
			color: #c7cdd5;
			cursor: pointer;
			&:hover {
				color: #393f53;
			}
		}
		&-close {
			position: absolute;
			top: 6px;
			right: -12px;
			color: #c7cdd5;
			cursor: pointer;
			&:hover {
				color: #393f53;
			}
		}
	}
	.tabs-section {
		margin-top: 20px;
	}
	.light-ruler {
		margin: 2rem 0;
		border: 1px solid #f1efef;
	}
	.label-text {
		font-size: 1.45rem;
	}
	.sub-label-text {
		font-size: 1.4rem;
	}
	.profile {
		&-sec {
			width: 500px;
		}
		@include flexbox();
		width: 700px;
		margin-top: 20px;
		.sub-label {
			color: #c7cdd5;
			font-size: 1.4rem;
			line-height: 2rem;
			font-weight: 500;
			margin-bottom: 0.5rem;
		}
		.left {
			width: 400px;
		}
		.right {
			width: 300px;
			.upload {
				width: 200px;
				height: 200px;
				min-height: 200px;
				min-width: 200px;
				margin-left: 50px;
				&-profile {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					padding: 1rem;
					background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BCD1EEFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
					background-color: #f8fcfd;
					.file-selector {
						text-align: center;
						font-weight: bold;
						padding-left: 3rem;
						padding-right: 3rem;
					}
					.title {
						font-weight: bold;
						font-size: 1.4rem;
					}
					.info {
						font-size: 1.2rem;
						color: #a0a0a1;
						font-weight: $semibold;
					}
					img {
						height: 65px;
						width: 65px;
					}
				}
				&-pic {
					width: 200px;
					height: 200px;
					min-height: 200px;
					min-width: 200px;
					object-fit: cover;
				}
				.change-image {
					position: relative;
					height: 100%;
					.overlay {
						display: none;
						height: 100%;
						width: 100%;
						position: absolute;
						background: rgba(0, 0, 0, 0.4);
						.icon {
							height: 50px;
							width: 50px;
							border-radius: 50%;
							background: #fafafa;
							@include flexbox();
							justify-content: center;
							align-items: center;
							opacity: 0.5;
							cursor: pointer;
							&:hover {
								opacity: 1;
							}
						}
					}
					&:hover {
						.overlay {
							display: flex;
						}
					}
				}
			}
		}
	}
	.tabs-container {
		border-bottom: 1px solid #f1efef;
		.tab {
			background: transparent;
			color: #1c2e59;
			display: inline-block;
			padding: 15px 0px;
			margin-right: 25px;
			font-weight: $medium;
			font-size: 1.6rem;
			border-radius: 10px 10px 0 0;
			cursor: pointer;
			border-bottom: 0;
			&::after {
				content: '';
				transform: scale(0);
				@include transition(all 0.3s ease);
				transform-origin: center, center;
			}
			&.active {
				font-weight: $semibold;
			}
			&.active,
			&:hover {
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					transform: scale(1);
					height: 2px;
					background: #ec5959;
				}
			}
		}
	}
	.table-container {
		.table-head,
		.table-body {
			.table-row {
				&-inline {
					margin: 5px 0;
					padding: 0.8rem 1rem;
					flex: 1;
				}
				padding: 1rem;
				background-color: #f3f7f9;
				.actions {
					@include flexbox();
					justify-content: center;
					align-items: center;
					&-item {
						font-weight: $semibold;
						color: #11a1ae;
						.grey-icon {
							font-size: 20px;
							color: #c4cfd7;
						}
						.add-icon {
							font-size: 16px;
							color: #11a1ae;
						}
					}
				}
				.label-bold {
					font-weight: 600;
				}
			}
			.options-bg {
				background: white;
			}
			.drag-icon {
				color: #dadada;
			}
		}
	}
	.btn-plus {
		background: transparent;
		color: $primary-brand-color--light;
		padding: 0.1rem;
		border-radius: 5px;
		border: 1px solid $primary-brand-color--light;
	}
	.currency {
		color: $border-secondary;
	}
	&_table {
		margin-top: 3rem;
		&.pagination {
			max-height: calc(100vh - 340px);
		}
		.bg-row {
			background-color: #f3f7f9;
		}
		thead {
			background-color: #f3f7f9;
			color: #22243d;
		}
		tr {
			border: 0 !important;

			th {
				font-weight: 700 !important;
			}
		}
		.inner-table {
			tr {
				&:nth-child(even) {
					background-color: #f3f7f9;
				}
			}
		}
		.oddRow {
			background-color: #f3f7f9;
		}
		.inner-table {
			max-height: calc(100vh - 335px);
			overflow-y: auto;
			.action-button {
				color: #11a1ae;
				font-weight: 600;
				padding: 0 0.5rem;
			}
			.action-button-1 {
				color: #393f53;
				padding: 0.5rem 1rem;
				background-color: #fff;
				border: 1px solid #7396b2;
				border-radius: 3px;
			}
			.ipad-fix {
				@media only screen and (min-width: 834px) and (max-width: 1024px) {
					width: 144px;
				}
			}
		}
		.inner-table-small {
			max-height: calc(100vh - 360px);
			overflow-y: auto;
		}
		.actions {
			@include flexbox();
			align-items: center;
			&-item {
				font-weight: $semibold;
				color: #11a1ae;
				.grey-icon {
					font-size: 20px;
					color: #c4cfd7;
				}
				.add-icon {
					font-size: 16px;
					color: #11a1ae;
				}
				.edit-icon {
					color: #888b99 !important;
				}
				.delete-icon {
					color: #888b99 !important;
				}
			}
		}
	}
	&_search-wrap {
		.search-box {
			padding-right: 3rem;
			padding-left: 1.6rem;
			border-color: #cbd6e2;
			background-color: #f3f7f9;
		}
	}
	&_search-wrap::after {
		position: absolute;
		top: 11px;
		right: 8px;
		content: '\E8B6';
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		font-size: 2rem;
		display: inline-block;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		left: auto !important;
		color: #11a1ae;
	}
	.custom-checkbox {
		.checkbox-icon-wrap {
			border-color: #cbd6e2;
		}
	}
	.all-notification-table {
		height: initial;
		max-height: calc(100vh - 200px);
	}
	.notification-table {
		width: 100%;
		margin-top: 20px;
		.header1,
		h4 {
			font-weight: 600;
			font-size: 1.6rem;
		}
		.header1,
		.td1 {
			text-align: left;
			width: 80%;
		}
		.header2,
		.td2 {
			width: 10%;
		}
		th,
		td {
			padding: 1.5rem 0;
		}
		thead {
			tr {
				border-bottom: 1px solid rgba(218, 218, 218, 0.47);
				border-top: 1px solid rgba(218, 218, 218, 0.47);
			}
			th {
				font-weight: 600;
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid rgba(218, 218, 218, 0.47);
			}
			p {
				color: rgba(57, 63, 83, 0.8);
			}
		}
	}
}
.modal--notification-access {
	.modal__global-setting_body {
		max-height: calc(100vh - 125px) !important;
		.enquiry-filters {
			.info-heading {
				display: none;
			}
		}
		.custom-checkbox {
			.checkbox-txt {
				.material-icons {
					font-size: 1.4rem;
					vertical-align: middle;
				}
			}
			.checkbox-icon-wrap {
				border-color: #cbd6e2;
			}
		}
		.user-notification-type-tooltip {
			max-width: 250px;
			bottom: 27px;
			left: -8px;

			.tooltip_wrapper_arrow::after {
				content: '';
				display: inline-block;
				position: absolute;
				bottom: -8px;
				left: 11px;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 11px solid #425b76;
				border-bottom: 0 !important;
				top: inherit;
			}
			.tooltip_wrapper_arrow::before {
				content: '';
				display: inline-block;
				position: absolute;
				bottom: -9px;
				left: 11px;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 10px solid #d7d3d3;
				border-bottom: 0 !important;
				top: inherit;
			}
		}
	}
}

.company-info-tab {
	padding-bottom: 2rem;
	border-bottom: 1px solid #f1efef;
}

.notification_user_guid {
	font-weight: 700;
	color: #11a1ae;
	.material-icons {
		font-size: 1.4rem;
		display: inline-flex;
		vertical-align: text-top;
	}
}
.copy-link-card {
	background-color: #f3f7f9;
	border-radius: 3px;
	border: 2px solid #c3cdce;
	padding: 1.5rem 1rem;
	cursor: pointer;
}
.copy-link-card:hover {
	background-color: #eefefe;
	border-color: #11a1ae;
}

#drop-area {
	border: 2px dashed #ccc;
	font-family: sans-serif;
	padding: 20px;
	background-color: #fefaf4;
}
.highlight {
	border-color: #11a1ae !important;
}
p {
	margin-top: 0;
}
.my-form {
	margin-bottom: 10px;
	text-align: center;
	.material-icons-outlined,
	.bold {
		color: #607483;
	}
	.material-icons-outlined {
		font-size: 45px;
	}
	label {
		width: fit-content;
		margin: auto;
		padding: 0.8rem 2.8rem !important;
		font-size: 1.5rem;
		border-radius: 2px;
		cursor: pointer;
		transition: all 0.3s ease;
		line-height: 2rem;
		font-weight: bold;
		border: 1px solid;
	}
}
#gallery {
	margin-top: 10px;
}
#gallery img {
	width: 150px;
	margin-bottom: 10px;
	margin-right: 10px;
	vertical-align: middle;
}
#fileElem {
	display: none;
}
.product-import-sample {
	color: #11a1ae;
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
}
.product-import-guide {
	color: #11a1ae;
	font-weight: bold;
	.material-icons {
		position: relative;
		font-size: 14px;
		font-weight: bold;
		top: 1px;
	}
}

.empty-sub-heading {
	color: #9a9b9c;
	font-size: 1.3rem !important;
	margin-bottom: 5px;
}

.empty-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 350px);

	&-partner {
		height: 100% !important;
		margin-top: 50px;
	}
	.empty-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.img {
		height: 100px;
		width: 100px;
	}
	.img-mid {
		height: 50px;
		width: 50px;
	}
}
