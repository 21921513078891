.plan {
	margin: 0 20px;
	&-container {
		position: relative;
		.card {
			border-radius: 5px;
			transition: all 0.2s linear;
			margin-bottom: 25px;
			&-header {
				width: 100%;
				height: 63px;
				border-radius: 5px 5px 0 0;
				padding: 0.8rem 2rem;
				background: linear-gradient(314.37deg, #6a748c 0%, #393f53 100%);
				.left {
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
					margin-right: 1rem;
					display: flex;
					flex-grow: 1;
				}
				.title {
					font-size: 2rem;
					font-weight: $semibold;
					letter-spacing: 0.37px;
					line-height: 25px;
					color: $white;
					text-transform: capitalize;
				}
			}
			&-body {
				background-color: #ffffff;
				box-shadow: 0 11px 18px 0 rgba(203, 200, 200, 0.5);
				padding: 15px;
				border: 2px solid transparent;
				border-top: none;
				border: 2px solid $white;
				border-top: none;
				border-radius: 0 0 5px 5px;

				.select-btn {
					width: 100%;
					color: $primary-text-color;
					font-size: 1.5rem;
					line-height: 2rem;
					font-weight: $semibold;
					padding: 0.9rem 1.6rem;
					background: $white;
					border: 1px solid;
					border-color: #dbd9e4;
					border-radius: 2px;
					text-align: center;
				}
			}
		}
		.check-circle-wrap {
			position: absolute;
			top: -10px;
			right: -6px;
			width: 26px;
			height: 26px;
			background-color: #28a25f;
			border: 2px solid $white;
			border-radius: 50%;
			visibility: hidden;
			opacity: 1;
			transition: opacity 100ms;
			> .check-icon {
				position: relative;
				top: 1px;
				left: 1px;
				font-size: 2rem;
				color: $white;
			}
		}
		input:checked + .card {
			box-shadow: 0px 10px 8px rgba(198, 190, 190, 0.5);
			transform: scale(1.07);
			.card-header {
				background: linear-gradient(136.15deg, #1ea8b4 0%, #1789c2 41.53%, #1292a0 100%);
			}
			.card-body {
				box-shadow: transparent;
				border-right: 2px solid #11a1ae;
				border-left: 2px solid #11a1ae;
				border-bottom: 2px solid #11a1ae;
			}
			.check-circle-wrap {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
