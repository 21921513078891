.card {
	&.bg-white {
		background-color: $white;
	}
	&__body {
		margin-top: 1rem;
	}
	&__container {
		position: relative;
		&__condition {
			padding: 2.4rem 2rem;
			border: 1px solid #dfe0e5;
			border-radius: 8px;
			background-color: #f7f7f7;
		}
		.edit-icon {
			position: absolute;
			right: 10px;
			top: 10px;
			&--type2 {
				right: 40px;
			}
		}
		.cont-header {
			.delete-icon {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
		.cont {
			&-list {
				&.box {
					padding: 1.4rem;
					border-radius: 5px;
					background-color: $white;
					box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31), 0 2px 2px 0 rgba(108, 117, 124, 0.16);
				}
				.btn {
					&--rounded {
						width: 100%;
						color: #5b666d;
						background: $white;
						border-radius: 20px;
						border: 1px solid #dadde0;
						box-shadow: 0 2px 7px 0 rgba(177, 164, 164, 0.5);
						color: #5b666d;
						&.active {
							color: $black;
							background: #fff5e2;
							border: 1px solid $secondary-brand-color;
							box-shadow: 0 2px 15px 0 rgba(198, 198, 198, 0.5);
						}
					}
					&--blue {
						color: $white;
						font-size: 1.4rem;
						font-weight: $bold;
						letter-spacing: 0.6px;
						line-height: 1.7rem;
						text-align: center;
						padding: 1.3rem 1.6rem;
						border-radius: 23px;
						background-color: #3856de;
					}
					&--cl {
						background-color: $secondary-brand-color !important;
					}
					&--disable {
						opacity: 0.5;
						cursor: not-allowed;
					}
				}
				.add-more {
					color: $primary-brand-color--light;
					font-weight: $medium;
					padding: 1rem;
					border: 1px dashed $primary-brand-color--light;
					border-radius: 5px;
					background-color: #f3f5ff;
					cursor: pointer;
					&--condition {
						margin: 0 3.5rem;
					}
				}
			}
			&-header {
				.heading {
					font-size: 1.6rem;
					font-weight: $medium;
					line-height: 21px;
				}
				.left-cont {
					padding-right: 2rem;
				}
				.sub-heading {
					color: rgba($primary-text-color, 0.6);
				}
			}
			&-body {
				&.border {
					border-bottom: 1px solid #979797;
				}
			}
		}
	}
}

.view-container {
	&--pd {
		padding-right: 3.5rem;
	}
}
.check-label {
	&__icon {
		color: #12b13f;
	}
	&__cancel {
		color: red;
	}
	&__txt {
		color: $primary-text-color;
		font-size: 1.4rem;
		font-weight: $medium;
		margin-left: 1rem;
		&--md {
			color: rgba($primary-text-color, 0.7);
			font-weight: $regular;
		}
	}
}

.item-wrap {
	.label-txt {
		color: $primary-text-color;
		font-weight: $medium;
		&--light {
			color: rgba($primary-text-color, 0.6);
		}
	}
	.desc {
		color: $primary-text-color;
		font-size: 1.5rem;
		line-height: 2rem;
	}
	.bubble-container {
		.bubble {
			display: inline-block;
			min-height: 44px;
			padding: 1.2rem 2rem;
			background-color: $white;
			box-shadow: 0 1px 10px 0 rgba(59, 68, 164, 0.31);
			border-radius: 4px 20px 20px 20px;
			color: #1f1e1e;
			letter-spacing: 0.2px;
			hyphens: none !important;
			@include word-wrap();
			h1,
			h2,
			h3 {
				display: block;
				margin-left: 0;
				margin-right: 0;
				font-weight: bold;
				line-height: 1.2em;
			}
			h1 {
				margin-top: 0.67em;
				margin-bottom: 0.67em;
				font-size: 2em;
			}
			h2 {
				margin-top: 0.83em;
				margin-bottom: 0.83em;
				font-size: 1.5em;
			}
			h3 {
				margin-top: 1em;
				margin-bottom: 1em;
				font-size: 1.17em;
			}
			ol,
			ul {
				margin-left: 8px;
				text-decoration: initial;
			}
			ul {
				list-style-type: initial;
			}
			ol {
				list-style-type: decimal;
			}
			em {
				font-style: italic;
			}
			a {
				color: blue;
				text-decoration: underline;
			}
			img {
			}
		}
	}
	.steps {
		&-count {
			@include avatar(36px, 36px, $primary-brand-color--light, 100%);
			color: $white;
			font-weight: $medium;
			letter-spacing: 0.2px;
		}
		&-txt {
			color: $primary-text-color;
			font-size: 1.6rem;
			font-weight: $medium;
			letter-spacing: 0.23px;
			margin-left: 1rem;
		}
	}
}
.ruler {
	margin: 2rem 0;
	border: 1px solid $border-color;
}
.btn {
	&--save,
	&--cancel {
		padding: 0.8rem 2.8rem;
	}
}

.condition {
	&-body {
		display: none;
	}
	&-wrap {
		position: relative;
		padding: 0.5rem 3.5rem;
		border: 1px solid #c4c7d3;
		border-radius: 3px;
		background-color: #efefef;
		> input:checked + label + .condition-body {
			display: block;
			margin-bottom: 3rem;
		}
	}

	&-btn {
		position: absolute;
		top: -11px;
		left: 30px;
		width: 120px;
		padding: 0.2rem;
		color: $primary-text-color;
		font-size: 1.3rem;
		letter-spacing: 0.19px;
		background-color: $white;
		border: 1px solid #c4c7d3;
		border-radius: 5px;
		cursor: pointer;
	}
}
.response-type1 {
	.cont-left {
		@include flex-grow(1);
	}
	.cont-right {
		position: relative;
		top: 2px;
		padding-left: 20px;
		> .material-icons {
			font-size: 2.3rem;
			color: #6b747e;
			cursor: pointer;
		}
	}
	&:not(:first-of-type) {
		.cont-right {
			top: 0;
		}
	}
}
.response-type2 {
	.cont-right {
		top: 0;
	}
	&:not(:first-child) {
		margin-top: 1.2rem;
	}
}
.view-container {
	.multiple-choice {
		width: 290px;
	}
}
.multiple-choice {
	.cont-list {
		&__item {
			&:not(:last-child) {
				margin-bottom: 0.6rem;
			}
		}
	}
	.label {
		padding: 1.2rem 1.6rem;
		background-color: $white;
		border: 2px solid transparent;
		border-radius: 5px;
		box-shadow: 0 8px 4px 0 rgba(198, 198, 198, 0.25);
		cursor: pointer;
		transition: all 300ms;
		@include word-wrap();
		&__icon {
			position: relative;
			@include avatar(21px, 21px, $white, 100%);
			border: 1px solid #979797;
			transition: all 300ms;
		}
		&__txt {
			width: calc(100% - 30px);
			color: #393333;
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.9rem;
			margin-left: 0.9rem;
		}
	}
	.label.active,
	input:checked + .label {
		border: 2px solid $secondary-brand-color;
		.label__icon {
			background-color: #f1a310;
			border: 1px solid #f1a310;
			&:after {
				content: '\e5ca';
				color: $white;
				font-family: 'Material Icons';
				font-weight: normal;
				font-style: normal;
				font-size: 1.6rem;
				display: inline-block;
				position: absolute;
				top: 1px;
				left: 1px;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;
				vertical-align: middle;
			}
		}
	}
}

.pills-list {
	&__item {
		display: inline-block;
		color: #5b666d;
		font-size: 1.4rem;
		font-weight: $medium;
		line-height: 1.7rem;
		padding: 1.2rem 3rem;
		margin: 0.5rem 0;
		background-color: $white;
		border: 1px solid #dadde0;
		border-radius: 20px;
		box-shadow: 0 2px 7px 0 rgba(177, 164, 164, 0.5);
		&--type1 {
			background-color: #feeccd;
			border: 1px solid $secondary-brand-color;
		}
		&__country {
			padding: 0.5rem 1rem;
			border-radius: 7px;
		}
		&:not(:last-of-type) {
			margin-right: 1rem;
		}
	}
}

.upload {
	&-wrap {
		width: 156px;
		.fig-caption {
			&-wrap {
				position: relative;
				padding: 0.8rem;
				border: 1px solid #d6d6d6;
				background-color: $white;
			}
			&-txt {
				padding-right: 1.8rem;
			}
		}
		.input-wrap .input-field {
			padding: 0.9rem 2.8rem 0.9rem 1rem;
		}
		.action-icon {
			position: absolute;
			top: 8px;
			right: 6px;
			color: rgba($primary-text-color, 0.6);
			font-size: 2rem;
			cursor: pointer;
		}
	}
	&-pic,
	&-label {
		width: 156px;
		height: 116px;
		background: #ececec;
		border: 1px solid #979797;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&-label1 {
		width: 156px;
		height: 156px;
		background: #ececec;
		border: 1px solid #979797;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&-loader {
		width: 156px;
		height: 116px;
		background: #ececec;
		border: 1px solid #979797;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&-label,
	&-label1 {
		@include flex-direction(column);
		background-color: rgba(#607cff, 0.17);
		cursor: pointer;
		&__icon {
			color: $primary-brand-color;
			transform: rotate(180deg);
		}
		&__txt {
			color: rgba(32, 45, 86, 0.56);
		}
		.add-icon {
			color: $primary-brand-color;
			font-size: 4rem;
		}
	}
}

.pic-delete {
	color: rgba($primary-text-color, 0.6);
	font-size: 2rem;
	&-wrap {
		@include avatar(30px, 30px, $white, 100%);
		@include flexbox();
		@include align-items(center);
		@include justify-content(center);
		position: absolute;
		top: 5px;
		right: 5px;
		opacity: 0.4;
		cursor: pointer;
		&:hover {
			opacity: 1;
		}
	}
}

.slider {
	&-container {
		position: relative;
		width: 286px;
		padding: 1rem;
		border-radius: 7px;
		background-color: rgba($white, 0.06);
		box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31), 0 2px 2px 0 rgba(108, 117, 124, 0.16);
	}
	&-pic {
		width: 100%;
		height: 220px;
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&-caption {
		color: $white;
		font-weight: $medium;
		padding: 1.2rem;
		background-color: $primary-brand-color;
		min-height: 44px;
	}
	&-controls {
		&__item {
			position: absolute;
			@include avatar(50px, 50px, $white, 100%);
			box-shadow: 0 2px 4px 0 rgba(145, 137, 137, 0.5);
			transition: all 300ms;
			> .material-icons {
				position: relative;
				top: 10px;
				left: 14px;
				color: $primary-brand-color;
				font-weight: $bold;
				font-size: 3rem;
			}
			&--left {
				top: calc(50% - 25px);
				left: -18px;
			}
			&--right {
				top: calc(50% - 25px);
				right: -18px;
			}
			&:hover {
				background-color: #e4dcdc;
			}
		}
	}
	.hide {
		display: none;
	}
}
.check-circle {
	@include avatar(37px, 37px, $secondary-brand-color, 100%);
	position: absolute;
	right: 10px;
	top: 10px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	border: 4px solid $white;
	opacity: 0;
	visibility: hidden;
	transition: all 300ms;
	&__icon {
		position: relative;
		top: 3px;
		left: 2px;
	}
	&.show {
		opacity: 1;
		visibility: visible;
	}
}
.custom-checkbox {
	.checkbox-txt-wrap {
		position: relative;
		top: 2px;
		margin-left: 1rem;
		.checkbox-txt {
			margin-left: 0;
			&--md {
				color: rgba($primary-text-color, 0.7);
				font-weight: $regular;
			}
		}
	}
}

.cont-list {
	.cont-left {
		position: relative;
		.text-options-input {
			padding: 1.15rem 1.6rem;
			padding-right: 6.5rem;
		}
		.text-options-wrap {
			position: absolute;
			top: 0;
			right: 0;
			&--type1 {
				top: initial;
				bottom: 0;
				height: 45px;
			}
		}
	}
}

.time-slots {
	&-wrap {
		.select-wrap {
			margin: 0 0.5rem;
		}
		.colon {
			font-weight: $medium;
		}
		.txt-to {
			margin: 0 0.5rem;
			font-weight: $medium;
		}
	}
}

// for text editor
.ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
	word-break: break-all !important;
}

.answer-options {
	padding-left: 20px;
	min-height: calc(100vh - 202px);
	&__list {
		width: 45%;
		padding-right: 20px;
		.image-mcq-list {
			padding-top: 20px;
			&__item {
				cursor: pointer;
				position: relative;
				border: 1px solid #e6e6e6;
				background: $white;
				padding: 10px;
				margin-bottom: 10px;
				.material-icons {
					color: rgba(57, 63, 83, 0.6);
				}
				&__text {
					font-weight: 800;
					font-size: 16px;
					margin-left: 15px;
					flex: 1 1 auto;
				}
			}
			&__add-item {
				color: $primary-brand-color--light;
				font-weight: $bold;
				padding: 1rem;
				border: 1px solid $primary-brand-color--light;
				border-radius: 5px;
				background-color: #f3f5ff;
				cursor: pointer;
				&__icon {
					font-size: 16px;
					border: 1px solid $primary-brand-color--light;
					border-radius: 15px;
				}
			}
		}
	}
	&__form {
		padding-top: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
		width: 45%;
		.answer-options-upload {
			text-align: center;
			color: $primary-brand-color--light;
		}
		.answer-options-advance-toggle {
			color: $primary-brand-color--light;
		}
		.image-mcq-file-input-hidden {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}
		.image-mcq-upload-preview {
			height: 200px;
			width: 200px;
			margin-bottom: 10px;
			border-radius: 10px;
		}
	}
	&__preview {
		border-left: 1px solid #e6e6e6;
		width: 55%;
		background-color: $white;
		&__title {
			color: #1c2e59;
			margin-top: 20px;
			font-weight: 600;
		}
		&__message {
			margin-top: 10px;
			.message-preview-msg {
				margin-left: 20px;
			}
		}
	}
}

.image-MCQ-cropper {
	.modal-container {
		width: 80%;
		margin-left: auto;
		margin-right: auto;
	}
	.modal__body {
		margin-bottom: 0px;
	}
}
.image-mcq-color-picker {
	.theme-color--input {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		padding: 1.5rem;
		border: 1px solid #cfcccc;
		float: right;
	}

	.theme-color {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		padding: 1.5rem;
		border: 1px solid #cfcccc;
		float: right;
	}
}
.image-mcq-range-ctrl {
	margin-bottom: 26px;
	.range {
		width: 100%;
		position: relative;
		input[type='range'] {
			width: 100%;
			height: 8px;
			-webkit-appearance: none;
			outline: none;
			border: 10px;
			margin: 8px 0;
			-moz-appearance: none;
			padding: 0;
		}
		.filler {
			pointer-events: none;
			height: 5px;
			background: #11a1ae;
			position: relative;
			top: -14px;
			border-radius: 6px;
			transition: all 0.1s ease;
			box-sizing: border-box;
			opacity: 0;
		}
		.bubble-value {
			background: #555;
			color: #fff;
			padding: 1px 6px;
			position: absolute;
			font-size: 12px;
			font-weight: 700;
			border-radius: 4px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 999;
			opacity: 0;
		}
		.bubble-value:after {
			content: '';
			position: absolute;
			width: 5px;
			height: 5px;
			background: #555;
			top: -3px;
			left: 40%;
			transform: rotate(45deg);
		}

		input[type='range'] {
			width: 100%;
			height: 8px; /* thumbHeight + (2 x thumbBorderWidth)*/
			-webkit-appearance: none; /*remove the line*/
			outline: none;
			border: 10px; /*for firefox on android*/
			margin: 8px 0;
			-moz-appearance: none;
			padding: 0;
		}

		input[type='range']:disabled {
			cursor: none !important;
			-moz-appearance: none;
			-webkit-appearance: none;
		}

		input[type='range']::-webkit-slider-runnable-track {
			background: #ccc; /*trackColor*/
			height: 5px; /*trackHeight*/
			border-radius: 6px; /*trackHeight*/
			transition: 0.3s;
		}

		// slider button
		input[type='range']::-webkit-slider-thumb {
			-webkit-appearance: none;
			width: 18px; /* thumbHeight + (2 x thumbBorderWidth)*/
			height: 18px; /* thumbHeight + (2 x thumbBorderWidth)*/
			border-radius: 50%;
			margin-top: -8px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
			cursor: pointer;
			transition: 0.3s;
			z-index: 10;
			position: relative;
			background: #ffffff;
			border: 1px solid #dcdcdc;
			box-shadow: 0 2px 4px 0 rgba(108, 105, 105, 0.5);
			left: 0;
		}

		input[type='range']::-moz-focus-outer {
			border: 0;
		}

		input[type='range']::-moz-range-track {
			background: #ccc; /*trackColor*/
			position: relative;
			height: 15px; /*trackHeight*/
			border-radius: 4px; /*trackHeight*/
		}

		input[type='range']::-moz-range-track + .filler {
			position: relative;
			display: none;
		}

		input[type='range']::-moz-range-progress {
			background-color: #3856de;
			height: 15px;
			border-radius: 6px;
		}

		input[type='range']::-moz-range-thumb {
			width: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
			height: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
			border-radius: 50%;
			margin-top: -8px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
			cursor: pointer;
			border: 4px solid #fff; /*border-width should be equal to thumbBorderWidth if you want same border width across all browsers and border-color should match the background*/
			transition: 0.3s;
			z-index: 10;
			position: relative;
			background: #ffffff;
			border: 1px solid #dcdcdc;
			box-shadow: 0 2px 4px 0 rgba(108, 105, 105, 0.5);
		}

		input[type='range']::-moz-focus-outer {
			border: 0;
		}

		input[type='range']::-moz-range-track {
			background: #ccc; /*trackColor*/
			position: relative;
			height: 15px; /*trackHeight*/
			border-radius: 4px; /*trackHeight*/
		}
	}
}
