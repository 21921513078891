.client-login {
	width: 100%;
	height: 100vh;
	&__container {
		width: 100%;
		height: 100vh;
	}
	.section-left {
		padding: 12.5rem 9rem;
		height: 100%;
		background: linear-gradient(180deg, #2f57e6 0%, #1e3fb4 100%);
		.heading-level1 {
			width: 396px;
			color: $white;
			font-size: 4.8rem;
			line-height: 62px;
		}
	}
	.section-right {
		padding: 10rem;
	}
	&__header {
		margin-bottom: 2.5rem;
		.heading {
			color: $primary-text-color;
			font-size: 3.2rem;
			line-height: 45px;
			margin-bottom: 0.5rem;
		}
		.sub-heading {
			color: rgba($primary-text-color, 0.7);
		}
	}
}
