.modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 200000;
	background: rgba(#f0f0f0, 0.9);
	overflow: scroll;

	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-touch-callout: none) {
			height: 88vh;
		}
	}
	// media query for 12.9 inch iPad
	@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
		height: 100%;
	}

	&--new-deal {
		@include respond-to(mobile) {
			.modal-container {
				max-width: 100%;
			}
			.react-datepicker__input-container input {
				max-width: 100% !important;
				width: 100% !important;
			}
			.react-datepicker-popper {
				left: -40px !important;
			}
		}
		.right {
			.react-datepicker__triangle {
				left: 144px !important;
			}
		}
		.left {
			.react-datepicker-popper {
				left: 0px !important;
			}
		}
	}
	&--type1 {
		.modal-container {
			width: 628px;
			height: 100vh;
			position: absolute;
			right: 0;
			top: 0;
			background: $white;
			margin: 0;
			border-radius: 0;
			@include respond-to(mobile) {
				height: calc(100vh - 110px);
				max-width: 100%;
				top: 56px;
			}
			@media not all and (min-resolution: 0.001dpcm) {
				@supports (-webkit-touch-callout: none) {
					height: calc(100vh - 110px);
				}
			}
			&.plan {
				width: 750px;
			}
		}
		.modal__body {
			height: calc(100vh - 125px);
			overflow-y: auto;
			overflow-x: hidden;
			padding: 1.5rem 2.5rem;
			padding-bottom: 50px;
			&__onboard {
				height: calc(100vh - 110px);
			}
			@include respond-to(mobile) {
				height: calc(100vh - 200px);
			}
			input,
			textarea,
			select {
				background-color: #f3f7f9 !important;
				border-color: #cbd6e2 !important;
			}
		}
		.modal__footer {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 1.5rem 2.5rem;
			background-color: $white;
			border-top: 1px solid rgba($border-color, 0.5);
			z-index: 2;
			.save-template {
				@include flexbox();
				cursor: pointer;
				justify-content: center;
				align-items: center;
				.material-icons {
					font-size: 17px;
					color: #393f53;
					margin-right: 3px;
				}
				.label-template {
					font-weight: 500;
					font-size: 13px;
					line-height: 15px;
					color: #393f53;
				}
			}
		}
	}
	&--type2 {
		.modal-container {
			background-color: #f9fafa;
			box-shadow: 0 2px 8px 0 rgba(91, 88, 88, 0.5);
			border-radius: 5px;
			border: none;
			margin-top: 20%;
		}
		input,
		textarea,
		select {
			background-color: #f3f7f9 !important;
			border-color: #cbd6e2 !important;
		}
	}
	&-container {
		margin: 4rem 0;
		background-color: #f9fafa;
		box-shadow: 0 2px 8px 0 rgba(91, 88, 88, 0.5);
		border-radius: 5px;
	}
	&__header {
		border-bottom: 1px solid rgba($border-color, 0.5);
		color: #fff !important;
		background-image: linear-gradient(to right, #11a1ae, #50b8a9);
		@extend %flexbox;
		padding: 1.5rem 2.5rem;
		color: #fff !important;
		background-image: linear-gradient(to right, #11a1ae, #50b8a9);
		.close-modal {
			color: #fff !important;
		}
		.btn-close-modal {
			border: 0;
			padding: 0;
			background: none;
			font-size: 3rem;
		}
		.close-modal {
			cursor: pointer;
			color: #fff;
		}
	}
	&__global-setting {
		.modal-container {
			width: 500px;
		}
		&_header {
			color: #fff !important;
			background-image: linear-gradient(to right, #11a1ae, #50b8a9);
			.close-modal {
				color: #fff !important;
			}
		}
		&_footer {
			background-color: #f3f7f9;
			.btn {
				border-color: #11a1ae;
			}
			.btn--primary {
				font-weight: bold;
			}
		}
		&_body {
			input,
			select,
			textarea {
				background-color: #f3f7f9 !important;
				border-color: #cbd6e2 !important;
				&:focus {
					border-color: #7d90a5 !important;
				}
			}
			.bg {
				background-color: #f3f7f9 !important;
			}
		}

		.add-modal-body {
			.input-label {
				color: #1c2e59;
				font-weight: 700;
			}

			.app_dropdown__menu-list {
				max-height: 225px;
			}

			.input-field,
			.custom-select,
			.css-1e3zplh-control {
				border-color: transparent !important;
				background-color: #f2f7f9 !important;
			}

			.input-wrap {
				.custom-radio {
					.item {
						.radio-button {
							.label-txt {
								color: #5e7281 !important;
								font-weight: 600 !important;
							}
						}
						&:not(:last-child) {
							margin-right: 10rem;
						}
					}
				}
			}
		}
	}
	&__body {
		margin-bottom: 4rem;
		padding: 1.5rem 2.5rem;
	}
	&__footer {
		padding: 1.5rem 2.5rem;
		@extend %flexbox;
		justify-content: flex-end;
		@include respond-to(mobile) {
			justify-content: center;
		}
		.btn--save,
		.btn--cancel {
			width: 100px;
		}
	}

	.modal-ruler {
		border-bottom: 1px solid rgba(#979797, 0.6);
		margin: 2rem 0;
	}
	// new variable
	&--new-variable {
		.modal-container {
			width: 475px;
		}
	}
	//onboard-client
	&--onboard-client {
		.modal__body {
			.check-icon .material-icons {
				color: #28a25f;
				font-size: 9rem;
			}
			.desc {
				padding: 0 3rem;
				color: $primary-text-color;
				font-size: 1.6rem;
				font-weight: $medium;
				line-height: 2.2rem;
			}
			.add-info {
				color: rgba($primary-text-color, 0.6);
				font-size: 1.3rem;
				line-height: 1.7rem;
			}
		}
	}

	//service request
	&--service-request {
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			margin-left: 0.4rem;
			border-radius: 4px 20px 20px 20px;
			background-color: $white;
			box-shadow: 0 1px 22px 0 rgba(171, 171, 171, 0.31);
			&:not(:first-of-type) {
				margin-top: 10px;
			}
			&__heading,
			&__cont {
				letter-spacing: 0.2px;
				line-height: 1.9rem;
			}
			&__cont {
				font-weight: $medium;
			}
		}
	}

	// add users
	&--add-user {
		.modal__body {
			.user-pic {
				position: relative;
				@include avatar(87px, 87px, rgba(32, 45, 86, 0.04), 100%);
				border: 1px dashed rgba(32, 45, 86, 0.33);
				cursor: pointer;
				.plus-icon {
					color: $primary-brand-color;
					font-size: 4.5rem;
					text-align: center;
				}
				> img {
					@include avatar(100%, 100%, transparent, 100%);
					object-fit: cover;
				}
			}
		}
	}

	//deal-info
	&--deal-info {
		.modal-nav {
			border-bottom: 1px solid rgba($border-secondary, 0.6);
			&__item {
				position: relative;
				color: #374055;
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
				padding-bottom: 1rem;
				&:not(:last-child) {
					margin-right: 2.5rem;
				}
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 4px;
					background-color: $secondary-brand-color;
					opacity: 0;
					visibility: hidden;
					transition: opacity 300ms;
				}
				&.active {
					font-weight: $bold;
					&:after {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
		.basic-info {
			&-header {
				position: absolute;
				right: 20px;
				top: 0;
				> .btn {
					padding: 1rem;
					border-radius: 3px;
				}
			}
			&-body {
				.input-wrap {
					margin-bottom: 2.5rem;
					&.name-container {
						padding-right: 80px;
					}
					.input-label {
						color: rgba($primary-text-color, 0.8);
						font-weight: $regular;
					}
					.input-desc {
						font-size: 1.5rem;
						line-height: 2rem;
					}
				}
			}
		}
	}

	// notification access
	&--notification-access {
		.modal__body {
			max-height: 400px;
			overflow-y: auto;
		}
		.info-heading {
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.6rem;
		}
		.main-list {
			margin-top: 1rem;
			li {
				margin-bottom: 1.5rem;
				.checkbox-txt {
					font-weight: $regular;
				}
			}
		}
		.sub-list {
			margin-top: 1rem;
			margin-left: 3.5rem;
			li:last-child {
				margin-bottom: 2rem;
			}
		}
	}

	&--notification-access-view {
		.main-list {
			li {
				margin-bottom: 0.8rem;
				.check-label__txt {
					font-weight: $regular;
				}
			}
		}
	}
	// client profile
	&--client-profile {
		.sub-heading {
			color: rgba($primary-text-color, 0.6);
		}
		.list {
			&-item {
				@include flexbox();
				&:not(:last-child) {
					margin-bottom: 1.5rem;
				}
				&__label {
					width: 35%;
					color: $primary-text-color;
					font-weight: $medium;
					line-height: 17px;
				}
				&__desc {
					width: 50%;
					padding-right: 1rem;
					color: rgba($primary-text-color, 0.8);
					line-height: 17px;
				}
			}
		}
	}
}

.swal-overlay {
	z-index: 999999999999 !important;
}

.card-change-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9999999999;
	background: rgba(#f0f0f0, 0.9);
	overflow: scroll;

	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-touch-callout: none) {
			height: 88vh;
		}
	}
	&-body {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
		margin-top: 30px;
	}
	.modal__body {
		padding: 1.5rem 3rem;
		margin-bottom: 3rem;
	}
	.modal__footer {
		justify-content: center;
	}
	.modal-container {
		background-color: #ffffff;
	}
}
.sections {
	margin-left: -2.5rem !important;
	margin-right: -2.5rem !important;
	padding: 20px 10px 10px;
	&-seperator-b {
		border-bottom: 1px solid #eff0f1;
	}
	&-seperator-t {
		border-top: 1px solid #eff0f1;
	}
}

.small-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 20;
	background: rgba(#f0f0f0, 0.9);
	overflow: scroll;
	.container {
		max-width: 960px;
	}
	.modal-container {
		width: 900px;
		height: 100vh;
		position: absolute;
		right: 0;
		top: 0;
		background: $white;
		margin: 0;
		border-radius: 0;
	}
	.modal__body {
		height: calc(100vh - 125px);
		overflow-y: auto;
		overflow-x: hidden;
		padding: 1.5rem 2.5rem;
		padding-bottom: 50px;
	}
	.modal__footer {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 1.5rem 2.5rem;
		background-color: $white;
		border-top: 1px solid rgba($border-color, 0.5);
		z-index: 2;
	}
}

.wide-modal {
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 20;
	background: rgba(0, 0, 0, 0.4);
	overflow: scroll;
	&-container {
		width: fit-content;
		box-shadow: 0 2px 8px 0 rgba(91, 88, 88, 0.5);
		border-radius: 0;
		height: calc(100vh - 120px);
		margin: 60px auto;
		position: relative;
		background: #fafafa;
		@include respond-to(mobile) {
			margin: 0px auto;
		}
	}
	&-update-container {
		width: fit-content;
		box-shadow: 0 2px 8px 0 rgba(91, 88, 88, 0.5);
		border-radius: 0;
		margin: 60px auto;
		position: relative;
		background: #fafafa;
		@include respond-to(mobile) {
			margin: 0px auto;
		}
	}
	&-main {
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		@include respond-to(mobile) {
			height: calc(100vh - 110px);
		}
	}
	&-hide-scroll {
		overflow-y: hidden;
		overflow-x: hidden;
	}
	&-header {
		border-bottom: 1px solid rgba($border-color, 0.5);
		color: #fff !important;
		background-image: linear-gradient(to right, #11a1ae, #50b8a9);
		@extend %flexbox;
		padding: 1.5rem 2.5rem;
		color: #fff !important;
		background-image: linear-gradient(to right, #11a1ae, #50b8a9);
		.close-modal {
			color: #fff !important;
		}
		.btn-close-modal {
			border: 0;
			padding: 0;
			background: none;
			font-size: 3rem;
		}
		.close-modal {
			cursor: pointer;
			color: #fff;
		}
	}
	&-body {
		height: calc(100% - 90px);
		overflow-y: auto;
		overflow-x: hidden;
		padding: 1.5rem 2.5rem;
		padding-bottom: 50px;
		background: $white;
	}
	&-footer {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding: 1.5rem 2.5rem;
		background-color: $white;
		border-top: 1px solid rgba($border-color, 0.5);
		z-index: 2;
	}
}
