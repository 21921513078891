.quillEditor {
	// border: 1px solid #dadada;
	position: relative;
	margin: 0px 5px;
	margin-bottom: 5px;
	.ql-container.ql-snow {
		border: none;
	}

	.tox-edit-area__iframe {
		font-weight: 400 !important;
		.mce-content-body {
			font-weight: 400;
		}
	}
	.char-remain-text {
		position: absolute;
		z-index: 999;
		bottom: 10px;
		right: 18%;
		font-size: 12px;
		font-weight: 500;
		color: #8c8e9a;
	}
	.char-remain-text_error {
		position: absolute;
		z-index: 999;
		bottom: 10px;
		right: 18%;
		font-size: 12px;
		font-weight: 500;
		color: #ff0000;
	}
	.attachments {
		background: #f2f7f9;
		&-container {
			padding: 10px 0px;
			max-height: calc(100vh - 591px);
			overflow-y: auto;
			.uploads {
				background-color: #ffffff;
				padding: 5px 10px;
				@include flexbox();
				align-items: center;
				justify-content: space-between;
				border-radius: 3px;
				margin: 0 10px;
				margin-bottom: 8px;
				box-shadow: 0 0px 6px 1px rgba(214, 214, 214, 1);
				// box-shadow: 0px 0px 6px 1px rgba(214 214 214);
				.title {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
				}
				.progress {
					@include flexbox();
					justify-content: center;
					align-items: center;
					.close {
						font-size: 15px;
						cursor: pointer;
					}
				}
			}
		}
	}
	.uploading {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(240, 240, 240, 0.6);
		font-weight: bold;
	}
}

.quillEditor {
	// border: 1px solid #dadada;
	position: relative;
	margin: 0px 5px;
	margin-bottom: 5px;
	overflow-y: auto;
	.ql-container.ql-snow {
		border: none !important;
	}

	.char-remain-text {
		position: absolute;
		z-index: 999;
		bottom: 10px;
		right: 18%;
		font-size: 12px;
		font-weight: 500;
		color: #8c8e9a;
	}
	.char-remain-text_error {
		position: absolute;
		z-index: 999;
		bottom: 10px;
		right: 18%;
		font-size: 12px;
		font-weight: 500;
		color: #ff0000;
	}
	.attachments {
		background: #f2f7f9;
		&-container {
			padding: 10px 0px;
			max-height: calc(100vh - 591px);
			overflow-y: auto;
			.uploads {
				background-color: #ffffff;
				padding: 5px 10px;
				@include flexbox();
				align-items: center;
				justify-content: space-between;
				border-radius: 3px;
				margin: 0 10px;
				margin-bottom: 8px;
				box-shadow: 0 0px 6px 1px rgba(214, 214, 214, 1);
				// box-shadow: 0px 0px 6px 1px rgba(214 214 214);
				.title {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
				}
				.progress {
					@include flexbox();
					justify-content: center;
					align-items: center;
					.close {
						font-size: 15px;
						cursor: pointer;
					}
				}
			}
		}
	}
	.uploading {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(240, 240, 240, 0.6);
		font-weight: bold;
	}
}

.link-modal {
	.link-text-wrap {
		position: relative;
		.input-field {
			padding-left: 35px;
			background: #f2f7f9;
			border: #f2f7f9;
		}
		.link-text-icon {
			position: absolute;
			top: 13px;
			left: 7px;
			color: #7d86a5;
		}
	}
}

.tox .tox-toolbar__group {
	padding: 0px !important;
	cursor: pointer;
}
.tox .tox-tbtn__select-label {
	cursor: pointer !important;
}

.tox .tox-toolbar--scrolling {
	flex-wrap: wrap !important;
	// overflow-x: scroll !important;
}
.tox .tox-split-button {
	margin-right: 2px !important;
	margin-left: 2px !important;
}
.tox .tox-split-button .tox-tbtn {
	margin-right: 0px !important;
	margin-left: 0px !important;
}
.tox .tox-dialog-wrap__backdrop {
	z-index: 999 !important;
}

.tox .tox-dialog {
	z-index: 9999 !important;
}

.tox-tinymce-aux {
	z-index: 999999 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled:hover {
	background: #e1f1f9 !important;
}

.tox .tox-tbtn--enabled,
.tox .tox-tbtn--enabled {
	background: #e1f1f9 !important;
}
.tox .tox-collection--toolbar .tox-collection__item--active {
	background: #e1f1f9 !important;
}
.tox .tox-collection--toolbar .tox-collection__item--enabled {
	background: #e1f1f9 !important;
	color: #222f3e;
}

.tox .tox-collection--list .tox-collection__item--active {
	background: #e1f1f9 !important;
}

.tox .tox-dialog__body-nav-item--active {
	border-bottom: 2px solid #11a1ae !important;
	color: #11a1ae !important;
}
.tox-dialog__footer-end {
	.tox-button {
		color: $white;
		background-color: $primary-brand-color--light !important;
		border-color: $primary-brand-color--light !important;
		border-radius: 2px !important;
		&:hover {
			background-color: darken($primary-brand-color--light, 7%) !important;
			border-color: darken($primary-brand-color--light, 7%) !important;
		}
	}
	.tox-button--secondary {
		color: #393f53 !important;
		font-size: 1.5rem !important;
		line-height: 2rem !important;
		font-weight: 500 !important;
		padding: 0.8rem 2.8rem !important;
		background: #fff !important;
		border: 1px solid;
		border-color: #dbd9e4 !important;
		border-radius: 2px;
		cursor: pointer !important;
		transition: all 0.3s ease;
		&:hover {
			background-color: lighten($primary-brand-color--light, 15%) !important;
			border-color: lighten($primary-brand-color--light, 10%) !important;
		}
	}
}

.tox .tox-tbtn svg {
	fill: #5f667d !important;
}

.default-editor-style {
	.tox .tox-tbtn {
		background: #f2f7f9;
		margin-right: 2px;
		margin-left: 2px;
		cursor: pointer;
	}
	.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
		border-right: none;
		cursor: pointer;
	}
	.tox .tox-toolbar,
	.tox .tox-toolbar__overflow,
	.tox .tox-toolbar__primary {
		background: none;
		padding-top: 3px;
		padding-bottom: 3px;
	}
}

.reply-template-editor {
	margin: 0px;
	background-color: #fafafa;
	border-radius: 8px;
	border: 1px solid #e0e0e1;
	border-bottom: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-bottom-left-radius: 0px !important;

	.tox.tox-tinymce--toolbar-bottom .tox-editor-header {
		margin-bottom: 2px;
	}
	.tox .tox-toolbar-overlord {
		background-color: #fafafa;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		border-bottom: 1px solid #e0e0e1;
		border-right: 1px solid #e0e0e1;
		border-left: 1px solid #e0e0e1;
	}
	.tox .tox-sidebar-wrap {
		background-color: #fafafa;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		border-top: 1px solid #e0e0e1;
		border-right: 1px solid #e0e0e1;
		border-left: 1px solid #e0e0e1;
	}
	.tox .tox-tbtn {
		position: relative;
		border-radius: 8px;
		margin-right: 2px;
		margin-left: 2px;
		cursor: pointer;
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			margin-right: 0;
			margin-left: 0;
			width: 27px;
		}
	}
	.tox .tox-tbtn:hover {
		overflow: inherit !important;
	}
	.toolbar-button[data-title]:hover::after {
		content: attr(data-title) !important;
		position: absolute !important;
		top: -17px !important;
		left: 27px !important;
		z-index: 1 !important;
		background-color: #33516a !important;
		color: #fff !important;
		border-radius: 6px !important;
		padding: 4px !important;
		white-space: nowrap !important;
	}

	.tox-split-button__chevron {
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			width: 12px !important;
		}
	}
	.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
		// border-right: none;
		cursor: pointer;
	}
	.tox .tox-toolbar__primary {
		background: none;
		padding-top: 3px;
		padding-bottom: 3px;
		padding-left: 10px;
	}
	.tox .tox-tbtn--select {
		color: #fff !important;
		background: #11a1ae;
		border-color: #11a1ae;
		position: absolute;
		right: 10px;
		bottom: -1px;
		padding: 0 10px 0 30px;
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			width: 75px !important;
		}
	}
	.tox .tox-tbtn--select::before {
		content: '';
		background: url('/img/send.svg');
		position: inherit;
		width: 20px;
		height: 20px;
		background-repeat: no-repeat;
		top: 13px;
		left: 12px;
	}
	.tox .tox-tbtn--select:hover {
		background: #11a1ae;
	}
	.tox .tox-tbtn--select:active {
		background: #11a1ae !important;
	}
	.tox .tox-tbtn--select:focus {
		background: #11a1ae !important;
	}
	.tox:not([dir='rtl']) .tox-toolbar__group:first-of-type {
		border-right: 1px solid #ccc !important;
	}
	.tox .tox-tbtn:nth-of-type(9) {
		position: absolute;
		right: 95px;
		bottom: 0px;
	}
	.tox:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
		border-right: none;
	}
}
.data-test-template-content {
	.tox .tox-tbtn {
		background: #f2f7f9 !important;
		margin-right: 2px !important;
		margin-left: 2px !important;
		cursor: pointer !important;
		color: #222f3e !important;
	}
	.tox .tox-tbtn--select {
		margin: 2px 0 3px 0;
		padding: 0 4px;
		width: auto;
		background: #f2f7f9 !important;
		position: unset !important;
	}
	.tox .tox-tbtn__select-label {
		cursor: pointer !important;
	}
	.tox .tox-tbtn--select::before {
		background: none !important;
		width: 0px !important;
	}
	.tox .tox-tbtn:hover {
		background: #dee0e2 !important;
		border: 0.5px !important;
		color: #222f3e !important;
	}
	.tox .tox-tbtn--select:active {
		background: #dee0e2 !important;
	}
	.tox .tox-tbtn--select:focus {
		background: #dee0e2 !important;
	}
	.tox:not([dir='rtl']) {
		overflow: visible !important;
	}
	.tox .tox-editor-container {
		overflow: inherit !important;
	}
	.tox .tox-edit-area__iframe .tox-edit-area .tox-sidebar-wrap .tox-sidebar__slider {
		::-webkit-scrollbar {
			display: none;
		}
	}
}

.reply-template-toolbar {
	border-top: none !important;
	border-top-right-radius: 0px !important;
	border-top-left-radius: 0px !important;
	border-radius: 8px;
	border: 1px solid #e0e0e1;
	background-color: #fafafa;
}

.toolbar-wrapper {
	border-width: 1px !important;
	border-top: 1px solid #ccc !important;
	.tox-button {
		color: $white;
		background-color: $primary-brand-color--light !important;
		border-color: $primary-brand-color--light !important;
		border-radius: 8px !important;

		&:hover {
			background-color: darken($primary-brand-color--light, 7%) !important;
			border-color: darken($primary-brand-color--light, 7%) !important;
		}
	}
}
.char-remain {
	&-text {
		position: absolute;
		z-index: 999;
		bottom: 10px;
		right: 18%;
		font-size: 12px;
		font-weight: 500;
	}
	&-text-error {
		color: red;
		position: absolute;
		z-index: 999;
		bottom: 10px;
		right: 18%;
		font-size: 12px;
		font-weight: 500;
	}
}

.attachments {
	background: #f2f7f9;
	&-container {
		padding: 10px 0px;
		max-height: calc(100vh - 591px);
		overflow-y: auto;
		.uploads {
			background-color: #ffffff;
			padding: 5px 10px;
			@include flexbox();
			align-items: center;
			justify-content: space-between;
			border-radius: 3px;
			margin: 0 10px;
			margin-bottom: 8px;
			box-shadow: 0 0px 6px 1px rgba(214, 214, 214, 1);
			// box-shadow: 0px 0px 6px 1px rgba(214 214 214);
			.title {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
			}
			.progress {
				@include flexbox();
				justify-content: center;
				align-items: center;
				.close {
					font-size: 15px;
					cursor: pointer;
				}
			}
		}
	}
}
