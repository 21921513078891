.link-temp {
	color: #0e838d;
	gap: 9px;
	.material-icons {
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		font-size: 17px;
		line-height: 1;
		letter-spacing: normal;
		text-transform: none;
		display: inline-block;
		white-space: nowrap;
		word-wrap: normal;
		direction: ltr;
	}

	.link-temp-text {
		color: #0e838d;
		font-weight: bold;
		font-size: 16px;
	}

	.link-temp-icon {
		position: relative;
		font-size: 14px;
		font-weight: bold;
		top: 0px;
	}
}

.create-temp-modal {
	.template-modal-container {
		border-radius: 16px;
		.template-modal__header {
			border-radius: 16px 16px 0px 0px;
		}
		.channel-icon {
			width: 20px;
		}
	}
	.modal-container {
		width: 589px;
		background: #fff;
		margin: 0;
		border-radius: 0;
		margin-right: auto;
		margin-left: auto;
	}
}

.attachment-modal {
	z-index: 2000000;
	&-container {
		width: 488px;
		margin-left: auto;
		margin-right: auto;
		margin: 4rem 0;
		background-color: #ffffff;
		box-shadow: 0px 1px 1px rgba(214, 214, 214, 0.5);
		border-radius: 5px;
		.attachment-modal-body {
			padding: 2.5rem 2.5rem;
			.text-wrap {
				text-align: center;
				font-family: Proxima Nova;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;
				text-align: center;
				color: #1c2e59;
			}
			.title {
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 17px;
				text-align: center;
				color: #393f53;
			}
			.attachment-title {
				font-size: 14px;
			}
			.attachment-container {
				justify-content: center;
				gap: 20px;
				.attachment-wrapper {
					flex-direction: column;
					.attachment-item {
						width: 119px;
						height: 93px;
						background: rgba(17, 161, 174, 0.08);
						border-radius: 2px;
						.folder {
							color: #11a1ae;
							font-size: 26px;
						}
						.cloud {
							color: #6361d5;
							font-size: 26px;
						}
						.upload {
							background: #eeeeff;
						}
					}
					.right {
						background: #eeeeff;
					}
				}
			}
		}
		.attachment-modal-footer {
			justify-content: center;
			padding-bottom: 5.5rem !important;
		}
	}
	.attchment-modal {
		height: 40vh;
	}
}
.file-library-attachment-modal {
	z-index: 2000000;
	.modal-container {
		width: 714px;
		background: #ffffff;
		.file-library-modal-body {
			height: calc(100vh - 300px);
			overflow-y: scroll;
			.title-wrap {
				font-weight: bold !important;
				font-size: 16px;
				line-height: 19px;
				color: #1c2e59;
			}
			.text-wrap {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				color: #393f53;
			}
		}
	}
}
.files-wrapper-container {
	.files-wrapper {
		.files-name-wrapper {
			align-items: center;
			justify-content: space-between;
			width: 617px;
			height: 31px;
			background: #fafafa;
			padding: 0px 13px;
			padding-right: 16px;
			.file-name {
				gap: 10px;
				font-size: 10px;
				color: #777a82;
				p:first-child {
					font-weight: 500;
					font-size: 13px;
					color: #393f53;
				}
			}
			.file-link {
				color: #11a1ae;
				font-weight: bold;
				text-decoration: underline;
				font-size: 10px;
			}
		}

		.multi-select-marker {
			width: 30px;
			height: 30px;
			min-height: 30px;
			min-width: 30px;
			background-color: #fafafa;
			border-radius: 50%;
			display: -moz-flex;
			display: flex;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			.checkbox-icon-wrap-smaller {
				border-radius: 2px;
				border: 1px solid #11a1ae;
				color: #11a1ae;
			}
			input:checked + .checkbox-label .checkbox-icon-wrap-smaller {
				background-color: #ffff;
				color: #11a1ae;
				border: 1px solid transparent;
			}
			.custom-checkbox input:checked + .checkbox-label .checkbox-icon-wrap-smaller::before {
				content: '\e5ca';
				color: #11a1ae;
			}
		}
		.check {
			border: 1px solid #11a1ae;
			background-color: #11a1ae;
		}
	}
	.file-not-found-wrap {
		display: flex;
		width: 656px;
	}
}
.link-modal {
	.modal-container {
		width: 496px;
		.input-wrap {
			position: relative;
			.material-icons {
				position: absolute;
				width: 11.5px;
				height: 1px;
				left: 26px;
				top: 39px;
				background: #393f53;
			}
			input[type='text']::placeholder {
				/* Firefox, Chrome, Opera */
				position: absolute;
				left: 40px;
			}
		}
	}
}

.template-modal {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 200000;
	background: rgba(#f0f0f0, 0.9);
	overflow: scroll;
	&-container {
		margin: 4rem 0;
		background: #ffffff;
		box-shadow: 0 2px 8px 0 rgba(91, 88, 88, 0.5);
		border-radius: 5px;
		&.create-template-container {
			width: 40vw;
		}
		@include respond-to(mobile) {
			width: 100% !important;
		}
	}
	&__header {
		border-bottom: 1px solid rgba($border-color, 0.5);
		color: #fff !important;
		background-image: linear-gradient(to right, #11a1ae, #50b8a9);
		@extend %flexbox;
		padding: 1.5rem 2.5rem;
		color: #fff !important;
		background-image: linear-gradient(to right, #11a1ae, #50b8a9);
		.close-modal {
			color: #fff !important;
		}
		.btn-close-modal {
			border: 0;
			padding: 0;
			background: none;
			font-size: 3rem;
		}
		.close-modal {
			cursor: pointer;
			color: #fff;
		}
	}
	&__body {
		margin-bottom: 1rem;
		padding: 2.5rem 2.5rem;
		@include respond-to(mobile) {
			padding-top: 6px;
			padding-bottom: 0px;
		}
	}

	.file-library-modal-body {
		height: calc(100vh - 300px);
		overflow-y: scroll;
		.template-search-header-wrap {
			.title-wrap {
				font-weight: bold !important;
				font-size: 16px;
				line-height: 19px;
				color: #1c2e59;
			}
			.text-wrap {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 17px;
				color: #393f53;
			}
		}
	}

	&__footer {
		padding: 1.5rem 2.5rem;
		@extend %flexbox;
		@include respond-to(mobile) {
			justify-content: center;
			padding-top: 10px;
		}
		.btn--save,
		.btn--cancel {
			width: 100px;
		}
	}
}

.add-modal {
	.template-modal-container {
		width: 714px;
		background: #ffffff;
		&.all-templates {
			height: 583px;
			@include respond-to(mobile) {
				height: calc(100vh - 110px);
			}
		}
		.template-modal__body {
			height: 83%;
			@supports (-webkit-touch-callout: none) {
				height: 77%;
			}
		}
	}
	&.template {
		.template-modal-container {
			.template-modal__body {
				padding: 0px !important;
			}
			.template-modal_footer {
				padding: 5px 10px;
				border-top: 1px solid #dadada;
			}
		}
	}
}

.add-modal {
	.modal-container {
		width: 714px;
		background: #ffffff;
		height: 583px;
		.modal__body {
			height: 80%;
			.filters-card {
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				.content {
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					line-height: 30px;
					gap: 10px;
					span {
						font-weight: bold;
					}
				}
			}
		}
	}
	&.template {
		.modal-container {
			.modal__body {
				padding: 0px;
			}
			.modal_footer {
				padding: 5px 10px;
				border-top: 1px solid #dadada;
			}
		}
	}
}
