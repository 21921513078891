.message {
	.section-body {
		height: calc(100vh - 182px);
		overflow-y: auto;
	}
	.msg-status-link {
		&__item {
			position: relative;
			color: $primary-text-color;
			font-size: 1.6rem;
			line-height: 21px;
			padding-bottom: 1rem;
			margin-right: 3.5rem;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 4px;
				background-color: #3856de;
				left: 0;
				bottom: 0px;
				opacity: 0;
				visibility: hidden;
			}
			&.active:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&-list {
		width: 395px;
		height: calc(100vh - 182px);
		overflow-y: auto;
		&-item {
			padding: 1.6rem;
			background-color: $white;
			margin-bottom: 0.4rem;
			&.active {
				border-left: 5px solid #263ead;
				box-shadow: 0 9px 11px 0 rgba(139, 139, 139, 0.1);
			}
			.cont-right {
				margin-left: 1rem;
				.name {
					color: $primary-text-color;
					font-size: 1.3rem;
					font-weight: $medium;
					line-height: 16px;
					margin-bottom: 0.5rem;
				}
				.msg-desc {
					color: #393f53;
					font-size: 1.4rem;
					line-height: 18px;
					margin-bottom: 0.5rem;
				}
				.msg-time {
					color: rgba($primary-text-color, 0.7);
					font-size: 1.2rem;
					line-height: 16px;
				}
			}
		}
	}
	.chat {
		&-container {
			width: calc(100% - 405px);
			margin-left: 1rem;
			background-color: $white;
		}
		&-header {
			padding: 2.5rem;
			border-bottom: 1px solid rgba($border-secondary, 0.35);
			.cont-right {
				margin-left: 1rem;
			}
			.name {
				color: $primary-text-color;
				font-size: 1.8rem;
				font-weight: $medium;
				line-height: 23px;
				margin-bottom: 0.5rem;
			}
			.active-status {
				color: rgba($primary-text-color, 0.7);
				font-size: 1.2rem;
				line-height: 15px;
			}
		}
		&-body {
			padding: 2.5rem;
			height: calc(100vh - 366px);
			overflow-y: auto;
			border-bottom: 1px solid rgba($border-secondary, 0.35);
			.chat-row {
				width: 70%;
				margin-bottom: 2rem;
				&.msg-received {
					.chat-bubble {
						background-color: #f3f5ff;
					}
				}
				&.msg-sent {
					margin-left: auto;
					text-align: right;
				}
			}
			.chat-bubble-container {
				&:not(:first-child) {
					margin-top: 1rem;
				}
			}
			.chat-bubble {
				display: inline-block;
				padding: 1.5rem;
				color: #393f53;
				font-size: 1.4rem;
				line-height: 18px;
				border: 1px solid #ebebeb;
				border-radius: 4px;
				background-color: $white;
			}
			.chat-info {
				display: inline-block;
				color: rgba($primary-text-color, 0.7);
				font-size: 1.2rem;
				line-height: 15px;
				margin-top: 1rem;
			}
		}
		&-footer {
			padding: 2.5rem;
		}
	}
	.avatar-pic {
		@include avatar(33px, 33px, transparent, 100%);
		> img {
			@include avatar(100%, 100%, transparent, 100%);
		}
	}
}
