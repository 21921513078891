.btn {
	color: $primary-text-color;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: $medium;
	padding: 0.9rem 1.6rem;
	background: $white;
	border: 1px solid;
	border-color: #dbd9e4;
	border-radius: 2px;
	cursor: pointer;
	@include transition(all 0.3s ease);
	> .material-icons {
		font-size: 2rem;
	}
	&:hover {
		background: lighten($primary-brand-color--light, 40%);
		border-color: $primary-brand-color--light;
	}
	&.active {
		background: lighten($primary-brand-color--light, 40%);
		border-color: $primary-brand-color--light;
	}
	&:disabled {
		cursor: not-allowed;
	}
	&--pagination {
		padding: 0.4rem 1.3rem;
		&:hover {
			background: lighten($primary-brand-color--light, 40%);
			border-color: $primary-brand-color--light;
		}
	}
	&--primary {
		color: $white;
		background: $primary-brand-color--light;
		border-color: $primary-brand-color--light;
		&--swap {
			margin-top: 6px;
			line-height: 1.6rem;
		}
		&:hover {
			background-color: darken($primary-brand-color--light, 7%);
			border-color: darken($primary-brand-color--light, 7%);
		}
		.active {
			background-color: darken($primary-brand-color--light, 7%);
			border-color: darken($primary-brand-color--light, 7%);
		}
	}
	&--secondary {
		color: rgba(0, 0, 0, 0.75);
		background: $secondary-brand-color;
		border-color: #e5b762;
		&:hover {
			background-color: darken($secondary-brand-color, 7%);
			border-color: darken(#e5b762, 7%);
		}
	}
	&--primary--inverse {
		color: $primary-brand-color--light;
		background: #f4f7f8;
		border-color: $primary-brand-color--light;
		&:hover {
			color: $white;
			background-color: darken($primary-brand-color--light, 7%);
			border-color: darken($primary-brand-color--light, 7%);
		}
		.active {
			color: $white;
			background-color: darken($primary-brand-color--light, 7%);
			border-color: darken($primary-brand-color--light, 7%);
		}
	}
	&--green {
		color: rgba(0, 0, 0, 0.75);
		background: #5bc553;
		border-color: #5bc553;
		&:hover {
			background-color: darken(#5bc553, 7%);
			border-color: darken(#5bc553, 7%);
		}
	}
	&--blue {
		color: $white;
		background: $info;
		border-color: $info;
		&:hover {
			background-color: darken($info, 7%);
			border-color: darken($info, 7%);
		}
	}
	&--dark {
		color: $white;
		background: $dark;
		border-color: $dark;
		&:hover {
			background-color: darken($dark, 7%);
			border-color: darken($dark, 7%);
		}
	}
	&--orange {
		color: $white;
		background: $nav-bottom;
		border-color: $nav-bottom;
		&:hover {
			background-color: darken($nav-bottom, 7%);
			border-color: darken($nav-bottom, 7%);
		}
	}
	&--smaller {
		padding: 0.5rem 1rem !important;
	}
	&--small {
		padding: 0.5rem 1.5rem !important;
	}
	&--lg {
		padding: 1rem 1.6rem !important;
	}
	&--width-auto {
		width: auto !important;
	}
	&--filter,
	&--sort {
		position: relative;
		min-width: 120px;
		padding-left: 4rem;
		padding-right: 4rem;
		text-align: left;
		border: 1px solid $border-color;
	}
	&--filter {
		min-width: 135px;
		position: relative;
		.total {
			border-radius: 30%;
			position: absolute;
			top: -8px;
			right: -4px;
			color: #fff;
			font-size: 1.1rem;
			font-weight: 600;
			line-height: 1.4rem;
			padding: 0.3rem 0.5rem;
			background: #ec5959;
		}
		&::after,
		&::before {
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 2rem;
			display: inline-block;
			line-height: 1;
			text-transform: none;
			letter-spacing: normal;
			word-wrap: normal;
			white-space: nowrap;
			direction: ltr;
		}
		&::after {
			position: absolute;
			top: 8px;
			left: 8px;
			content: '\E152';
		}
		&::before {
			position: absolute;
			top: 8px;
			right: 8px;
			content: '\E5C5';
		}
		&--small {
			padding-top: 5px;
			padding-bottom: 5px;
			padding-right: 2.2rem;
			padding-left: 3.1rem;
			min-width: 106px;
			&::after {
				top: 5px;
			}
			&::before {
				content: none;
			}
		}
	}
	&--sort {
		min-width: 120px;
		position: relative;
		&::after,
		&::before {
			font-family: 'Material Icons';
			font-weight: normal;
			font-style: normal;
			font-size: 2rem;
			display: inline-block;
			line-height: 1;
			text-transform: none;
			letter-spacing: normal;
			word-wrap: normal;
			white-space: nowrap;
			direction: ltr;
		}
		&::after {
			position: absolute;
			top: 8px;
			left: 8px;
			content: '\E164';
		}
		&::before {
			position: absolute;
			top: 8px;
			right: 8px;
			content: '\E5C5';
		}
		&--small {
			padding-top: 5px;
			padding-bottom: 5px;
			padding-right: 1.8rem;
			padding-left: 3.1rem;
			min-width: 92px;
			&::after {
				top: 5px;
			}
			&::before {
				content: none;
			}
		}
	}
	&.btn-round {
		border-radius: 8px;
	}
	&--generate-link {
		border-radius: 5px;
		background: #0491ab;
		color: #fff;
		font-size: 1.2rem;

		&:hover {
			background: #0491ab;
		}
	}
}
