@import 'scss/helpers/variable.scss';

@mixin transition($transition...) {
	-moz-transition: $transition;
	-o-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}
@mixin word-wrap() {
	-ms-word-break: break-word;
	word-break: break-word;
	-webkit-hyphens: auto;
	-moz-hyphens: auto;
	hyphens: auto;
}
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}
// grid layout
@mixin grid($cols, $mgn) {
	float: left;
	margin-right: $mgn;
	width: ((100% - (($cols - 1) * $mgn)) / $cols);
	&:nth-child(#{$cols}n) {
		margin-right: 0;
	}
}
@mixin table-grid($cols) {
	.table-container {
		thead {
			th {
				width: 100% / $cols;
			}
		}
		tbody {
			td {
				width: 100% / $cols;
			}
		}
	}
}
// Media queries
@mixin respond-to($media) {
	@if $media == mobile-sm {
		@media only screen and (max-width: $bp-mobile-sm) {
			@content;
		}
	}
	@if $media == mobile {
		@media only screen and (max-width: $bp-mobile) {
			@content;
		}
	}
	@if $media == mobile-ls {
		@media only screen and (max-width: $bp-mobile-ls) {
			@content;
		}
	} @else if $media == tablet {
		@media only screen and (max-width: $bp-tablet) {
			@content;
		}
	} @else if $media == tablet-ls {
		@media only screen and (min-width: $bp-tablet) and (max-width: $bp-tablet-ls) {
			@content;
		}
	} @else if $media == wide-screen {
		@media only screen and (max-width: $bp-large) {
			@content;
		}
	} @else {
		@media only screen and (max-width: $media) {
			@content;
		}
	}
}
// Placeholder
@mixin placeholder() {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
// flexbox mixins
@mixin flexbox() {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin inline-flexbox() {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: -moz-inline-box;
	display: inline-flex;
}
@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}
@mixin align-items($value: stretch) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}
@mixin align-self($value: auto) {
	// No Webkit Box Fallback.
	-webkit-align-self: $value;
	-moz-align-self: $value;
	@if $value == flex-start {
		-ms-flex-item-align: start;
	} @else if $value == flex-end {
		-ms-flex-item-align: end;
	} @else {
		-ms-flex-item-align: $value;
	}
	align-self: $value;
}
@mixin flex-wrap($value: nowrap) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $value;
	}
	flex-wrap: $value;
}
@mixin order($int: 0) {
	-webkit-box-ordinal-group: $int + 1;
	-webkit-order: $int;
	-moz-order: $int;
	-ms-flex-order: $int;
	order: $int;
}
@mixin flex-grow($int: 0) {
	-webkit-box-flex: $int;
	-webkit-flex-grow: $int;
	-moz-flex-grow: $int;
	-ms-flex-positive: $int;
	flex-grow: $int;
}
@mixin flex-basis($value: auto) {
	-webkit-flex-basis: $value;
	-moz-flex-basis: $value;
	-ms-flex-preferred-size: $value;
	flex-basis: $value;
}
@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}
@mixin rotate($val) {
	-webkit-transform: rotate($val);
	-moz-transform: rotate($val);
	-ms-transform: rotate($val);
	-o-transform: rotate($val);
	transform: rotate($val);
}
@mixin transform-origin($left: left, $center: center) {
	-webkit-transform-origin: $left $center;
	-moz-transform-origin: $left $center;
	-o-transform-origin: $left $center;
	transform-origin: $left $center;
}
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
// translate
@mixin translate($x, $y) {
	@include transform(translate($x, $y));
}
@mixin scale($scale) {
	@include transform(scale($scale));
}
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}
@mixin avatar($width, $height, $bg-color, $radius) {
	width: $width;
	height: $height;
	min-height: $height;
	min-width: $width;
	background-color: $bg-color;
	@include border-radius($radius);
}
//Text Elipsis
@mixin text-elipsis() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
