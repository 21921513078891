.template-list {
	position: relative;
	margin-bottom: 2rem;
	margin-top: 2rem;
	.cont-wrap {
		width: 100%;
		height: 175px;
		padding: 2.4rem;
		border-radius: 4px;
		background-color: $white;
		box-shadow: 5px 10px 8px rgba(243, 230, 230, 0.5);
		cursor: pointer;
		transition: box-shadow 300ms;
		&:hover {
			box-shadow: 0px 10px 8px rgba(198, 190, 190, 0.5);
		}
	}
	.left-sec {
		margin-right: 2.5rem;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		.btn {
			width: 110px;
			margin-top: auto;
		}
		.title {
			color: $primary-text-color;
			font-size: 1.6rem;
			font-weight: $medium;
			line-height: 2.1rem;
			margin-bottom: 1rem;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		.desc {
			color: rgba($primary-text-color, 0.6);
			font-size: 1.4rem;
			line-height: 1.9rem;
			margin-bottom: 1.8rem;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		.btn > .material-icons {
			color: rgba($primary-text-color, 0.6);
		}
	}
	.right-sec {
		.steps-wrap {
			width: 78px;
			padding: 0.8rem;
			background-color: rgba(#28a25f, 0.1);
			border-radius: 2px;
			text-align: center;
			.title {
				color: rgba(#28a25f, 0.96);
				font-size: 1.2rem;
				font-weight: $medium;
				line-height: 1.5rem;
				margin-bottom: 0.5rem;
			}
			.desc {
				color: #28a25f;
				font-size: 3.4rem;
				line-height: 4.3rem;
			}
		}
	}
	.check-circle-wrap {
		position: absolute;
		top: -10px;
		right: 6px;
		width: 26px;
		height: 26px;
		background-color: #28a25f;
		border: 2px solid $white;
		border-radius: 50%;
		visibility: hidden;
		opacity: 0;
		transition: opacity 100ms;
		> .check-icon {
			position: relative;
			top: 1px;
			left: 1px;
			font-size: 2rem;
			color: $white;
		}
	}
	input:checked + label {
		background: linear-gradient(132.22deg, #066066 0%, #11a1ae 53.2%, #109aa7 100%);
		box-shadow: 0px 10px 8px rgba(198, 190, 190, 0.5);
		.left-sec {
			.title {
				color: $white;
			}
			.desc {
				color: rgba(#fbfbfb, 0.6);
			}
			.btn {
				background-color: $white;
			}
		}
		.right-sec {
			.steps-wrap {
				background-color: rgba($white, 0.39);
			}
			.title {
				color: rgba($white, 0.96);
			}
			.desc {
				color: $white;
			}
		}
		.check-circle-wrap {
			visibility: visible;
			opacity: 1;
		}
	}
}
.template-wrap {
	overflow-y: auto !important;
	max-height: calc(100vh - 260px) !important;
	align-items: stretch;
}
.template-body {
	padding: 0rem 3rem 2rem 3rem;
}
