.tabs-container {
	border-bottom: 1px solid #f1efef;
	.tab {
		background: transparent;
		color: #1c2e59;
		display: inline-block;
		padding: 15px 0px;
		margin-right: 25px;
		font-weight: $semibold;
		font-size: 1.6rem;
		border-radius: 10px 10px 0 0;
		cursor: pointer;
		border-bottom: 0;
		&::after {
			content: '';
			transform: scale(0);
			@include transition(all 0.3s ease);
			transform-origin: center, center;
		}
		&.active {
			font-weight: $semibold;
		}
		&.active,
		&:hover {
			position: relative;
			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				transform: scale(1);
				height: 2px;
				background: #fbbe4d;
			}
		}
	}
	.notification-tab {
		background: transparent;
		color: #1c2e59;
		display: inline-block;
		padding: 15px 0px;
		margin-right: 25px;
		font-weight: $semibold;
		font-size: 1.6rem;
		border-radius: 10px 10px 0 0;
		cursor: pointer;
		border-bottom: 0;
		&::after {
			content: '';
			transform: scale(0);
			@include transition(all 0.3s ease);
			transform-origin: center, center;
		}
		&.active {
			font-weight: $semibold;
		}
		&.active,
		&:hover {
			position: relative;
			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				transform: scale(1);
				height: 2px;
				background: #11a1ae;
			}
		}
		.tab_content {
			margin-left: 5px;
			.count_wrap {
				width: 21px;
				background: rgba(161, 161, 163, 0.13);
				border-radius: 4px;

				.count {
					text-align: center;
					font-size: 14px;
					font-weight: 500;
					.count_value {
						font-size: 12px;
						font-weight: 600;
						&.active {
							color: #00abba !important;
						}
					}
				}
				.active {
					background: rgba(17, 161, 174, 0.08);
				}
			}
			.active {
				background: rgba(17, 161, 174, 0.08) !important;
			}
		}
	}
	.tab-right {
		float: right;
		display: flex;
		align-items: center;

		&-end {
			margin-left: auto;

			.link-title {
				cursor: pointer;
				font-size: 1.5rem;
				font-weight: 700;
				margin-left: 15px;
				color: #378daa;
			}
		}

		.btn {
			color: #11a1ae;
			border-color: #11a1ae;
		}
	}
}
