.service-enquiry-bot {
	.section-header {
		padding-bottom: 1rem;
		border-bottom: 1px solid $border-color;
	}
	.item-left,
	.item-right {
		width: 100%;
		margin-bottom: 2rem;
	}
	.item-left {
		.chat-bubble {
			position: relative;
			.add-circle-svg {
				position: absolute;
				right: -16px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
			}
		}
	}
	.item-right {
		@include flexbox();
		@include justify-content(flex-end);
		.chat-bubble-container {
			position: relative;
			width: 100% !important;
			margin-left: 0 !important;
			.chat-bubble {
				border-bottom-left-radius: 22px;
				border-top-left-radius: 22px;
				border-bottom-right-radius: 10px;
				border-top-right-radius: 0;
				border: 1px solid #ffbb29;
				background-color: #fff5e0;
			}
		}
	}
	.chat-container {
		max-width: 70%;
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			border-radius: 40px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 20px;
			background-color: $white;
			box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31);
			&:not(:first-of-type) {
				margin-top: 10px;
			}
			&__tile,
			&__cont {
				color: primary-text-color;
				font-weight: $medium;
				letter-spacing: 0.2px;
				line-height: 1.9rem;
			}
			&__title {
				color: rgba($primary-text-color, 0.7);
				font-size: 1.3rem;
				margin-bottom: 0.5rem;
				text-transform: uppercase;
			}
			&-container {
				width: calc(100% - 56px);
				margin-left: 0.8rem;
			}
		}
	}
	.input-wrap {
		position: relative;
		.bot-input-field {
			padding: 1.4rem 5.5rem 1.4rem 2rem;
			background-color: $white;
			border-radius: 30px;
		}
		.btn-send-msg {
			position: absolute;
			top: 6px;
			right: 6px;
		}
	}
	.review-board,
	.add-comments {
		.title {
			color: $primary-text-color;
			font-weight: $bold;
			letter-spacing: 0.6px;
			line-height: 17px;
		}
	}
	.review-board {
		.cont-wrap {
			padding: 2rem;
			background-color: #f4f4f4;
			border-radius: 2px;
		}
	}
	.add-comments {
		.chat-bubble-container {
			position: relative;
			width: initial !important;
			margin-left: 0 !important;
			.chat-bubble {
				padding-right: 4rem;
			}
		}
		.m-icon {
			@include avatar(31px, 31px, $white, 100%);
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
			.material-icons {
				font-size: 1.8rem;
			}
			&--edit {
				background-color: #ffbb29;
				color: $white;
				cursor: pointer;
			}
			&--delete {
				cursor: pointer;
			}
			&-wrap {
				position: absolute;
				top: 50%;
				right: -6px;
				transform: translateY(-50%);
			}
		}
	}
}
