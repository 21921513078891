.all-users {
	.avatar-pic {
		@include avatar(28px, 28px, $border-color, 100%);
		text-align: center;
		line-height: 30px;
		font-weight: bold;
		background: #77858d;
		color: white;
		> img {
			@include avatar(100%, 100%, $border-color, 100%);
		}
	}
}
.tooltip-user {
	position: absolute;
	right: 0;
	width: 300px;
	background: #fafbff;
	padding: 5px 10px;
	border-radius: 3px;
	color: black;
	z-index: 30;
	opacity: 0;
	visibility: hidden;
	&-wrap {
		position: relative;
		cursor: pointer;
		.material-icons {
			color: rgba($primary-text-color, 0.6);
			font-size: 18px;
		}
		&:hover {
			.tooltip-user {
				opacity: 1;
				visibility: visible;
				width: max-content;
				background: black;
				color: white;
				font-size: 12px;
				font-weight: 600;
			}
		}
	}
	&.top {
		bottom: 35px !important;
	}
	&.bottom {
		bottom: -39px !important;
	}
	&.left {
		right: 35px !important;
		top: 19px !important;
	}
}
