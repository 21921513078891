* {
	@extend %primary-font;
}

html {
	font-size: 62.5%; /* Sets up the Base 10 stuff */
	color: $primary-text-color;
}

body,
input,
input::placeholder,
select,
button,
textarea {
	font-size: 1.4rem;
	line-height: 2rem;
	color: $primary-text-color;
}
