.landing-page {
	width: 775px;
	overflow: auto;
	.menu-options__body {
		height: initial !important;
		overflow: initial !important;
	}
	&--edit {
		.landing-page-settings__body {
			padding-bottom: 3rem;
			border-bottom: 1px solid rgba($border-color, 0.5);
		}
	}
	.company-logo {
		position: relative;
		@include avatar(87px, 87px, rgba(32, 45, 86, 0.04), 100%);
		border: 1px dashed rgba(32, 45, 86, 0.33);
		cursor: pointer;
		.plus-icon {
			color: $primary-brand-color;
			font-size: 4.5rem;
			text-align: center;
		}
		> img {
			@include avatar(100%, 100%, transparent, 100%);
			object-fit: cover;
		}
	}
	.bg-theme {
		width: 87px;
		height: 28px;
		border: 3px solid #cfcccc;
		background-color: #545454;
	}
	.upload-pic {
		width: 100%;
		height: 200px;
		border: 1px dashed $border-secondary;
		background-color: #dfe4fa;
		cursor: pointer;
		&__txt {
			color: $primary-brand-color--light;
			font-size: 1.5rem;
			font-weight: $medium;
			line-height: 1.9rem;
		}
	}
	&--view {
		.logo-container {
			@include avatar(87px, 87px, transparent, 100%);
			> img {
				@include avatar(87px, 87px, transparent, 100%);
			}
		}
		.hero-pic {
			width: 100%;
			height: 216px;
			> img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}
