.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 20;
	@extend %flexbox;
	@include align-items(center);
	background-color: #ffffff;
	border-bottom: 1px solid #dfdfdf;
	&.bg-white {
		border-bottom: 1px solid #dfdfdf;
		background-color: $white;
	}
	.bot-logo {
		width: 61px;
		height: 54px;
		background-color: #22243d;
		text-align: center;
		@include respond-to(mobile) {
			background-color: #22243d;
		}
		> img {
			height: 30px;
			width: 30px;
		}
	}
	.menu {
		display: none;
		@include respond-to(mobile) {
			display: block;
		}
		.material-icons {
			color: #f4f4f4;
			opacity: 0.6;
			font-size: 28px;
		}
		padding: 8px 9px;
	}
	.nav {
		&-primary {
			padding: 1.5rem 2.5rem;
			.nav-item {
				font-size: 1.8rem;
				font-weight: $medium;
				line-height: 2.4rem;
				color: $primary-text-color;
				&.active {
					color: $primary-brand-color--light;
					pointer-events: none;
				}
				&:not(:first-child) {
					&::before {
						content: '/';
						padding-right: 0.5rem;
						color: $primary-text-color;
					}
				}
			}
		}
		&-secondary {
			.nav-item {
				position: relative;
				&:last-child {
					padding: 0.9rem;
				}
			}
			.header-action {
				width: 40px;
				height: 40px;
				border: 1px solid #e0e0e1;
				background: #fafafa;
				border-radius: 5px;
				position: relative;
				cursor: pointer;
				> i {
					font-size: 2rem;
					line-height: 4rem;
					color: #5e7281;
				}
				.help-btn {
					text-align: center;
					padding-top: 6px;
					.help-btn-img {
						height: 2.75rem;
						color: #5e7281;
					}
				}
				.notification-btn {
					text-align: center;
					padding-top: 6px;
					.notification-btn-img {
						width: 16px;
						height: 16px;
					}
				}
				.notication-tooltip-design {
					padding: 0.4px 8px;
					line-height: 34px !important;
				}
				.dot {
					position: absolute;
					top: -11px;
					font-size: 24px;
					right: 2px;
					color: #ff0000;

					.notification_count {
						color: #fff;
						right: 5px;
						width: 14px;
					}
				}
			}
			.profile-pic {
				@include avatar(36px, 36px, white, 50%);
				overflow: hidden;
				border: 3px solid #f6f6f6;
				cursor: pointer;
				> img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				.user-name {
					color: #1f2236;
				}
			}
		}
	}
}

.nav-user-info {
	text-align: center;
	line-height: 30px;
	font-weight: bold;
	background: #77858d;
	color: white;
	border-radius: 50%;
}
