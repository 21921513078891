.editor {
	position: relative;
	box-sizing: border-box;
	border: 1px solid #ddd;
	cursor: text;
	padding: 1px;
	margin: 0px 10px;
	border-radius: 2px;
	margin-bottom: 2em;
	box-shadow: inset 0px 1px 8px -3px #ababab;
	background: #fefefe;
	max-height: 123px;
	min-height: 120px;
}

.full {
	margin: 0px;
	max-height: 84px;
	min-height: 80px;
	.public-DraftEditor-content {
		min-height: 80px !important;
		max-height: 80px !important;
		overflow-y: auto !important;
	}
}
.editor .public-DraftEditor-content {
	min-height: 120px;
	max-height: 120px;
	overflow-y: auto;
}

.editor :global(.public-DraftEditor-content) {
	min-height: 140px;
}

.editor .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
	background: #e6fdff;
}

.editor .draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
	font-family: proxima-nova, sans-serif;
}

.editor .public-DraftEditorPlaceholder-root {
	color: #999;
	position: absolute;
}

.public-DraftEditorPlaceholder-inner {
	padding: 10px;
}

.task_editor_wrap {
	position: relative;
	// margin-top: 20px;
	padding-top: 5px;
	width: 100%;
	margin: 0;
	.task_editor {
		max-height: 45px;
		min-height: 45px;
		border-radius: 12px;
		border: 1px solid #dadada;
		transition: border 0.3s;
		box-shadow: none;
		margin: 0;
	}

	.task_editor .public-DraftEditor-content {
		max-height: 40px;
		min-height: 41px;

		@media only screen and (min-width: 320px) and (max-width: 374px) {
			padding-right: 25px;
		}
		@media only screen and (min-width: 375px) and (max-width: 424px) {
			padding-right: 25px;
		}
	}

	.task_editor .draftJsMentionPlugin__mentionSuggestions__2DWjA {
		top: -120px !important;
	}

	.task_editor .DraftEditor-root .DraftEditor-editorContainer {
		background-color: rgba(237, 248, 249, 0.55);
		border-radius: 12px;
	}
}
