.dashboard-header {
	display: flex;
	justify-content: space-between;
	padding: 2rem 3rem;
	background-color: #54b1bb;
	align-items: center;

	&-card {
		display: flex;

		.dashboard-text {
			.title {
				font-size: 2.8rem;
				font-weight: 700;
				color: #fff;
				margin-bottom: 5px;
			}

			.sub-title {
				font-size: 1.5rem;
				color: #fff;
			}
		}
	}
}

.dashboard-main-container {
	display: flex;
	width: 100%;

	.home-left {
		background-color: #fff;
		overflow-y: scroll;
		height: 800px;
		padding-top: 2rem;

		.action-main-container {
			.tab {
				font-size: 18px;
				&.active::after {
					background: #1f2235;
				}
			}
			.tab:hover::after {
				background: #1f2235;
			}
		}

		.home-left-header {
			padding: 0 2rem 0 4rem;
			.tab {
				font-size: 18px;
				&.active::after {
					background: #ee5b4f;
				}
			}
			.tab:hover::after {
				background: #ee5b4f;
			}
		}

		.menu-options__body {
			height: calc(100vh - 160px) !important;
			padding: 0 2rem 0 4rem;
			overflow-y: scroll;

			.home-left-container {
				/*enquiry */
				.enquiry-wrap {
					.enquiry-title {
						font-size: 16px;
						font-weight: 600;
					}

					.enquiry-container {
						background-color: #f3f7f7;

						.enquiry-item {
							border: 1px solid #e9e9e9;
							border-radius: 2px;
							padding: 1.3rem 1.4rem;

							&-head {
								display: flex;
								justify-content: space-between;

								&-title {
									font-size: 16px;
									font-weight: 500;
								}

								&-count {
									font-size: 30px;
									font-weight: 600;
								}

								&:after {
									content: '';
									position: absolute;
									left: 4%;
									width: 92%;
									top: 40%;
									height: 1px;
									margin-top: -1px;
									background: #e9e9e9;
								}
							}

							&-desc {
								display: flex;
								justify-content: space-between;
							}
						}
					}
				}

				/*productivity*/
				.productivity-wrap {
					.Productivity-title {
						font-size: 16px;
						font-weight: 600;
					}

					.product-main {
						display: flex;
						width: 100%;
						justify-content: space-between;
					}

					.productivity-container {
						border-radius: 2px;
						display: flex;
						justify-content: space-between;
						padding: 2rem 3.8rem 2rem 1.4rem;
						width: 32%;
						cursor: pointer;
						border: 2px solid #e9e9e9;

						.prod-image {
							width: 38px;
							height: 46px;
						}

						.prod-content {
							&-title {
								font-size: 1.5rem;
								color: rgba(57, 63, 83, 0.6);
							}

							&-count {
								float: right;
								font-size: 30px;
								font-weight: 600;
							}
						}

						&:hover {
							border: 2px solid #11a1ae;
						}

						&.active {
							border: 2px solid #11a1ae !important;
						}
					}
				}

				.action-main-wrap {
					display: flex;
					justify-content: space-between;
					width: 100%;

					.action-type-wrap {
						width: 194px;

						.action-type {
							display: flex;
							padding: 1rem 2rem;
							justify-content: space-between;
							box-shadow: 0 0 0 2px #e9e9e9;
							border-radius: 2px;

							.actions {
								align-items: center;
								display: flex;

								.icon {
									margin-right: 5px;
									color: #11a1ae;
									font-size: 16px;
								}

								.name {
									font-size: 15px;
									color: rgba(57, 63, 83, 0.6);
								}
							}

							.action-count {
								font-size: 2.4rem;
								font-weight: 600;
								margin-left: 51px;
							}
						}
					}
				}

				/*sales*/
				.sales-main-container {
					padding-bottom: 20rem;

					.sales-title {
						font-size: 16px;
						font-weight: 600;
					}

					.sales-status-types {
						border: 2px solid #e9e9e9;
						border-radius: 2px;
						padding: 3rem 2.2rem;

						.sales-option-wrap {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;

							.sales-option-selector {
								display: flex;
								height: 36px;
								width: 458px;
							}

							.view-report {
								color: #398ba3;
								font-weight: 700;
							}
						}

						.sales-status-list {
							display: flex;
							width: 100%;
							justify-content: space-between;

							&-item {
								width: 31%;
								border: 1px solid #e9e9e9;
								border-radius: 2px;
								position: relative;

								.status-header {
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding: 1.5rem 1rem;

									.status {
										display: flex;
										align-items: center;

										.icon {
											width: 28px;
											height: 28px;
										}

										.status-text {
											font-size: 15px;
											color: rgba(57, 63, 83, 0.6);
										}
									}

									.status-count {
										font-size: 2.4rem;
										font-weight: 600;
									}
								}

								.status-value {
									display: flex;
									justify-content: space-between;
									align-items: center;
									padding: 1.5rem 1rem;
									background-color: #f3f7f7;

									width: 249px;
									position: relative;
									left: -1px;
									top: 1px;
									border-radius: 2px;

									.total-value {
										font-size: 12px;
										color: rgba(57, 63, 83, 0.6);
									}

									.total-cost {
										font-size: 16px;
										font-weight: 500;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.home-right {
		padding: 2rem 4rem 0 0rem;
		height: 800px;
		overflow-y: auto;
		.tab {
			font-size: 18px;
			&.active::after {
				background: #ee5b4f;
			}
		}
		.tab:hover::after {
			background: #ee5b4f;
		}
	}
}
