input,
textarea {
	font-size: 1.4rem;
	line-height: 2rem;
	padding: 0.9rem 1.6rem;
	background: $white;
	border: 1px solid $border-color;
	border-radius: 2px;
	@include transition(border 300ms);
	&::placeholder {
		font-size: 1.4rem;
		line-height: 2.4rem;
		color: rgba($primary-text-color, 0.6);
	}
	&.search-box {
		padding-left: 3rem;
	}
	&:focus {
		border-color: $primary-brand-color--light;
	}
}
.input-date {
	width: 235px;
	padding-right: 3.5rem;
	background: $white url(/img/date-range1.svg) right 6px center no-repeat;
}
.search-wrap {
	position: relative;
	&::after {
		position: absolute;
		top: 11px;
		left: 8px;
		content: '\E8B6';
		font-family: 'Material Icons';
		font-weight: normal;
		font-style: normal;
		font-size: 2rem;
		display: inline-block;
		line-height: 1;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
	}
	.search-box {
		width: 102%;
	}
	&-right {
		&::after {
			top: 11px;
			right: 5px;
			left: unset;
		}
		.search-box {
			padding-right: 1.5rem;
			padding-left: 1.3rem;
		}
	}
	&--primary {
		&:focus-within {
			&::after {
				color: $primary-brand-color--light;
			}
			.search-box {
				border-color: $primary-brand-color--light;
				background-color: $filter-background;
			}
		}
	}
}
.input-field {
	&-sub {
		padding: 0.7rem 1rem;
	}
	&-small {
		padding: 0.4rem 1rem;
	}

	&-secondary {
		background-color: #f3f7f9;
		border-color: #cbd6e2;
		&:focus {
			border-color: #7d90a5;
		}
	}
	&:disabled {
		background: #f2f2f2;
		color: #999999;
	}
}
.input-wrap {
	margin-bottom: 1.5rem;
	.input-label {
		font-size: 1.4rem;
		line-height: 2rem;
		font-weight: $semibold;
		margin-bottom: 0.5rem;
	}
	.input-field {
		width: 100%;
	}
}
.input-wrap-2 {
	background: #fafafa;
	border: 1px solid #e0e0e1;
}
.input-label {
	font-size: 1.4rem;
	line-height: 2rem;
	font-weight: $medium;
	margin-bottom: 0.5rem;
}
input[type='file'],
input[type='checkbox'],
input[type='radio'] {
	display: none;
}
.custom-checkbox {
	.checkbox {
		&-label {
			cursor: pointer;
		}
		&-icon-wrap {
			@include avatar(19px, 19px, $white, 0);
			min-width: 19px;
			border: 1px solid #11a1ae;
			position: relative;
			&-small {
				@include avatar(15px, 15px, $white, 0);
				min-width: 15px;
				&::before {
					font-size: 13px !important;
					position: absolute;
				}
			}
			&-smaller {
				@include avatar(12px, 12px, $white, 0);
				min-width: 12px;
				&::before {
					font-size: 10px !important;
					position: absolute;
				}
			}
			&::before {
				content: '';
				font-family: 'Material Icons';
				font-style: normal;
				font-size: 17px;
				font-weight: $bold;
				display: inline-block;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;
				@include transition(all 0.3s ease);
				color: $white;
			}
		}
		&-txt {
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.6rem;
			margin-left: 1.5rem;
		}
	}
	input:checked + .checkbox-label .checkbox-icon-wrap {
		background-color: #12b13f;
		border: 1px solid transparent;
		&::before {
			content: '\e5ca';
			color: $white;
		}
	}
	input:checked + .checkbox-label .checkbox-icon-wrap-primary {
		background-color: $primary-brand-color--light;
		border: 1px solid transparent;
		&::before {
			content: '\e5ca';
			color: $white;
		}
	}
	input:checked + .checkbox-label .checkbox-icon-wrap-teal {
		background-color: #11a1ae !important;
	}
	input:checked + .checkbox-label .checkbox-icon-wrap-smaller {
		background-color: #12b13f;
		border: 1px solid transparent;
		&::before {
			content: '\e5ca';
			color: $white;
		}
	}
	input:checked + .checkbox-label .checkbox-icon-wrap.secondary {
		background-color: #11a1ae;
		border: 1px solid transparent;
		&::before {
			content: '\e5ca';
			color: $white;
		}
	}
}
.date-range {
	&-wrap {
		width: 220px;
	}
	&-selector {
		width: 100%;
		background: $white
			url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTggMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU3LjEgKDgzMDg4KSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDx0aXRsZT4yNHB4PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjI0cHgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zLjAwMDAwMCwgLTEuMDAwMDAwKSI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNywxMiBMMTIsMTIgTDEyLDE3IEwxNywxNyBMMTcsMTIgWiBNMTYsMSBMMTYsMyBMOCwzIEw4LDEgTDYsMSBMNiwzIEw1LDMgQzMuODksMyAzLjAxLDMuOSAzLjAxLDUgTDMsMTkgQzMsMjAuMSAzLjg5LDIxIDUsMjEgTDE5LDIxIEMyMC4xLDIxIDIxLDIwLjEgMjEsMTkgTDIxLDUgQzIxLDMuOSAyMC4xLDMgMTksMyBMMTgsMyBMMTgsMSBMMTYsMSBaIE0xOSwxOSBMNSwxOSBMNSw4IEwxOSw4IEwxOSwxOSBaIiBpZD0iU2hhcGUiIGZpbGw9IiMwMDAwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIgb3BhY2l0eT0iMC43Ij48L3BhdGg+CiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBwb2ludHM9IjAgMCAyNCAwIDI0IDI0IDAgMjQiPjwvcG9seWdvbj4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
			right 10px center no-repeat;
		padding-right: 3rem;
		cursor: pointer;
	}
}
/* toggle slider*/
.toggle-slider {
	&__dropDown {
		width: 30%;
	}
	&__checkbox {
		width: initial;
		position: absolute;
		opacity: 0;
		&:checked + label .toggle-slider__ball {
			background: $green;
			&:before {
				left: 24px;
			}
		}
		&:checked + label .toggle-slider__txt {
			color: $primary-text-color;
		}
		&__small {
			&:checked + label .toggle-slider__ball__small {
				background: $green;
				&:before {
					left: 17px;
				}
			}
			&:disabled + label .toggle-slider__ball {
				cursor: no-drop;
			}
			&__primary {
				&:checked + label .toggle-slider__ball__small__primary {
					background: #005c6f !important;
					&:before {
						left: 17px;
					}
				}
				&:disabled + label .toggle-slider__ball {
					cursor: no-drop;
				}
			}
		}
	}
	&__ball {
		display: inline-block;
		vertical-align: middle;
		width: 42px;
		height: 20px;
		background: rgba($primary-text-color, 0.6);
		border-radius: 22px;
		cursor: pointer;
		padding: 0;
		margin: 0 0 0 1rem;
		position: relative;
		transition: all 0.4s ease;
		&__small {
			width: 31px;
			height: 16px;
			&:before {
				content: '';
				width: 12px !important;
				height: 12px !important;
			}
		}
		&:before {
			content: '';
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background-color: $white;
			box-shadow: inset 0 1px 4px 0 rgba(221, 214, 214, 0.5), 0 0 2px 0 rgba(36, 16, 16, 0.4);
			position: absolute;
			top: 2px;
			left: 3px;
			transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
		}
	}
	&__txt {
		color: $primary-text-color;
		font-size: 1.4rem;
		font-weight: $bold;
		cursor: pointer;
		&:hover {
			color: rgba($primary-text-color, 1);
		}
	}
}
/* custom radio checkbox */
.custom-radio {
	.item {
		&:not(:last-child) {
			margin-right: 2rem;
		}
	}
	.radio-button {
		cursor: pointer;
	}
	.icon {
		position: relative;
		@include avatar(20px, 20px, $white, 100%);
		border: 2px solid $primary-text-color;
		transition: all 300ms;
	}
	.label-txt {
		width: calc(100% - 30px);
		color: #2d3762;
		font-size: 1.3rem;
		font-weight: $medium;
		line-height: 1.6rem;
		margin-left: 1rem;
	}
	input:checked + label {
		.icon {
			border: 2px solid #12b13f;
			&:after {
				content: '';
				@include avatar(10px, 10px, #12b13f, 100%);
				position: absolute;
				top: 3px;
				left: 3px;
			}
		}
		.icon-primary {
			border: 2px solid $primary-brand-color--light;
			&:after {
				@include avatar(10px, 10px, $primary-brand-color--light, 100%);
			}
		}
	}
}
.radio-button-error {
	position: relative;
	right: 550px;
	top: 20px;
}
