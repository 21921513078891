.inbox-section {
	&.pipeline-modal {
		width: 1060px;

		// media query for 12.9 inch iPad
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			width: 918px;
		}

		@include respond-to(mobile-sm) {
			width: 100vw;
		}

		@include respond-to(mobile) {
			width: 100vw;
		}
	}

	@include respond-to(mobile) {
		padding: 0px;
	}

	.back {
		margin-bottom: 0.5rem !important;
		background: white;
		border: 1px solid #dadada;
		padding: 0px 5px;
	}

	.add-enquiry-container {
		.modal-heading {
			font-weight: 700;
		}

		.modal__body {
			padding-top: 0 !important;
			padding-bottom: 0;
			margin-bottom: 0;

			.enquiry-section {
				padding-top: 20px !important;
				padding-bottom: 5px !important;

				.section-label {
					color: #1c2e59;
					font-size: 16px;
					font-weight: 700;
				}

				.input-wrap {
					.input-field {
						border-color: transparent !important;
						background-color: #f2f7f9 !important;
					}

					.css-2b097c-container {
						.app_dropdown__control {
							background-color: #f3f7f9 !important;
							border: transparent !important;
						}
					}

					.plus-symbol {
						position: absolute;
						left: 22px;
						top: 10px;
						font-size: 20px;
					}
				}

				.icon-expand {
					position: absolute;
					right: 20px;
					top: 7px;
					font-size: 26px;
					color: #11a1ae;
				}

				.dropdown--custom-search {
					padding: 5px 0;
					top: 46px !important;

					li {
						padding: 8px 16px;

						&:hover {
							background-color: #d2f2f5;
						}
					}
				}

				.react-datepicker-wrapper {
					.react-datepicker__input-container {
						input {
							border: transparent !important;
							width: 274px !important;
						}
					}
				}

				.editor {
					border: transparent;
					box-shadow: none;

					.DraftEditor-editorContainer {
						background-color: #f2f7f9;
					}
				}
			}
		}

		.mb-0 {
			margin-bottom: 0 !important;
		}

		.modal__footer {
			.btn--save {
				width: max-content !important;
				padding: 10px 18px;
				font-weight: 700;
			}

			.btn--cancel {
				color: #00abba;
				border: 1px solid #11a1ae;
				font-weight: 600;
			}
		}
	}

	.inbox-top-header {
		width: 23.5%;

		@media (max-width: 1300px) {
			width: 22%;
		}

		@media (max-width: 1200px) {
			width: 21%;
		}

		@media (max-width: 992px) {
			width: 20%;
		}

		.header-enquiry-dropdown-wrapper {
			background-color: #ffffff;
			border: 1px solid #e0e0e1;
			padding: 5px 8px 5px 16px;
			width: 100%;
			margin-top: -8px;
			color: #8c8e9a;
		}

		.inbox-header-enquiry-dropdown {
			color: #8c8e9a;
			top: 28px;

			.dropdown-item {
				font-size: 14px;
				font-weight: 500;
				color: hsl(0, 0%, 50%);
				white-space: nowrap;
				width: 86%;
				text-overflow: ellipsis;
				overflow: hidden;

				&:hover,
				&.active {
					background-color: #f4f4f4;
					color: #1f2236;
				}
			}
		}
	}
}

.inbox-container {
	height: calc(100vh - 53px);

	@supports (-webkit-touch-callout: none) {
		/* CSS specific to iOS devices */
	}

	&.pop-up {
		height: calc(100vh - 120px);

		.chat-body {
			height: calc(100vh - 233px);
		}

		.notes-body {
			height: calc(100vh - 173px);
		}

		.product-container {
			height: calc(100vh - 173px);

			@include respond-to(mobile) {
				height: calc(100vh - 154px);
			}

			.product {
				height: calc(100vh - 173px);

				@include respond-to(mobile) {
					height: calc(100vh - 154px);
				}
			}
		}
	}

	.section-spliter {
		height: 100%;
		flex-grow: 1;
		border: 1px solid #dfdfdf;
		background-color: #fff;

		@include respond-to(mobile) {
			border: none;
		}

		&:first-child {
			max-width: 26%;
			min-width: 26%;
		}

		&:nth-child(2) {
			border-left: none;
			border-right: none;
			border-bottom: none;
		}

		&:nth-child(3) {
			max-width: 28%;
			min-width: 28%;
		}

		&.public {
			max-width: 30%;
			min-width: 30%;
		}

		&.pipeline {
			max-width: 36%;
			min-width: 36%;
		}

		&.enquires-section {
			.add-deal {
				position: absolute;
				bottom: 45px;
				right: 15px;

				&-relative {
					@include flexbox();
					padding: 0.9rem 1.1rem;

					.btn {
						padding: 0px 9px;
						border-radius: 50%;
						animation: scaleup 0.3s ease-in 1;
						font-weight: 500;
						font-size: 1.6rem;
						width: 50px;
						height: 50px;
						background: #11a1ae;
						box-shadow: 0px 4px 11px rgba(43, 42, 42, 0.49);
						border-color: #11a1ae;

						.icon {
							color: $white;
							font-size: 33px;
							position: absolute;
							top: 9px;
							left: 9px;
						}
					}
				}

				@include respond-to(mobile) {
					bottom: 35px;

					@supports (-webkit-touch-callout: none) {
						bottom: 60px;
					}
				}
			}
		}
	}

	.icon-wrap {
		@include flexbox();
		justify-content: center;
		align-items: center;
		border: none;
		background-color: transparent;
		border-radius: 50%;
		height: 23px;
		width: 23px;
		min-height: 23px;
		min-width: 23px;
		cursor: pointer;

		&.active {
			background: $green;
			border: 0.5px solid $filter-border;

			.material-icons {
				color: $white;
				font-weight: bold;
			}
		}

		&-bg {
			background-color: #f3f7f9;
		}

		&-big {
			height: 30px;
			width: 30px;
			min-height: 30px;
			min-width: 30px;

			.material-icons {
				font-size: 18px;
			}
		}

		&-high {
			height: 26px;
			width: 26px;
			min-height: 26px;
			min-width: 26px;

			.material-icons {
				font-size: 16px;
			}
		}

		&-medium {
			height: 23px;
			width: 23px;
			min-height: 23px;
			min-width: 23px;

			.material-icons {
				font-size: 18px;
			}
		}

		&-small-lg {
			height: 20px;
			width: 20px;
			min-height: 20px;
			min-width: 20px;

			.material-icons {
				font-size: 13px;
			}
		}

		&-small {
			height: 17px;
			width: 17px;
			min-height: 17px;
			min-width: 17px;

			.material-icons {
				font-size: 12px;
			}
		}

		&-white {
			background: $white;
		}

		.material-icons {
			color: #5e7281;
		}

		.rotate {
			@include rotate(90deg);
		}

		.rotate-full {
			@include rotate(360deg);
		}
	}

	.value {
		color: #393f53;
		font-size: 15px;
		font-weight: 500;
		line-height: 22px;
	}

	.value-green {
		color: #3dcb2c;
		font-weight: 600;
		line-height: 22px;
	}

	.value-gray {
		color: gray !important;
	}

	.value-light-green {
		cursor: pointer;
		color: #11a1ae;
		font-size: 13px;
		font-weight: 500;
		line-height: 19px;
	}

	.value-red {
		color: red;
		font-size: 15px;
		font-weight: 500;
		line-height: 22px;
	}

	.value-gray {
		color: $filter-text;
	}

	.stage-value {
		color: #393f53;
		font-size: 14px;
		font-weight: 600;
		line-height: 19px;
	}

	.tabs-container {
		.tab {
			background: transparent;
			color: #818ba2;
			display: inline-block;
			padding: 15px 0px;
			margin-right: 25px;
			font-weight: $medium;
			font-size: 1.6rem;
			border-radius: 10px 10px 0 0;
			cursor: pointer;
			border-bottom: 0;

			@include respond-to(mobile) {
				padding: 12px 0px;
				margin-right: 15px;
				font-size: 1.5rem;
				white-space: pre;
			}

			&::after {
				content: '';
				transform: scale(0);
				@include transition(all 0.3s ease);
				transform-origin: center, center;
			}

			&.active {
				font-weight: $semibold;
			}

			&.active,
			&:hover {
				position: relative;
				color: #1f2236;

				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					transform: scale(1);
					height: 3px;
					background: $primary-brand-color--light;
				}
			}
		}

		.lock-icon {
			font-size: 14px;
			color: #11a1ae;
		}

		.space-right {
			margin-right: 16px;
		}

		@media only screen and (min-width: 1200px) and (max-width: 1390px) {
			.tab {
				margin-right: 15px;

				&:last-child {
					margin-right: 5px !important;
				}
			}
		}
	}

	.filter-icon {
		cursor: pointer;
	}

	.enquiry-tabs {
		padding-right: 10px;
		padding-left: 18px;
		border-bottom: 1px solid #d8d9da;
		background: white;

		.tabs-container {
			border-bottom: none;
			background: white;
			@include flexbox();
			justify-content: center;
			align-items: center;

			.tab-wrap {
				@include flexbox();
				justify-content: center;
				margin-right: 15px;
			}

			.tab {
				padding: 10px 0px;
				font-size: 1.4rem;
				font-weight: $medium;
				margin-right: 0px;
				color: #818ba2;

				&.active,
				&:hover {
					color: #1f2236;

					&::after {
						background: $primary-brand-color--light;
					}
				}
			}

			.count-wrap {
				margin: 10px 0px;

				.count {
					padding: 0px 1px;
					width: 21px;
					margin-left: 8px;
					background: rgba(161, 161, 163, 0.13);
					border-radius: 2px;

					.count-value {
						font-size: 9px;
						font-weight: 500;
						text-align: center;
						color: #777a82;
					}

					&.active {
						background: rgba(17, 161, 174, 0.08);

						.count-value {
							color: #11a1ae;
						}
					}
				}
			}
		}
	}

	.multi-options {
		&.inbox-multi-options {
			height: auto;
			display: flex;
			flex-direction: column;

			.actions {
				cursor: pointer;
				font-weight: 500;
			}
		}

		height: 40px;
		background: $white;
		border-bottom: 1px solid rgba(217, 217, 217, 0.56);

		&.column {
			@include flexbox();
			justify-content: space-evenly;
			background-color: #f2f7f9;

			.block {
				width: 100%;
				@include flexbox();
				align-items: center;
				justify-content: center;

				&:nth-child(2) {
					border-left: 1px solid #d9d9d9;
					border-right: 1px solid #d9d9d9;
				}

				.title {
					color: #777a82;
					font-size: 13px;
				}

				.actions {
					.material-icons {
						color: #979797;
					}
				}

				.close {
					font-size: 14px;
					color: #979797;
					margin-right: 5px;
					font-weight: 600;
				}

				&.checkbox {
					.checkbox-icon-wrap-smaller {
						border-radius: 2px;
						border: 1px solid #11a1ae;
					}

					input:checked + .checkbox-label .checkbox-icon-wrap-smaller {
						background-color: #11a1ae;
						border: 1px solid transparent;
					}
				}
			}
		}

		&.selected {
			@include flexbox();
			justify-content: center;
			align-items: center;
			padding-left: 13px;
			padding-right: 10px;
		}

		.multi-select-container {
			padding: 5px;
			overflow-x: none;

			.selected-container {
				margin-bottom: 3px;
				margin-top: 2px;
			}
		}
	}

	.main-tabs {
		@include flexbox();
		height: 50px;

		.tab {
			flex: 50%;
			@include flexbox();
			align-items: center;
			background: $white;
			padding: 10px 16px;
			border-bottom: 1px solid #d8d9da;
			cursor: pointer;

			.title {
				color: #6d91ae;
				font-size: 14px;
				font-weight: 700;
			}

			.mail {
				font-size: 20px;
				color: #6d91ae;
				margin-right: 8px;
			}

			&.active {
				background: #f3f7f9;
				border-bottom: 3px solid #11a1ae;

				.title {
					color: #11a1ae;
				}
			}

			.avatar-pic {
				@include avatar(25px, 25px, $border-color, 100%);
				text-align: center;
				line-height: 26px;
				font-weight: 600;
				background: #77858d;
				color: white;
				font-size: 14px;

				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}
			}
		}

		@media only screen and (min-width: 1200px) and (max-width: 1324px) {
			.tab {
				padding: 10px;
			}
		}
	}

	.enquires-header {
		padding: 7px 0;
		border-bottom: 1px solid #dadada;
		background: $white;

		.add-deal {
			padding: 0.55rem 0.9rem;
			height: 100%;
		}

		.search-wrap {
			&::after {
				top: 8px;
			}

			.close {
				position: absolute;
				right: 8px;
				top: 8px;
				font-size: 18px;
			}
		}

		.sort {
			padding: 0.55rem 3rem 0.55rem 1.6rem;
		}

		.bot-dropdown {
			position: relative;
			-webkit-tap-highlight-color: transparent;

			&-container {
				height: 30px;
				padding-left: 21px;
			}

			.bot-avatar-wrap {
				max-width: 30px;
				max-height: 30px;

				img {
					width: 30px;
					height: 30px;
					border-radius: 50%;
					object-fit: cover;
					background-color: var(--primary-bg-color);
				}
			}

			.avatar-pic {
				@include avatar(28px, 28px, $border-color, 100%);
				text-align: center;
				line-height: 30px;
				font-weight: 600;
				background: #77858d;
				color: white;
				font-size: 14px;

				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}
			}

			.dropdown_options_container {
				max-height: 280px;
				overflow: auto;
			}

			.dropdown_options {
				width: 100%;
				position: absolute;
				z-index: 99;
				top: 7px;
				background: #fff;
				border: 1px solid #dadada;
				padding: 1rem 0rem;
				max-height: 300px;
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
				box-shadow: 0px 8px 10px -8px rgba(43, 42, 42, 0.49);

				.dropdown_option {
					padding: 0.9rem 1.5rem;
					width: 100%;
					color: $black;
					font-weight: 400;
					cursor: pointer;

					&:hover {
						background-color: rgba(96, 124, 255, 0.16);
					}

					&.selected {
						background-color: rgba(96, 124, 255, 0.16);
					}
				}

				.all-enquiries {
					@include flexbox();
					align-items: center;
					padding-left: 33px;
					margin-top: 5px;

					.icon {
						width: 25px;
						height: 20px;
					}

					.label {
						margin-left: 12px;
						color: #1c2e59;
						font-style: normal;
						font-weight: bold;
						font-size: 16px;
					}
				}

				.menu-section {
					padding: 0px 25px;
					padding-right: 25px;
					margin-top: 18px;

					.title {
						font-weight: bold;
						font-size: 14px;
						margin-bottom: 8px;
						padding-left: 6px;
					}

					.item {
						padding: 0.5rem;
						width: 100%;
						color: $black;
						font-weight: 400;
						cursor: pointer;

						&:hover {
							background-color: rgba(96, 124, 255, 0.16);
						}

						&.selected {
							background-color: rgba(96, 124, 255, 0.16);
						}
					}
				}
			}
		}
	}

	.inbox--primary {
		color: $white !important;
		background: $primary-brand-color--light !important;
		border-color: $primary-brand-color--light !important;

		&:hover {
			background-color: darken($primary-brand-color--light, 7%) !important;
			border-color: darken($primary-brand-color--light, 7%) !important;
		}

		.active {
			background-color: darken($primary-brand-color--light, 7%) !important;
			border-color: darken($primary-brand-color--light, 7%) !important;
		}
	}

	.inbox-filter {
		position: relative;
		background: $filter-background;
		color: $filter-text;
		padding: 5px;
		border-radius: 3px;
		margin-right: 5px;
		border: 0.5px solid $filter-border;
		cursor: pointer;

		@include respond-to(mobile) {
		}

		&.active {
			background: #96edf5;
			border-color: #11a1ae;
		}

		.total {
			border-radius: 50%;
			position: absolute;
			top: -3px;
			height: 10px;
			width: 10px;
			right: -4px;
			color: #fff;
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.4rem;
			background: #ec5959;
		}
	}

	.inbox-sort {
		position: relative;
		background: $filter-background;
		color: $filter-text;
		padding: 5px;
		border-radius: 3px;
		border: 0.5px solid $filter-border;
		cursor: pointer;

		@include respond-to(mobile) {
		}

		&.transparent {
			background: none;
			border: none;
		}

		&.active {
			background: #96edf5;
			border-color: #11a1ae;
		}

		.total {
			border-radius: 50%;
			position: absolute;
			top: -3px;
			height: 10px;
			width: 10px;
			right: -4px;
			color: #fff;
			font-size: 1.3rem;
			font-weight: 500;
			line-height: 1.4rem;
			background: #ec5959;
		}
	}

	.archieve {
		margin-bottom: 2px;
		padding: 3px 6px;

		@include respond-to(mobile) {
			padding: 6px;
			margin-bottom: 0px;
		}
	}

	.search {
		padding: 0.8rem 3rem;
		padding-left: 4rem;

		&::placeholder {
			font-size: 13px;
		}
	}

	.ipad-search-box {
		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			padding-left: 30px;
			padding-right: 100px !important;
		}

		padding-right: 14rem;
	}

	.pills {
		color: #393f53;
		font-size: 14px;
		line-height: 21px;
		text-align: center;
		border-radius: 3px;
		background-color: #c6c6c6;
		padding: 2px 12px;
		margin-right: 8px;
		cursor: pointer;

		&.active {
			background-color: $white;
			box-shadow: 0 2px 4px 0 rgba(160, 160, 160, 0.5);
		}
	}

	.enquires {
		&-card {
			padding: 10px 15px;
			padding-left: 17px;
			background-color: $white;
			width: 99.5%;
			border-bottom: 0.5px solid $filter-background;

			.icon-container {
				display: none;

				.icons {
					@include flexbox();

					.icon-wrap {
						border-radius: 5px;

						.share {
							height: 14px;
							width: 14px;
						}

						.archive {
							height: 16px;
							width: 16px;
						}
					}
				}
			}

			&:hover {
				.icon-container {
					display: block;
				}
			}

			&.active {
				transform: scale(1.01);
				position: relative;
				background-color: #4eb5c5;
				border-left: 5px solid #00abba;

				.enquiry {
					&-name {
						color: #fff;
						font-weight: 700;
					}

					&-day {
						color: #fff;
					}

					&-type {
						color: #fff;
					}
				}

				.icon-wrap {
					background: $white;
				}
			}

			&.closed {
				box-shadow: none;

				.enquiry-name {
				}

				.enquiry-day {
					font-weight: normal;
				}

				.enquiry-status.closed {
				}

				.enquiry-type {
				}
			}

			.online {
				color: $green;
				font-size: 10px;
			}

			.unread-count {
				height: 16px;
				width: 17px;
				background-color: #11a1ae;
				border-radius: 2px;
				display: flex;
				justify-content: center;
				align-items: center;

				.count-value {
					font-size: 12px;
					font-weight: 500;
					color: #ffffff;
				}
			}

			.new-tag {
				height: 16px;
				background-color: #4e9d52;
				padding: 9px 6px;
				border-radius: 6px;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 700;
				font-size: 12px;
				color: $white;
				margin-right: 6px;
			}

			.archieve-icon {
				font-size: 1.8rem;
				color: #979797;
			}

			.priority {
				font-size: 18px;
				cursor: pointer;
				color: #d7d7d7;

				&.active {
					color: #ffb62e;
				}
			}

			.avatar-pic {
				@include avatar(30px, 30px, $border-color, 100%);
				text-align: center;
				line-height: 29px;
				font-weight: 600;
				background: #77858d;
				color: white;
				font-size: 14px;

				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}
			}

			.multi-select-marker {
				@include avatar(30px, 30px, transparent, 50%);
				@include flexbox();
				justify-content: center;
				align-items: center;
				border: 1px solid #11a1ae;
				box-sizing: border-box;

				.checkbox-icon-wrap-smaller {
					border-radius: 2px;
					border: 1px solid #11a1ae;
				}

				input:checked + .checkbox-label .checkbox-icon-wrap-smaller {
					background-color: #11a1ae;
					border: 1px solid transparent;
				}
			}
		}

		&-body {
			height: calc(100% - 90px);
			overflow-y: auto;
			padding: 0px;
			-webkit-overflow-scrolling: touch;
			position: relative;
			background: $white;

			@include respond-to(mobile) {
				@supports (-webkit-touch-callout: none) {
					height: calc(100% - 29%);
				}

				height: calc(100vh - 260px);
			}

			&.selector-container {
				height: calc(100% - 130px);
			}

			.no-content {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;

				.title {
					font-weight: $semibold;
					font-size: 15px;
					color: #393f53;
				}

				.sub-title {
					font-weight: $medium;
					font-size: 13px;
					color: #888b99;
				}
			}

			.load-more {
				padding: 3px 10px;
				border: 1px solid #dadada;
				margin: 10px auto;
				background: white;
				border-radius: 4px;
				cursor: pointer;

				&:hover {
					background: #96edf5;
				}
			}
		}
	}

	.enquiry {
		&-name {
			color: $black;
			font-size: 16px;
			line-height: 22px;
			font-weight: 500;
			text-transform: capitalize;
			margin-top: 4px;
		}

		&-status {
			color: #ffffff;
			font-size: 13px;
			font-weight: 500;
			line-height: 19px;
			border-radius: 1px;
			background-color: #11a1ae;
			padding: 0 10px;
			@include text-elipsis();

			&.pending {
				color: #393f53;
				background-color: #fbbe4d;
			}
		}

		&-type {
			font-size: 13px;
			text-transform: capitalize;
			padding: 0px 3px;
			border-radius: 3px;
			color: rgba(#393f53, 0.6);
		}

		&-day {
			color: rgba(#393f53, 0.6);
			font-size: 12px;
			line-height: 18px;
			font-weight: $semibold;
			min-width: max-content;
		}

		&-actions {
			min-height: 23px;

			.reply-sent-icon {
				height: 14px;
				width: 15px;
				margin-right: 19px;
				margin-left: 8px;
			}

			.reply-icon {
				color: #b9b7b7;
				font-size: 19px;
				margin-right: 5px;
				margin-left: 4px;
			}
		}
	}

	.chat-header {
		color: #393f53;
		font-size: 16px;
		font-weight: 500;
		background: white;
		padding: 0px 15px;
		border-bottom: 1px solid #dedbdb;
		background-color: #ffffff;
		min-height: 52px;

		@include respond-to(mobile) {
			min-height: 40px;
		}

		.tabs-container {
			border-bottom: none;
			display: flex;
			overflow: auto;
		}

		.dropdown {
			max-height: 220px;
			top: 37px;
			padding: 10px 0;
			right: 5px;
		}

		.close-btn {
			@include flexbox();
			cursor: pointer;
			justify-content: center;
			align-items: center;
			background: #e3f6f7;
			padding: 5px 8px;
			color: #00abba;
			font-size: 14px;
			border-radius: 4px;
			font-weight: 600;

			.done-icon {
				font-size: 15px;
				margin-right: 7px;
			}

			&.reopen {
				background: #effff7;
				color: #009143;
				border: 1px solid #009143;
			}
		}

		.mobile {
			.dropdown {
				max-height: 220px;
				top: 37px;
				padding: 10px 0;
				right: -5px;
			}

			.material-icons {
				font-size: 20px;
			}
		}

		.call {
			-webkit-tap-highlight-color: transparent;
		}

		.archive {
			&-container {
				position: relative;
				height: 25px;
				font-size: 13px;

				&:hover {
					.archive-tooltip {
						display: block;
					}
				}
			}

			&-tooltip {
				display: none;
				position: absolute;
				right: 5px;
				width: max-content;
				top: 25px;
				background: #fafafa;
				padding: 0px 10px;
				border-radius: 4px;
				border: 0.5px solid #d7d3d3;
			}
		}
	}

	.deal {
		.input-sub {
			&-wrap {
				position: relative;
			}

			&-field {
				width: 190px;
				height: 32px;
				font-size: 1.4rem;
				background: transparent;
				color: $white;
				padding: 0.7rem 2.5rem 0.7rem 1rem;
				border: 1px solid $green;
				font-weight: bold;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				&-price {
					width: 110px;
				}

				&-dark {
					color: $black;
				}
			}

			&-text {
				width: 150px;
				height: 27px;
				font-size: 1.4rem;
				padding: 0.7rem;

				&-wrap {
					padding: 0.7rem 2rem;
					padding-right: 3rem;
				}
				&-sm {
					width: calc(100% - 30px);
				}
			}

			&-success {
				position: absolute;
				right: 23px;
				bottom: 3px;
				width: 30px;
			}

			&-btn {
				padding: 0.4rem 2.8rem;
			}
		}

		&-container {
			background-color: $white;
			height: calc(100% - 53px);
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			position: relative;

			@include respond-to(mobile) {
				height: calc(100% - 110px);

				@supports (-webkit-touch-callout: none) {
					height: calc(100% - 155px);
				}
			}
		}

		&-inline {
			position: relative;

			.edit-icon {
				display: none;
				position: absolute;
				right: 0;
				bottom: 0.5rem;

				.icon-wrap {
					border-radius: 5px;
				}

				&.close {
					display: block;
				}

				&.edit {
					display: block;
					right: 25px;
				}
			}

			.overlap {
				width: calc(100% - 20px);

				.deal-input-wrapper {
					width: 100%;

					.deal-input {
						position: relative;
						border-radius: 8px;
						border: 1px solid #e0e0e1;
						padding: 7.5px 10px 7.5px 10px;
						color: gray;
						background: #fafafa;
						width: 100%;
						padding-left: 4rem;
					}

					.deal-icon {
						position: absolute;
						font-size: 18px;
						color: #92a8b9;
						top: 9px;
						left: 9px;
						z-index: 99999;
					}
				}

				.plus-symbol {
					position: absolute;
					left: 22px;
					bottom: 4px;
					font-size: 1.8rem;
				}
			}

			.tooltips {
				max-width: calc(100% - 40px);

				.mail-icon {
					height: 0;
					margin-left: 3px;
				}
			}

			.enquiry-type-value {
				font-size: 12px;
				color: #5e7281;
				font-weight: 500;
				background-color: #f2f7f9;
				border-radius: 2px;
				padding: 3px 6px;
			}

			.background-none {
				background-color: transparent !important;
			}

			.header-transparency {
				opacity: 0.5;
			}

			.error-container-wrap {
				&-sm {
					width: calc(100% - 30px);
				}
				.input-sub-text {
					width: 120px !important;
					margin-right: 10px;
				}
				.input-sub-new {
					width: 100%;
					height: 27px;
					font-size: 1.4rem;
					padding: 0.7rem;
				}
			}

			.inbox-sub-enquiry {
				top: 25px !important;
			}

			.icon-expand {
				position: absolute;
				right: -8px;
				top: 0px;
				font-size: 26px;
				color: #11a1ae;

				&__sm {
					right: 46px;
				}
			}

			.detail-icons {
				color: #92a8b9;
			}

			.detail-icon-bot {
				margin-right: 10px;
				margin-left: 2px;
				height: 17px;
				width: 18px;
			}

			.dropdown--custom-search--inbox {
				top: 55px !important;
				left: 15px !important;
				min-width: 120px !important;
			}

			.dropdown--custom-enquiry--inbox {
				top: 30px !important;
				left: 0px !important;
				width: 100% !important;
				.bg-color-on-hover {
					&:hover {
						background-color: rgba(96, 124, 255, 0.16);
					}
				}
			}

			.dropdown—custom-search-sub-enquiry {
				top: 30px !important;
				left: 15px !important;
			}

			.dropdown—custom-dropdown-sub-enquiry {
				top: 30px !important;
				left: 15px !important;
				width: calc(100% - 60px) !important;
				.bg-color-on-hover {
					&:hover {
						background-color: rgba(96, 124, 255, 0.16);
					}
				}
			}
			.detail-client-icon {
				font-size: 22px;
			}

			&:hover {
				.edit-icon {
					display: block;
				}
			}
		}

		&-body {
			@include respond-to(mobile) {
			}

			.value-pill {
				color: $filter-text;
				font-size: 1.2rem;
				line-height: 15px;
				background-color: $comment-background;
				border: 0.5px solid $filter-text;
				border-radius: 4px;
				padding: 1px 8px;
				white-space: nowrap;
				text-transform: capitalize;
				text-overflow: ellipsis;
				overflow: hidden;
				font-weight: 600;
				max-width: max-content;

				&:not(:first-child) {
					margin-left: 10px;
				}
			}
		}

		&-section {
			background: white;
			.input-sub-field {
				width: 9vw;
				background-color: #fff;
				border: 1px solid #e0e0e1;
				color: #000;
				padding: 5px 8px 5px 16px;
				height: 34px;

				@include respond-to(mobile) {
					width: 20vw;
					padding-right: 20px;
					padding-left: 5px;
				}
			}

			.pipeline-name-input {
				padding-right: 25px;
				width: 12vw;
			}

			.close-icon {
				color: #fff !important;
			}
		}

		&-header {
			z-index: 20;
			height: 50px;
			background-color: #4eb5c5;
			border-bottom: 1px solid #dedbdb;
			@include flexbox();
			align-items: center;

			.name-heading {
				color: $white;
				font-size: 1.9rem;
				@include text-elipsis();
				background: transparent !important;
				margin-bottom: 0 !important;
				width: 286px;
				display: inline-block;
				max-width: fit-content;

				img {
					margin-right: 6px;
				}
			}

			.avatar-pic {
				@include avatar(28px, 28px, $border-color, 100%);
				text-align: center;
				line-height: 30px;
				font-weight: bold;
				background: $white;
				color: #77858d;

				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}
			}

			.material-icons {
				color: #5e7281;
			}

			.edit-icon {
				color: $white;
				font-size: 1.9rem;
			}

			.edit-tooltip {
				position: absolute;
				top: 20px;
			}
		}

		&-top {
			.section {
				border-bottom: 1px solid #d9d9d9;
			}

			.material-icons {
				color: #5e7281;
			}

			.select-wrap .custom-select--inbox {
				padding: 0.3rem 3rem 0.3rem 0.5rem;
			}

			.dropdown--custom-search--inbox {
				min-width: 120px;
				top: 52px !important;
				left: 15px !important;
			}

			.dropdown {
				max-height: 220px;
				top: 24px;
				left: -31px;
				padding: 10px;

				&::after {
					left: 40px;
					right: initial;
				}

				&::before {
					left: 40px;
					right: initial;
				}

				.links-wrap {
					max-height: 200px;
					overflow: auto;
				}
			}

			.value {
				color: rgba(#393f53, 0.8);
				font-size: 15px;
				line-height: 21px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				min-width: 100px;
				font-weight: 500;

				.mailto {
					color: rgba(#393f53, 0.8);
				}
			}

			.value-pill {
				color: $filter-text;
				font-size: 1.2rem;
				line-height: 15px;
				background-color: $filter-background;
				border: 0.5px solid $filter-background;
				padding: 1px 8px;
				white-space: nowrap;
				text-transform: capitalize;
				text-overflow: ellipsis;
				overflow: hidden;
				font-weight: bold;
			}

			.react-datepicker-wrapper {
				width: 100%;
			}

			.react-datepicker__input-container input {
				padding: 0.3rem;
				width: 100%;
			}

			.react-datepicker-popper[data-placement^='top'] {
				left: -36px !important;
				top: 15px !important;
			}

			.react-datepicker-popper[data-placement^='bottom'] {
				left: -36px !important;
			}
		}

		.custom-field {
			.react-datepicker-popper[data-placement^='bottom'] {
				left: unset !important;
			}
		}

		&-ruler {
			border-bottom: 0.5px solid #dadada;
			margin-bottom: 8px;
		}

		&-form {
			padding: 13px 12px 10px 12px;
		}

		&-name {
			color: #393f53;
			font-size: 18px;
			font-weight: 500;
			line-height: 27px;
		}
	}

	.deal-nav {
		&.deal-border-bottom {
			border-bottom: 1px solid #d7d7d7;
		}

		.nav-items {
			cursor: pointer;
			color: rgba(#393f53, 0.6);
			font-size: 13px;
			font-weight: bold;
			line-height: 19px;
			margin-right: 20px;
			text-transform: uppercase;

			&.active {
				color: rgba(#393f53, 0.9);
				position: relative;

				&::after {
					content: '';
					display: inline-block;
					position: absolute;
					background-color: #fbbe4d;
					width: 100%;
					height: 5px;
					left: 0;
					bottom: -15px;
				}
			}
		}

		&.active {
			.expand {
				transform: rotate(90deg) !important;
				color: #009db0;
			}
		}

		&-header {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			padding: 12px 16px;
			border-bottom: 1px solid #d9d9d9;

			.title {
				flex: 1;
				color: #1c2e59;
				font-size: 16px;
				font-weight: 700;
			}

			.content {
				color: #646464;
				font-weight: 600;
			}

			.expand {
				color: #878787;
				transition: all 0.2s ease;
				-webkit-user-select: none;
				/* Safari */
				-ms-user-select: none;
				/* IE 10 and IE 11 */
				user-select: none;
				/* Standard syntax */
			}
		}

		.bottom-hide {
			border-bottom: none !important;
		}

		&-body {
			.slider-sub-container {
				max-height: 0;
				width: 100%;
				display: none;

				.item {
					@include flexbox();
					justify-content: space-between;
					font-weight: 600;

					.edit-icon {
						display: none;
					}

					&:hover {
						.edit-icon {
							display: block;
						}
					}
				}

				.custom-field-title {
					font-weight: bold;
				}

				.custom-field-label {
					font-weight: normal;
				}
			}

			&.active {
				.slider-sub-container {
					max-height: max-content !important;
					padding: 5px 16px;
					display: inline-block;

					.dropdown-top {
						max-height: 128px;
						overflow: auto;
						top: -128px;
						background-color: #fafafa;
						padding: 5px 10px;
					}

					.add-source {
						align-items: center;
						border-radius: 8px;
						border: 1px solid #e0e0e1;
						padding: 7.5px 10px 7.5px 10px;
						color: gray;
						background: #fafafa;
						width: 100%;

						.source-icon {
							color: #92a8b9;
							font-size: 18px;
							margin-left: 0.3rem;
						}
					}

					.add-source-item {
						margin-bottom: 3px;
						border-radius: 8px;
						padding: 6px;
						color: #8c8e9a;
						border-radius: 4px;
						color: #1f2236;

						&:hover {
							background-color: #f4f4f4;
						}
					}
				}
			}

			.value {
				color: rgba(#393f53, 0.8);
				font-size: 14px;
				line-height: 21px;
				font-weight: 500;
				max-width: 280px !important;
				width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;

				.mailto {
					color: #5e7281;
				}
			}

			.dropdown {
				max-height: 220px;
				top: 24px;
				left: -31px;
				padding: 10px;

				&::after {
					left: 40px;
					right: initial;
				}

				&::before {
					left: 40px;
					right: initial;
				}

				.links-wrap {
					max-height: 200px;
					overflow: auto;
				}
			}

			.drop-down-top {
				&::after {
					left: 27px;
					right: initial;
				}

				&::before {
					left: 27px;
					right: initial;
				}

				.links-wrap {
					max-height: 200px;
					overflow: auto;
				}
			}

			.pointer-none {
				pointer-events: none !important;
			}
		}
	}

	.tags {
		.create {
			.tags-dropdown {
				position: relative;
				-webkit-tap-highlight-color: transparent;

				&-container {
					height: 30px;
				}

				.create-wrap {
					position: relative;

					input {
						width: 100%;
						padding: 0.7rem;
						padding-right: 8rem;
						padding-left: 2rem;
					}

					.create-btn {
						cursor: pointer;
						position: absolute;
						right: 6px;
						top: 6px;
						background: #f3f7f9;
						border: 1px solid #dadada;
						border-radius: 3px;
						padding: 0.1rem 0.8rem;
						font-size: 12px;
						text-transform: capitalize;
						color: darken($primary-brand-color--light, 7%);
						font-weight: 500;
					}
				}

				.bot-avatar-wrap {
					max-width: 25px;
					max-height: 25px;

					img {
						width: 25px;
						height: 25px;
						border-radius: 50%;
						object-fit: cover;
						background-color: var(--primary-bg-color);
					}
				}

				.dropdown_options {
					width: 100%;
					position: absolute;
					z-index: 99;
					top: 9px;
					background: #fff;
					border: 1px solid #dadada;
					padding: 1rem 0rem;
					max-height: 230px;
					overflow: scroll;

					.dropdown_option {
						padding: 0.9rem 1.5rem;
						width: 100%;
						color: $black;
						font-weight: 400;
						cursor: pointer;

						&:hover {
							background-color: rgba(96, 124, 255, 0.16);
						}

						&.selected {
							background-color: rgba(96, 124, 255, 0.16);
						}
					}
				}
			}
		}
	}

	.deal-list-container {
		padding: 0px 16px 10px 16px;
	}

	.deal-list-wrap {
		border-radius: 4px;
		border: 1px solid #dadada;
		background-color: #f2f7f9;

		.title {
			color: #1f2236;
			font-size: 14px;
			font-weight: 500;
			line-height: 19px;
		}

		.pipeline-title {
			color: #00abba !important;
			text-decoration: underline;
		}

		.visibility-icon {
			color: #11a1ae;
			font-size: 18px;
			margin-left: 5px;
			margin-top: 5px;
		}

		.value {
			color: #393f53;
			font-size: 14px;
			font-weight: 600;

			i {
				color: #92a8b9;
				font-size: 17px;
				margin-left: 6px;
			}
		}

		.estimated-close-date-drodown-wrap {
			width: 50%;

			@include respond-to(mobile) {
				.close-date-drodown-wrap {
					width: 100%;
				}
			}
		}

		.dropdown-wrapper {
			width: 10vw;

			@include respond-to(mobile) {
				width: 50%;
			}

			.global-dropdown-title-wrapper {
				width: 100%;
				justify-content: space-between;
			}
		}

		.deal-list {
			padding: 10px 15px;
			position: relative;
			@include flexbox();
			justify-content: space-between;
			align-items: center;

			.calendar-icon {
				color: #8c8e9a;
				margin-left: 6px;
			}

			&:not(:last-child) {
				border-bottom: 1px solid #e8e8e8;
			}

			.sub-select {
				padding: 0.3rem 3rem 0.3rem 1.6rem;
			}

			&--dropdown {
				position: relative;
			}

			&-menu {
				cursor: pointer;
				position: absolute;
				font-size: 14px;
				right: 0px;
				top: 13px;

				.material-icons {
					&.more-icon {
						color: #78858d;
					}

					&:hover {
						color: $black;
					}
				}

				.dropdown {
					transform: translateY(30%);

					&.show {
						transform: translateY(0);
					}

					&::before {
						content: none;
					}

					&::after {
						content: none;
					}

					&--deal {
						top: 6px;

						&-title {
							cursor: initial;
							display: block;
							width: 100%;
							padding: 0.4rem 1.5rem;
							font-size: 14px;
							color: rgba(#393f53, 0.5);
						}
					}
				}
			}

			.pipeline-title-name {
				white-space: nowrap;
				width: 7.5vw;
				overflow: hidden;
				text-overflow: ellipsis;

				@include respond-to(mobile) {
					width: 70%;
				}
			}

			.input {
				&.deal {
					padding: 3px;
				}
			}

			.due-date-picker {
				z-index: 99999;
				top: 35px;
				right: 2px;
				left: unset;
			}

			.react-datepicker__input-container input {
				padding: 0.5rem;
			}

			.react-datepicker-popper[data-placement^='top'] {
				left: 10px !important;
				top: 14px !important;
			}

			.view-tag {
				position: absolute;
				top: 4px;
				right: 5px;
			}

			.bot-avatar-wrap {
				max-width: 25px;
				max-height: 25px;

				img {
					width: 25px;
					height: 25px;
					border-radius: 50%;
					object-fit: cover;
					background-color: var(--primary-bg-color);
				}
			}

			.avatar-pic {
				@include avatar(25px, 25px, $border-color, 100%);
				text-align: center;
				line-height: 30px;
				font-weight: bold;
				background: $filter-background;
				color: $dark;

				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}

				.user-info {
					margin-top: auto;
					@include avatar(24px, 24px, #78858d, 50%);
					min-width: 24px;
					min-height: 24px;
					text-align: center;
					font-size: 1rem;
					color: #ffffff;
					line-height: 24px;
					font-weight: 600;

					> img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
						object-fit: cover;
					}
				}
			}

			.deal-value {
				cursor: pointer;
			}

			.assigned-user {
				color: #1c2e59;
				margin-left: 8px;
			}

			.pipeline-name {
				white-space: nowrap;
				width: 130px;
				text-align: right;
				overflow: hidden;
				text-overflow: ellipsis;
				margin-right: 4px;
			}
		}

		.tooltip-wrap {
			.deal-list {
				border-bottom: 1px solid #e8e8e8;
			}

			.border-none {
				border-bottom: none !important;
			}

			.save-icon {
				top: 8px;

				@include respond-to(mobile) {
					right: 10px;
				}
			}
		}

		.inbox-detail-tooltip {
			max-width: 135px;
			width: max-content;
			left: 0;

			@include respond-to(mobile) {
				opacity: 0 !important;
				visibility: hidden !important;
			}
		}

		.pointer-none {
			pointer-events: none !important;
		}
	}

	.disable-tooptip {
		display: none !important;
	}

	.notes-list-container {
		height: calc(100% - 69px);

		&.view {
			height: calc(100% - 50px);
		}

		.title {
			padding: 10px 15px;
			font-size: 1.6rem;
			font-weight: $semibold;
		}

		.notes-header {
			padding: 10px 25px;
			border-bottom: 1px solid rgba(#979797, 0.2);

			.sub-title {
				font-size: 1.3rem;
				font-weight: $semibold;
			}

			.add-note {
				font-size: 1.4rem;
				font-weight: $semibold;
				color: #11a1ae;

				.material-icons {
					font-size: 1.6rem;
				}
			}
		}

		.notes-body {
			height: calc(100% - 22px);
			overflow-y: auto;

			&.public {
				height: 100%;
			}

			.notes {
				padding: 1.2rem 2.5rem;
				border-bottom: 1px solid rgba(#979797, 0.2);

				&__container {
					flex: 0.95;
				}

				&__content {
					font-size: 1.4rem;
					color: #22243d;
					line-height: 2.1rem;
				}

				&__time {
					font-size: 1.2rem;
					font-weight: 500;
					color: #393f53;
				}

				&-icon {
					font-size: 20px;
					line-height: 32px;
					cursor: pointer;
					color: #78858d;

					&:hover {
						color: #000;
					}
				}
			}
		}
	}

	.product-container {
		height: calc(100vh - 106px);
		background: #f3f7f9;

		.product {
			height: calc(100vh - 106px);

			@include respond-to(mobile) {
				height: calc(100vh - 210px);
			}

			padding: 1.2rem 2.5rem;
			overflow-y: auto;
			border-bottom: 1px solid rgba(#979797, 0.2);

			.product-img {
				height: 150px;
				width: auto;
			}

			.main-section {
				margin-top: 80px;
				@include flexbox();
				justify-content: center;
				align-items: center;
				flex-direction: column;
			}

			.guide {
				font-weight: bold;
				margin-top: 20px;

				.intro {
					color: darken($primary-brand-color--light, 7%);
					margin-right: 20px;
				}

				.learn {
					color: #22243d;

					.material-icons {
						color: #b1b1b1;
						font-size: 18px;
					}
				}
			}

			.product-add {
				&.extra-info {
					padding: 2rem 2.5rem;
				}

				&.extra-info-rel {
					height: 100%;
					position: relative;
				}

				.extra-info-body {
					height: calc(100% - 85px);
					overflow-y: auto;
					overflow-x: hidden;
					padding: 18px;
					padding-bottom: 180px;

					@include respond-to(mobile) {
						height: calc(100% - 50px);
					}
				}

				.extra-info-footer {
					position: absolute;
					bottom: -4px;
					width: 100%;
				}

				.input-label {
					font-weight: 600;
				}

				.sub-label {
					font-weight: 100;
					font-size: 1.2rem;
					margin-left: 5px;
					color: #9b9999;
				}
			}

			.product-list {
				.title {
					font-weight: $semibold;
					font-size: 1.5rem;
					color: $black;
				}

				.product-card {
					background: $white;
					border: 1px solid #dadada;
					border-radius: 3px;
					margin-top: 10px;

					.head-sec {
						padding: 12px 15px;
					}

					.detail-body {
						border-top: 1px solid #dadada;
						border-bottom: 1px solid #dadada;

						.sec {
							padding: 12px 15px;
							flex: 1;
							@include flexbox();
							justify-content: center;
							align-items: center;
							border-right: 1px solid #dadada;

							&:last-child {
								border-right: none;
							}

							.label {
								color: #8c8f97;
								font-weight: $medium;
							}

							.value-label {
								color: $black;
								font-weight: $semibold;
							}
						}
					}

					.detail-notes {
						padding: 12px 15px;

						.top {
							@include flexbox();
							justify-content: space-between;
							align-items: center;
							margin-bottom: 10px;
						}

						.notes-users {
							margin-left: 0px;
						}
					}

					.card-title {
						font-weight: $semibold;
						font-size: 1.6rem;
						color: $black;
					}

					.avatar-pic {
						@include avatar(28px, 28px, $border-color, 100%);
						text-align: center;
						line-height: 30px;
						font-weight: bold;
						background: $filter-background;
						color: $dark;

						> img {
							@include avatar(100%, 100%, $border-color, 100%);
						}
					}
				}

				.item {
					@include flexbox();
					justify-content: space-between;
					font-weight: 600;

					.edit-icon {
						display: none;
					}

					&:hover {
						.edit-icon {
							display: block;
						}
					}
				}

				.custom-field-title {
					font-weight: bold;
					font-size: 1.6rem;
				}

				.custom-field-label {
					font-weight: normal;
					font-size: 1.4rem;
				}
			}
		}
	}

	.custom-field {
	}

	.advanced-search {
		background: #f3f7f9;

		&-container {
			height: calc(100vh - 106px);
			padding: 1.2rem 2.5rem;
			overflow-y: auto;
			border-bottom: 1px solid rgba(#979797, 0.2);
		}

		&-input {
			.input-title {
				color: #777a82;
				font-weight: 600;
				font-size: 14px;
			}

			.app_dropdown-no-bg__control {
				border-radius: 8px;
			}
		}

		.react-datepicker-wrapper {
			input {
			}
		}

		.date-picker-close {
			position: absolute;
			right: 36px;
			bottom: 12px;
			font-size: 16px;
			color: #777a82;
		}
	}

	.advanced-search-box {
		@include flexbox();
		align-items: center;
		margin-left: 10px;
		margin-right: 10px;
		flex: 1;
		font-weight: $semibold;

		.search-container {
			@include flexbox();
			justify-content: space-between;
			align-items: center;
			background: #f0fdfe;
			border: 1.5px solid #4298a2;
			padding: 4px 6px;
			color: #4298a2;
			font-weight: $semibold;
			width: max-content;
			border-radius: 3px;
		}
	}

	.task-list-container {
		.task-body {
			height: calc(100% - 44px);
			overflow-y: auto;

			.task {
				padding: 1.2rem 2.5rem;
				border-bottom: 1px solid rgba(#979797, 0.2);

				&.complete {
					background: #e8e8e8;

					&:hover {
						background: #e8e8e8;

						.task-icon {
							color: #78858d;
						}
					}
				}

				&:hover {
					background: #f9fbff;
				}

				&__container {
					flex: 0.95;
				}

				&__content {
					font-size: 1.4rem;
					color: #22243d;
					line-height: 2.1rem;

					&.complete {
						text-decoration: line-through;
						color: rgba(#393f53, 0.6);
					}
				}

				&__time {
					font-size: 1.2rem;
					font-weight: 600;
					color: #393f53;
				}

				&-icon {
					font-size: 20px;
					line-height: 32px;
					cursor: pointer;
					color: #78858d;

					&:hover {
						color: #000;
					}
				}
			}
		}

		.task-container {
			padding: 0.5rem 1.5rem;

			.react-datepicker__input-container input {
				width: 100%;
			}

			.react-datepicker-popper[data-placement^='top'] {
				left: 0px !important;
				top: 14px !important;
			}

			.type-list {
				background: #fff;
				border: 1px solid #dadada;
				border-radius: 2px;
				padding: 0.7rem 0.7rem;

				.material-icons {
					&.type {
						cursor: pointer;
						opacity: 0.7;

						&.active {
							color: #11a1ae;
						}
					}
				}
			}
		}
	}

	.activity {
		&-body {
			background: $filter-background;
			height: calc(100% - 52px);
			overflow-y: auto;

			@include respond-to(mobile) {
				height: calc(100% - 46px);
			}
		}

		&-container {
			padding: 2rem 3rem;
			position: relative;
			padding-right: 4rem;

			@include respond-to(mobile) {
				padding: 2rem 1rem 6.5rem;
			}
		}

		&-item {
			margin-top: 10px;
			position: relative;

			.content {
				margin-left: 30px;
				margin-bottom: 15px;
				padding: 10px 15px;
				background: $white;
				box-shadow: 0px 0px 6px 0px rgba(57, 114, 120, 0.18);
			}

			&:not(:last-child) {
				.activity-bullet {
					&::after {
						content: '';
						background: #bbc4c4;
						z-index: 0;
						width: 1px;
						height: 100%;
						position: absolute;
						left: 10px;
						top: 30px;
					}
				}
			}
		}

		&-bullet {
			float: left;
			height: 20px;
			width: 20px;
			margin-top: 10px;
			border: 1px solid $primary-brand-color--light;
			background-color: $comment-background;
			border-radius: 50%;
			@include flexbox();
			justify-content: center;
			align-items: center;

			.material-icons {
				color: $primary-brand-color--light;
				font-size: 13px;
				font-weight: 800;
			}
		}
	}

	.chat-footer-menu {
		display: none;
		background: #ffffff;
		border-top: 1px solid #bbc4c4;
		height: 56px;
		padding: 0.8rem 0rem;
		justify-content: space-around;
		align-items: center;
		position: fixed;
		width: 100%;
		bottom: 0px;

		@include respond-to(mobile) {
			display: flex;
		}

		.menu-option {
			display: block;
			text-align: center;
			line-height: 1.4rem;

			.material-icons {
				font-size: 2rem;
				color: #99a5af;
			}

			.label {
				font-size: 1.3rem;
				color: #99a5af;
				font-weight: $medium;
			}

			&.active {
				.material-icons {
					color: black;
				}

				.label {
					color: black;
				}
			}
		}
	}

	.chat-footer {
		background: white;
		padding: 10px 15px;
		width: 100%;

		&.compose {
			background: transparent;
			position: fixed;
			bottom: 56px;

			.compose-btn {
				padding: 0.8rem 2.2rem;
				position: absolute;
				border-radius: 20px;
				bottom: 10px;
				right: 15px;
				box-shadow: 2px 2px 5px 0 rgba(91, 88, 88, 0.5);
				background-color: #ffffff;
				animation: scaleup 0.3s ease-in 1;

				.material-icons {
					color: #ee2020;
					font-size: 24px;
					margin-right: 3px;
					margin-bottom: 1px;
				}

				.compose-value {
					font-weight: 500;
					color: #ee2020;
					font-size: 1.6rem;
				}
			}
		}
	}

	.chat-reply-placeholder {
		background-color: #eeeeee;
		color: rgba(#393f53, 0.9);
		border: 1px solid #e3e3e3;
		border-radius: 3px;

		.chat-reply-input {
			background-color: #eeeeee;
			font-size: 13px;
			line-height: 19px;
			border: none;

			&::placeholder {
				color: rgba(#393f53, 0.5);
			}
		}
	}

	.char-global-container {
		height: 92%;

		.chat-loader {
			z-index: 1;
			left: 0;
			top: 0;
		}
	}

	.chat-body {
		height: calc(100vh - 190px);

		@include respond-to(mobile-ls) {
			height: calc(100vh - 264px);
		}

		@include respond-to(tablet-ls) {
			height: calc(100vh - 230px);
		}

		padding: 1.5rem;
		overflow-y: auto;
		background: #fff;

		&.public {
			height: calc(100vh - 106px);

			@include respond-to(mobile) {
				height: calc(100vh - 155px);
			}
		}

		.reply {
			width: fit-content;
			margin-bottom: 12px;

			@include respond-to(mobile) {
				max-width: 288px;
				overflow-wrap: break-word;
			}
		}

		.reply-sent {
			width: 100% !important;
			max-width: initial !important;
		}

		.webform {
			width: 100%;
			max-width: initial;

			@include respond-to(mobile) {
				max-width: 288px;
				overflow-wrap: break-word;
			}

			&__header {
				padding-bottom: 15px;
				border-bottom: 1px solid #dadada;
				word-wrap: break-word;
			}

			&__value {
				background-color: #fff;
				border: 1px solid #dadada;
				border-radius: 6px;
				padding: 10px;
				word-wrap: break-word;
			}

			&__url {
				word-wrap: break-word;
				word-break: break-word;
				hyphens: auto;
			}
		}

		.chat-time {
			color: rgba(57, 63, 83, 0.6);
			position: absolute;
			width: calc(100% - 50px);

			.by-container {
				@include flexbox();
				align-items: baseline;

				&.recieve {
					justify-content: flex-end;
				}
			}

			.time-style {
				font-weight: $medium;
				font-size: 10px;
				width: fit-content;
				color: #8c8e9a;

				&.name {
					font-size: 14px;
					font-weight: $bold;
					color: #1f2236;
				}
			}

			.left {
				margin-left: 0px;
			}

			.bolt-icon {
				color: gold;
				font-size: 14px;
			}

			.right {
				margin-right: 0px;
			}
		}
	}

	.chat-section {
		position: relative;
		background: white;
	}

	.chat-reply-wrap {
		position: absolute;
		width: 100%;
		border: 1px solid #dce0e0;
		bottom: 0;
		left: 0;
		background: $white;
		transition: all 300ms;
		box-shadow: 0px 0px 22px rgba(4, 108, 117, 0.08);

		@-moz-document url-prefix() {
			margin-bottom: 12px !important;
		}

		.chat-reply-header {
			border-top-right-radius: 8px;
			border-top-left-radius: 8px;
			padding-top: 0px;
		}

		@include respond-to(mobile) {
			height: calc(100% - 0px);
			position: fixed;
			z-index: 99;
			padding: 0px;
		}

		@include respond-to(mobile-ls) {
			top: 55px;
		}

		.channel-switch {
			min-width: 235px;

			.fb-icon {
				background-color: #0084ff;
				padding: 1px 5px 1px 5px !important;
			}

			.instagram-icon {
				width: 24px;
			}

			.channels-dropdown {
				top: 30px;
			}

			.from-dropdown {
				top: 21px;
				left: -20px;
			}

			.expand-icon {
				position: absolute;
				right: 57px;
				bottom: 0px;
				top: -3px;
				cursor: pointer;
				font-size: 24px;
			}
		}
	}

	.chat-replay-message {
		.reply-textarea {
			border: none;
			height: auto;
		}

		.chat-message-footer {
			border-top: 1px solid #dedbdb;

			&__border-right {
				border-right: 1px solid #dfdfdf;
			}

			.send-btn-img {
				height: 14px;
			}
		}

		.attachments {
			overflow-y: auto;
			max-height: 277px;
		}
	}

	.chat-reply-container {
		border-radius: 3px;
		height: 100%;

		@include respond-to(mobile) {
			border: none;
		}

		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.3);
			z-index: 2;

			&-label {
				color: white;
				font-weight: $semibold;
			}
		}

		.editor-container {
			@include respond-to(mobile) {
			}

			.editor-body {
				@include respond-to(mobile) {
					font-size: 1.5rem;
				}

				min-height: 100px;

				.attachment {
					@include respond-to(mobile) {
						font-size: 1.5rem;
					}
				}
			}
		}

		.DraftEditor-editorContainer .public-DraftEditor-content {
			@include respond-to(mobile) {
				font-size: 15px;
			}
		}

		.material-icons {
			font-size: 20px;
		}

		.button-render {
			background: #f2f7f9;
			border-radius: 7px;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 6px;
			cursor: pointer;

			.text {
				color: #393f53;
				font-size: 14px;
				line-height: 17px;
			}

			.close {
				color: #c4c4c4;
			}
		}
	}

	.chat-reply-header {
		background-color: #fcfdff;
		padding: 0px 15px 0px 15px;

		.material-icons {
			font-size: 22px;
		}

		.header-btn {
			color: #8c8e9a;
		}
	}

	.head-title {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #1c2e59;
	}

	.chat-reply-top {
		border-radius: 4px;

		.select-wrap {
			flex: 1 1 auto;

			.custom-select {
				border: none;
				padding: 0;
			}
		}

		.label-value {
			border-bottom: 1px solid #dadada;
			padding: 10px 20px 10px 15px;
		}

		.label {
			color: #a1a1a3;
			line-height: 17px;
			font-weight: 500;
			font-size: 14px;
			min-width: 55px;

			@include respond-to(mobile) {
				font-size: 1.45rem;
			}
		}

		.value {
			color: #393f53;
			font-size: 1.4rem;
			line-height: 19px;
			min-width: calc(100% - 10%);
			word-break: break-all;
			padding-right: 10px;

			&.placeholder {
				color: rgba(57, 63, 83, 0.6);
			}

			@include respond-to(mobile) {
				font-size: 1.45rem;
			}
		}

		.email-pill {
			color: #393f53;
			font-size: 13px;
			line-height: 19px;
			border: 1px solid #e3e3e3;
			border-radius: 3px;
			background-color: #f4f4f4;
			padding: 0 5px;
			margin-right: 5px;
			margin-bottom: 5px;

			@include respond-to(mobile) {
				font-size: 1.45rem;
			}

			.material-icons {
				font-size: 14px;
				color: #5e7281;
				margin-left: 5px;
			}
		}

		.flex-wrap {
			flex-wrap: wrap;
		}

		.add-more {
			color: rgba(#393f53, 0.5);
			font-size: 13px;
			line-height: 19px;
			padding: 0;
			border: 0;
			background: transparent;
		}

		.email-text {
			color: rgba(#393f53, 0.9);
			font-size: 1.4rem;
			line-height: 19px;
			padding: 0;
			border: 0;
			min-width: 120px;
			background: transparent;
			width: 100%;

			@include respond-to(mobile) {
				font-size: 1.45rem;
			}
		}
	}

	.notes {
		&-body {
			height: calc(100vh - 106px);
			padding: 1.5rem;
			overflow-y: auto;
			background: #f3f7f9;
			overscroll-behavior: contain;

			@include respond-to(mobile) {
				padding: 1.5rem 0 6.5rem;
				height: calc(100vh - 156px);
			}

			.btn-wrap {
				@include respond-to(mobile) {
				}
			}
		}

		.avatar-sm {
			.avatar-pic {
				@include avatar(21px, 21px, $border-color, 100%);
				text-align: center;
				line-height: 21px;
				font-weight: 600;
				background: $filter-background;
				color: $dark;

				.user-info {
					font-size: 13px;
				}

				> img {
					@include avatar(100%, 100%, $border-color, 100%);
				}
			}
		}

		&-users {
			@include flexbox();
			align-items: center;
			margin-left: 10px;

			@include respond-to(mobile) {
				margin-left: 0px;
			}

			.user-name {
				font-size: 14px;
				font-weight: bold;
				color: $dark;
				margin-left: 8px;
			}
		}

		&-items {
			padding: 0 20px;

			@include respond-to(mobile) {
				padding: 0;
			}

			.note-title {
				position: absolute;
				top: 20px;
			}

			.value {
				@include respond-to(mobile) {
					padding: 0 10px;
				}
			}

			&-container {
				margin-top: 15px;
				background: $white;
				@include flexbox();

				.left {
					padding: 10px;
				}

				.right {
					padding: 10px;
					width: 100%;
					padding-right: 40px;

					.note-header {
						@include flexbox();
						justify-content: space-between;
						margin-top: 3px;

						.title {
							@include text-elipsis();
						}

						&-left {
							@include flexbox();
							align-items: flex-start;

							.value {
								padding-left: 20px;
							}
						}

						&-right {
							@include flexbox();
							align-items: flex-start;

							.value {
								padding-left: 20px;
							}
						}
					}

					.note-wrap {
						padding-left: 36px;

						@include respond-to(mobile) {
							margin-top: 10px;
						}

						.editor {
							margin: 0;
							box-shadow: none;
						}
					}

					.note {
						font-size: 14px;
						cursor: pointer;
						padding: 5px 10px;
						border: 1px solid transparent;

						@include respond-to(mobile) {
							padding: 5px;
						}

						.editable {
							display: none;
							position: absolute;
							right: 5px;
							top: 4px;
						}

						&:hover {
							border: 1px solid #558a95;
							background: #e5f5f8;
							border-radius: 5px;

							.editable {
								display: block;
							}
						}
					}

					.note-max-width {
						word-wrap: break-word !important;
						max-width: 500px !important;
					}
				}
			}

			.empty-note {
				margin-top: 24rem;

				@include respond-to(mobile) {
					margin-top: 20rem;
				}
			}
		}

		.comments {
			background-color: $white;

			&-section {
				border-top: 1px solid $filter-border;
				@include flexbox();

				.left {
					padding: 10px;
				}

				.right {
					padding: 10px;
					width: 100%;
					padding-right: 40px;
				}

				.material-icons {
					color: $filter-text;
				}
			}

			&-form {
				margin-top: 10px;
				padding-left: 46px;

				@include respond-to(mobile) {
					padding-left: 0;
				}

				.editor {
					margin: 0;
					box-shadow: none;
					border: none;

					.DraftEditor-editorContainer {
						background-color: $comment-background;
					}
				}
			}

			&-view {
				padding: 10px;
				padding-left: 57px;
				padding-right: 40px;
				border-top: 1px solid $filter-border;

				.note {
					display: flex;
					cursor: pointer;

					.material-icons {
						font-size: 18px;
						height: 18px;
						color: #11a1ae;
					}
				}

				.note:hover {
					color: #11a1ae;
				}

				.comment-item {
					padding-top: 10px;
				}

				.desc {
					margin-top: 10px;
					border: 1px solid $filter-border;
					background-color: $comment-background;
					padding: 15px 10px;

					.editable {
						display: none;
						justify-content: center;
						align-items: center;
						position: absolute;
						top: 5px;
						right: 10px;

						.icon-wrap {
							border-radius: 5px;
						}
					}

					&:hover {
						.editable {
							@include flexbox();
						}
					}

					.editor {
						margin: 0;
						box-shadow: none;
						background-color: $comment-background;

						.DraftEditor-editorContainer {
							background-color: $comment-background;
						}
					}
				}
			}
		}

		.avatar-pic {
			@include avatar(28px, 28px, $border-color, 100%);
			text-align: center;
			line-height: 30px;
			font-weight: bold;
			background: $filter-background;
			color: $dark;

			> img {
				@include avatar(100%, 100%, $border-color, 100%);
			}
		}
	}

	.title-completed {
		color: #8d9199;
		position: relative;
		text-decoration: line-through;
	}

	.inbox-background {
		background-color: #f2f7f9;
	}

	.actions {
		.status-view {
			@include flexbox();
			justify-content: space-between;
			align-items: center;
			padding-left: 46px;

			.status--pill {
				color: $dark;
				font-weight: bold;
				font-size: 1rem;
				background-color: #e9f3ed;
				border: 2px solid $green;
				padding: 0px 14px;
				white-space: nowrap;
				overflow: hidden;

				&--due {
					border: 2px solid #e56369;
					background-color: #fceaeb;
				}
			}

			.date-time {
				margin-left: 24px;
				color: #979797;
				font-size: 14px;
				font-weight: 500;
			}

			.mark-complete {
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 17px;
				cursor: pointer;

				.check-box {
					height: 15px;
					width: 15px;
					border-radius: 50%;
					position: relative;

					.check {
						display: block;
						background: #11a843;
						height: -webkit-fill-available;
						height: inherit;
						border: 1px solid #11a843;
						border-radius: 50%;

						.done {
							position: absolute;
							font-size: 11px;
							font-weight: bold;
							color: $white;
							top: 2px;
							left: 2px;
						}
					}

					.uncheck {
						border: 1px solid #939393;
						height: -webkit-fill-available;
						height: inherit;
						border-radius: 50%;

						.done {
							display: none;
						}
					}
				}
			}
		}

		&-details {
			background-color: $white;
			padding: 15px 15px;
			border-radius: 8px;
		}

		&-notes {
			border-top: 1px solid $filter-border;
			border-bottom: 1px solid $filter-border;
			padding: 10px 10px;

			.editor {
				margin: 0;
				box-shadow: none;
				border: none;
			}

			.action-name {
				padding: 0.7rem;
			}

			.note {
				padding: 1rem;
			}
		}

		.expand {
			color: $black;
			@include transition(all 0.2s ease);

			&.open {
				@include rotate(90deg);
			}
		}
	}

	.date-label {
		color: #7d818c;
		font-size: 12px;
	}

	.chat-reply-textarea {
		@include respond-to(mobile) {
		}

		textarea {
			width: 100%;
		}

		.chat-footer-loader {
			position: absolute;
			top: 22%;
			left: 45%;
			z-index: 9999999;
		}

		.send-btn {
			right: 10px;
			z-index: 1;

			.send-btn-img {
				height: 14px;
			}

			> .attachment-image {
				&:hover {
					background-color: #e6edf3;
				}

				> img {
					height: 23px;
				}
			}
		}
	}

	.chat-reply-footer {
		// padding: 0px 10px 10px 10px;
		// text-align: right;
		@include flexbox();
		justify-content: space-between;
		align-items: center;

		.btn--sm {
			padding: 0.8rem 1.6rem;
		}

		.save-template {
			@include flexbox();
			cursor: pointer;
			justify-content: center;
			align-items: center;
			height: 30px;

			.material-icons {
				font-size: 17px;
				color: #393f53;
				margin-right: 3px;
			}

			.label-template {
				font-weight: 500;
				font-size: 13px;
				line-height: 15px;
				color: #393f53;
			}
		}

		@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
			padding-bottom: 0;
		}
	}

	.close-msg-text {
		color: rgba(57, 63, 83, 0.6);
		font-weight: 600;
		text-align: center;
	}

	.pointer-none {
		pointer-events: none !important;
	}

	.public-name-heading {
		width: 362px !important;
	}

	.close_pipeline_icon {
		position: absolute;
		right: -10px;
		top: -15px;
		color: #ffffff;
		background-color: #4eb5c5;
		border-radius: 50%;
		font-size: 16px;
		border: 2px solid;
		font-weight: 600;
		padding: 4px;
	}
}

.time-tooltip {
	position: absolute;
	right: 0;
	left: 0;
	bottom: 15px;
	width: max-content;
	background: white;
	border: 0.5px solid #d7d3d3;
	padding: 5px 10px;
	border-radius: 3px;
	color: black;
	z-index: 1000000;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;

	&-wrap {
		position: relative;
		cursor: pointer;

		.material-icons {
			color: rgba($primary-text-color, 0.6);
			font-size: 18px;
		}

		&:hover {
			.time-tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&.tooltip-left {
		left: 8px !important;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 30px;
			left: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 10px solid #d7d3d3;
		}

		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 28px;
			left: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 11px solid white;
		}
	}

	&.tooltip-right {
		left: 415px !important;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 30px;
			right: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 10px solid #d7d3d3;
		}

		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 28px;
			right: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-top: 11px solid white;
		}
	}

	&.tooltip-bottom-left {
		top: 25px;
		bottom: unset;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: -11px;
			left: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 10px solid #d7d3d3;
		}

		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: -10px;
			left: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 11px solid white;
		}
	}

	&.tooltip-bottom-right {
		top: 25px;
		bottom: unset;
		left: unset;

		&::before {
			content: '';
			display: inline-block;
			position: absolute;
			top: -11px;
			right: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 10px solid #d7d3d3;
		}

		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: -10px;
			right: 8px;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 11px solid white;
		}
	}
}

.actions {
	.avatar-sm {
		.avatar-pic {
			@include avatar(21px, 21px, $border-color, 100%);
			text-align: center;
			line-height: 21px;
			font-weight: 600;
			background: $filter-background;
			color: $dark;

			.user-info {
				font-size: 13px;
			}

			> img {
				@include avatar(100%, 100%, $border-color, 100%);
			}
		}
	}

	.clock {
		font-size: 14px;
	}

	.dropdown {
		max-height: 220px;
		top: 29px;
		right: -5px;
		padding: 10px 0;
		transform: translateY(10%);

		&.show {
			transform: translateY(0%);
		}

		&-search-inbox {
			margin: 0 0.5rem;
		}

		.links-wrap {
			max-height: 170px;
			overflow: auto;
		}

		.links-wrap::-webkit-scrollbar {
			-webkit-appearance: auto;
			width: 7px;
			appearance: auto;
		}

		.links-wrap::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, 0.5);
			-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
		}
	}
}

.due-date {
	&-picker {
		position: absolute;
		z-index: 99999;

		@include respond-to(mobile-sm) {
			left: -70px;
		}

		&.pos-top {
			bottom: 15px;
		}

		&.pos-bottom {
			top: 15px;
		}
	}
}

.notes {
	.delete-task-icon {
		color: #8f94ab;
		font-size: 22px;

		&:hover {
			color: #51535a;
		}
	}

	&-form {
		background-color: $white;
		margin: 20px 20px;
		padding: 15px 20px;

		@include respond-to(mobile) {
			margin: 20px 0;
		}

		.top {
			padding: 15px 20px;
		}

		&-container {
			padding: 10px 10px;
			border: 1px solid #e0e0e1;
			background-color: #fafafa;

			.DraftEditor-editorContainer {
				background-color: #fafafa;
			}

			.editor {
				margin: 0;
				box-shadow: none;
				background-color: #fafafa;
				border: none;
			}
		}

		&-actions {
			background-color: $white;
			border-radius: 8px;

			.pad {
				padding: 10px 15px;
			}

			.item {
				@include flexbox();
				justify-content: space-between;
				border-bottom: 1px solid $filter-border;
				padding: 10px 15px;

				.info {
					color: $filter-text;
					font-size: 18px;
				}
			}

			.delete-btn-wrap {
				color: #808080;
				opacity: 0.6;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.inbox-container {
		flex-wrap: wrap;
		height: calc(100vh - 110px);

		.enquires-body {
		}

		.chat-body {
			height: calc(100vh - 214px);
			padding: 1.5rem 1.5rem 6.5rem 0.5rem;
		}

		.deal-top {
		}
	}

	.new {
		padding: 0px 8px;
	}

	.section-spliter {
		width: 100%;
		max-width: 100% !important;
	}
}

.conversation-summary {
	padding-bottom: 2rem;
	margin-bottom: 3rem;
	border-bottom: 1px solid #dadada;
}

.empty-conversation {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.empty-conversation-img {
	width: 400px;
}

@keyframes scaleup {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

.attachment-icon {
	transform: rotate(130deg);
	vertical-align: bottom;
}

.chat-container {
	position: relative;

	.bot-avatar-wrap:hover {
		.tooltip_wrapper {
			display: block !important;
		}
	}
}

.dropdown-background {
	background: $filter-background;
	padding: 2px 10px;
	border-radius: 5px;
}

.add-produt-inbox,
.selected-produt-variables {
	&-bt {
		border-top: 1px solid rgba(218, 218, 218, 0.5);
	}
}

.add-produt-inbox {
	padding: 1.5rem 2.5rem 0;

	h4 {
	}

	.material-icons,
	.material-icons-outlined {
		color: #11a1ae;
		cursor: pointer;
	}

	&-btn {
		border-bottom: 1px solid rgba(218, 218, 218, 0.5);
		cursor: pointer;
	}
}

.selected-produt-variables {
	padding: 2rem 2.5rem 0;

	input {
		background-color: #f3f7f9;
	}

	.product-terms {
		.info {
			color: #11a1ae;
			font-size: 1.6rem;
		}

		&-selection {
			z-index: 9;
			padding-left: 0;
		}
	}
}

.counter-notification {
	position: absolute;
	top: 12px;
	font-size: 11px;
	background: red;
	border-radius: 50%;
	height: auto;
	width: auto;
	color: white;
	right: -17px;
	line-height: 17px;
	display: block;
	text-align: center;
	font-weight: bold;
	min-width: 16px;
}

.counter-move-top {
	top: 6px !important;
	right: -14px !important;
}

.warning-icon {
	width: 70px;
	height: 80px;
}

.light-ruler {
	border-bottom: 1px solid #e4e1e1;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
}

.search-box-container {
	&.mobile {
		width: calc(100% - 0px);
		position: unset;
		top: unset;
		height: 45px;

		.search-wrapper {
			&-full {
				margin-left: 0%;

				.search-wrap {
					width: calc(100% - 22%);
				}
			}
		}
	}

	width: calc(100% - 62px);
	background-color: $white;
	position: fixed;
	z-index: 9999;
	top: 0;
	height: 54px;

	&.short {
		width: 25%;

		.search-wrap {
			margin-left: 15px;
		}
	}

	.search-wrapper {
		@include flexbox();
		width: 100%;
		height: 100%;
		align-items: center;
		margin-left: 15px;

		.search-text {
			color: #777a82;
			margin-left: 10px;
		}

		&-full {
			@include flexbox();
			align-items: center;
			height: 100%;
			margin-left: 26%;

			.search-wrap {
				width: calc(100% - 37.8%);
				border-right: 1px solid #5e7281;
				position: relative;

				&::after {
					top: 9px;
				}

				.search-box {
					width: 100%;
				}
			}

			.card-filter-wrap {
				border: none;

				.card-dropdown-wrap {
					.card-filter-dropdown {
						top: 45px;
					}
				}
			}

			.close {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 18px;
			}

			.category {
				margin-left: 15px;

				.drop-down-icon {
					margin-left: 20px;
				}

				.inbox-auto-complete-category {
					.dropdown {
						width: 166px;
					}
				}
			}
		}
	}
}

.filters-selected-card {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f2f7f9;
	border-top: 1px solid #dedbdb;

	.content {
		@include flexbox();
		justify-content: center;
		align-items: center;
		flex-direction: column;
		line-height: 30px;

		.sub-section {
			@include flexbox();
			justify-content: space-between;
			align-items: center;

			&.center {
				justify-content: center;
			}
		}

		.title {
			font-weight: 700;
			color: black;
			font-size: 15px;
			text-align: center;
		}

		.sub-title {
			font-weight: 600;
			font-size: 13px;
			color: #00abba;
			cursor: pointer;
		}
	}
}

.reply-messages {
	width: 100%;
	min-height: 39px;

	p {
		margin-top: -20px;
	}

	li {
		margin-bottom: -18px;

		> div {
			margin-top: -18px;
		}
	}

	> div {
		&:not(:last-child) {
			margin-bottom: -25px;
		}

		> br {
			display: block;
			/* makes it have a width */
			content: '';
			/* clears default height */
			margin-top: 13px;
		}
	}

	> br {
		display: block;
		/* makes it have a width */
		content: '';
		/* clears default height */
		margin-top: 13px;
	}
}

.attachments-container {
	.attachment-item {
		border: 1px solid #f8f8f8;
		position: relative;
		display: -moz-flex;
		display: flex;
		align-items: center;
		background-color: #ffffff;
		width: fit-content;
		padding: 5px 8px;

		&:not(:last-child) {
			margin-bottom: 5px;
		}

		.logo {
			background: #ffffff;
			border: 1px solid #f8f8f8;
			border-radius: 50%;
			padding: 5px;
			display: -moz-flex;
			display: flex;
			justify-content: center;
			align-items: center;

			.material-icons {
				font-size: 16px;
			}
		}

		.attachment-name {
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			color: #5e7281;
		}
	}
}

.inbox-header-search-container {
	background-color: $white;
	margin: 10px;

	&.top {
		position: fixed;
		padding: 0px;
		margin: 0px;
		width: 24%;
		top: 8px;
		z-index: 25;
		margin-left: 5px;
	}

	.search-wrapper-full {
		.search-wrap {
			.search-box {
				width: 100%;
				background-color: #fbfbfb;
				border: 1px solid #e0e0e1;

				&:hover,
				&:focus {
					border-color: #00abba;

					& + .search-enquiry-category {
						display: block;
					}
				}
			}

			&:hover,
			&:focus {
				.search-enquiry-category {
					display: block;
				}
			}

			&::after {
				content: none;
			}

			.inbox-search-icon {
				position: absolute;
				top: 10px;
				left: 9px;
				font-size: 2rem;
			}

			.search-enquiry-category {
				position: absolute;
				top: 8px;
				right: 10px;
				display: none;

				&:hover,
				&:focus {
					display: block;
				}

				.search-enquiry-wrap {
					.search-selected {
						gap: 5px;

						@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
							gap: 0px;
						}

						.selected-category {
							text-align: right;
							white-space: nowrap;
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;

							@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
								width: 65px;
							}
						}
					}

					.enquiry-filter-dropdown {
						padding: 10px 8px;
						background-color: #fbfbfb;
						width: 158px;
						border-radius: 8px;

						.enquiry-filter-list {
							.enquiry-type-type {
								&__item {
									border-radius: 6px;
									padding: 6px 6px;

									&:hover {
										background-color: #f5f5f5;
									}
								}
							}
						}

						&::before {
							display: none;
						}

						&::after {
							display: none;
						}
					}
				}
			}

			.show {
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				left: -20px;
				top: 26px;
			}
		}

		.inbox-header-search-dropdown {
			height: 340px;
			max-height: 340px;

			.search-list {
				height: 300px;
				max-height: 300px;
				overflow-y: auto;

				.card-filter-dropdown-card {
					padding: 1rem 0.8rem;

					.card-tag {
						white-space: nowrap;
						overflow: hidden;
						max-width: 60px;
						text-overflow: ellipsis;
						padding: 0.2rem 0.5rem;
						font-size: 1.2rem;

						&:not(:last-child) {
							margin-right: 5px;
						}
					}
				}
			}

			.search-filter-footer {
				font-size: 12px;
				color: #777a82;
				border-top: 1px solid #efefef;

				.search-icon {
					font-size: 14px;
					color: #777a82;
					margin: 10px;
				}

				.press-key {
					padding: 0rem 1rem;
					color: #777a82;
					font-weight: 700;
					display: inline-block;
				}
			}
		}
	}
}

.inbox-custom-dropdown-container {
	border-bottom: 1px solid #e8e8e8;

	.inbox-custom-dropdown {
		width: 270px;
		right: 0px;
	}
}

.inbox-custom-calendar {
	width: 250px !important;
}

.email-automation-toolbar {
	margin-top: 10px;
	margin: 0px;
	// background-color: #fafafa;
	border-top: none !important;
	border-bottom: 1px solid #e0e0e1 !important;
	border-bottom-right-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}

.disabled {
	background: rgba(201, 201, 201, 0.19);
	color: #c9c9c9;
}
