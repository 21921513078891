.StripeElement {
	height: 40px;
	padding: 10px 12px;

	color: #32325d;
	background-color: white;
	border: 1px solid #dadada;
	border-radius: 2px;
	transition: border 300ms;
}

input:focus,
.StripeElement--focus {
	border-color: #11a1ae;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}
