.dropdown-container {
	width: 4.5em;
	position: relative;

	.dropdown-header {
		margin-bottom: 0.1em;
		font-weight: 500;
		font-size: 1.5rem;
		padding: 0.9rem 3rem 0.9rem 1.6rem;
		background: #f3f7f9
			url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSI1cHgiIHZpZXdCb3g9IjAgMCAxMCA1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1Ny4xICg4MzA4OCkgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+YXJyb3ctZG93bjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJhcnJvdy1kb3duIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNy4wMDAwMDAsIC0xMC4wMDAwMDApIj4KICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgiIGZpbGwtb3BhY2l0eT0iMC41IiBmaWxsPSIjMDAwMDAwIiBmaWxsLXJ1bGU9Im5vbnplcm8iIHBvaW50cz0iNyAxMCAxMiAxNSAxNyAxMCI+PC9wb2x5Z29uPgogICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aCIgcG9pbnRzPSIwIDAgMjQgMCAyNCAyNCAwIDI0Ij48L3BvbHlnb24+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=)
			right 10px center no-repeat;
		border: 1px solid #dadada;
		color: #393f53;

		@include respond-to(mobile) {
			padding: 9px 26px 9px 10px;
		}
	}

	.dropdown-list-container {
		position: absolute;
		z-index: 100;
		width: 10.5em;
		height: 100px;

		.dropdown-list {
			padding: 0;
			margin: 0;
			padding-left: 1em;
			background: #ffffff;
			border: 2px solid #e5e5e5;
			box-sizing: border-box;
			color: #393f53;
			font-size: 1.3rem;
			font-weight: 500;

			max-height: 70px;
			overflow: auto;
			max-width: 8.5rem;
			border: 1px solid #dadada;

			&:first-child {
				padding-top: 0.8em;
			}

			.listItem {
				list-style: none;
				margin-bottom: 0.8em;
				cursor: pointer;

				&:hover {
					color: #11a1ae;
				}
			}
		}
	}
}
