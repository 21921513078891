.create-bot {
	padding: 0px 20px;
	position: relative;
	bottom: 60px;
	overflow: hidden;
	&__container {
		top: 40px;
		padding-bottom: 50px;
	}
	.ruler-client {
		border-top: 1px solid #dbdbdb;
	}
	.header-section {
		justify-content: center;
		margin: 1.7rem 0;
		.heading-level1 {
			margin-bottom: 0.6rem;
		}
	}
	.left {
		flex-basis: 25%;
	}
	.right {
		background: white;
		padding: 40px 30px 20px 32px;
		border-radius: 10px;
		box-shadow: 0 11px 12px 0 #c4cbc9;
		margin: 0 auto;
		height: calc(100% - 20px);
	}
	.basic-bot-info {
		padding: 5rem 0;
		&.section-body-bot {
			padding: 4rem;
		}
		.pills-removable {
			&__list {
				color: #3856de;
				font-size: 15px;
				line-height: 20px;
				padding: 0.5rem 1rem;
				border-radius: 2px;
				background-color: #e7ebff;
				margin: 0 0.7rem 0.7rem 0;
				> i {
					color: #5e7281;
					margin-left: 0.9rem;
				}
			}
		}
	}
	.type1 {
		margin: 0 !important;
	}
	.bot-builder {
		height: calc(100vh - 166px);
		height: calc(100vh - 150px);
		&__main {
			width: 780px;
			background-color: #fbfbfb;
			border: 1px solid $border-color;
		}
		&__aside,
		&__main {
			.p-title {
				padding: 1.5rem;
				font-size: 1.6rem;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
			.loader-section {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
			}
		}
		&__aside {
			flex-grow: 1;
			@extend %flexbox;
			.tabs-container {
				.active::after {
					background-color: #ec5959;
				}
				.tab:hover::after {
					background-color: #ec5959;
				}
			}
		}
		&__main {
			.settings-body {
				max-height: calc(100vh - 202px);
				overflow-y: auto;
				.card__header {
					i {
						transition: 0.3s ease all;
					}
					&.turn_up {
						i {
							-webkit-transform: rotate(180deg);
							-moz-transform: rotate(180deg);
							-o-transform: rotate(180deg);
							-ms-transform: rotate(180deg);
							transform: rotate(180deg);
						}
					}
				}
			}
		}
		&__menu {
			background: $white;
			position: relative;
			box-shadow: inset -1px 0 1px #dbdbdb;
			height: 100%;
			overflow-y: auto;
			width: 77px;
			.menu-item {
				display: block;
				max-width: 75px;
				font-size: 1.2rem;
				line-height: 1.5rem;
				color: #4b505e;
				padding: 1.6rem 1.2rem;
				text-align: center;
				cursor: pointer;
				background-color: $white;
				word-break: break-all;
				> .material-icons {
					color: #5e7281;
					font-size: 2.3rem;
					margin-bottom: 0.5rem;
				}
				&.active {
					background: $white;
					.material-icons {
						color: $nav-bottom;
					}
				}
				.side-item {
					white-space: nowrap;
					margin-left: -4px;
				}
				.lock-icon-wrapper {
					position: relative;
					.lock-item {
						position: absolute;
						font-size: medium;
						bottom: 1px;
						right: 1px;
					}
				}
			}
		}
		.menu-options {
			position: relative;
			.landing-header {
				z-index: 1;
			}
			&__container {
				width: 415px;
				background-color: rgba(255, 255, 255, 0.2);
			}
			&__header {
				margin-bottom: 1px;
				padding: 9px 20px;
				background: #ffffff;
				box-shadow: 0 2px 4px 0 rgba(155, 154, 154, 0.5);
			}
			&__body {
				height: calc(100vh - 260px);
				padding: 1.2rem;
				overflow-y: auto;
				&.steps-menu {
					height: calc(100vh - 220px);
				}
				.step-list {
					.step {
						&-item {
							position: relative;
							padding: 1.2rem;
							background: $white;
							border: 1px solid #e6e6e6;
							border-radius: 5px;
							margin-bottom: 0.9rem;
							.display-view,
							.edit-view {
								width: calc(100% - 39px);
							}
							&.active {
								color: $primary-brand-color--light;
								position: relative;
								border: 1px solid #e6e6e6;
								box-shadow: -2px 10px 5px -6px rgba(0, 0, 0, 0.3);
								.step {
									&-number {
										background-color: $primary-brand-color--light;
									}
									&-name {
										color: $primary-brand-color--light;
									}
								}
							}
							.more-icon,
							.done-icon {
								color: #5e7281;
								cursor: pointer;
							}
							.done-icon {
								color: $black;
							}
							.drag-icon {
								cursor: move;
							}
							&.disabled {
								cursor: default;
								.step {
									&-number {
										background-color: #cacaca;
										cursor: default;
									}
									&-name {
										color: #cacaca;
										cursor: default;
										&:hover {
											text-decoration: none;
										}
									}
									&-type {
										color: #cacaca;
									}
								}
							}
						}
						&-number {
							@include avatar(29px, 29px, $black, 50%);
							color: $white;
							text-align: center;
							font-size: 1.3rem;
							line-height: 2.9rem;
							font-weight: $medium;
							margin-right: 1rem;
						}
						&-name {
							font-weight: $semibold;
							display: inline-block;
							color: $black;
							cursor: pointer;
							&:hover {
								text-decoration: underline;
							}
						}
						&-type {
							font-size: 1.1rem;
							line-height: 1.4rem;
							color: #81889f;
						}
					}
				}
			}
		}
		.settings {
			&-header {
				.title-wrap {
					padding: 1.5rem 2.5rem;
				}
				.material-icons {
					color: #5e7281;
					font-size: 2rem;
					cursor: pointer;
					margin-left: 1rem;
				}
				.edit-box {
					display: none;
					input {
						min-width: 250px;
					}
				}
				#editHeading:checked + label .edit-box {
					display: flex;
				}
				#editHeading:checked + label .edit-icon {
					display: none;
				}
				#editHeading:checked ~ h4.heading-level4 {
					display: none;
				}
			}
			&-nav {
				box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
				position: relative;
				z-index: 3;
				background: white;
				.step-number {
					width: 31px;
					height: 31px;
					background-color: #000;
					border-radius: 50%;
					color: #fff;
					text-align: center;
					font-size: 1.3rem;
					line-height: 3.2rem;
					font-weight: 500;
					margin-right: 1rem;
					position: absolute;
					top: 10px;
					left: 10px;
				}
				.arrow-right {
					left: 30px;
					position: absolute;
					color: #ec5959;
					top: 15px;
				}
				.menu-item {
					margin-right: 2.5rem;
					color: #1c2e59;
					padding: 15px 0px;
					font-size: 1.5rem;
					cursor: pointer;
					&::after {
						content: '';
						transform: scale(0);
						@include transition(all 0.3s ease);
						transform-origin: center, center;
					}
					&.active {
						font-weight: $semibold;
					}
					&.active,
					&:hover {
						position: relative;
						&::after {
							position: absolute;
							bottom: 0;
							left: 0;
							width: 100%;
							transform: scale(1);
							height: 4px;
							background: $nav-bottom;
						}
					}
				}
			}
		}
		.card {
			padding: 30px;
			min-height: calc(100vh - 202px);
			&-title {
				letter-spacing: 0.6px;
				text-transform: uppercase;
				cursor: pointer;
				margin-bottom: 20px;
			}
		}
	}
}
.show {
	display: block;
}
.hide {
	display: none;
}
