.notification {
	@include respond-to(mobile) {
		padding: 1rem;
	}
	.section-body {
		height: calc(100vh - 182px);
		overflow-y: auto;
	}
	.notify-status-link {
		&__item {
			position: relative;
			color: $primary-text-color;
			font-size: 1.6rem;
			line-height: 21px;
			padding-bottom: 1rem;
			margin-right: 3.5rem;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 4px;
				background-color: #3856de;
				left: 0;
				bottom: 0px;
				opacity: 0;
				visibility: hidden;
			}
			&.active:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&-list {
		&__item {
			@include flexbox();
			@include align-items(center);
			padding: 1.4rem;
			background-color: $white;
			margin: 0.3rem 0;
			&.read-msg {
				background-color: rgba(#d2d2d2, 0.2);
			}
		}
		.msg-cont {
			@include flex-grow(1);
			color: $primary-text-color;
			font-size: 1.4rem;
			line-height: 18px;
			padding-right: 1rem;
		}
		.msg-date {
			color: rgba(#393f53, 0.6);
			font-size: 1.2rem;
			line-height: 15px;
			flex-shrink: 0;
		}
		.msg-delete-icon {
			color: rgba($primary-text-color, 0.6);
			font-size: 2rem;
			margin-left: 1rem;
			cursor: pointer;
			transition: color 300ms;
			&:hover {
				color: rgba($primary-text-color, 0.9);
			}
		}
	}
}
.show_mobile_notification {
	width: 316px !important;
	right: -51px !important;
	@include respond-to(mobile) {
		width: 99vw !important;
	}
}

.notification-unread-icon {
	color: #3856de;
	font-size: 15px;
	margin-left: 1rem;
	cursor: pointer;
	transition: color 300ms;
}

.notifications-container {
	width: 70%;
	@include respond-to(mobile) {
		width: 90% !important;
	}
	margin: 0 auto;
	background-color: #ffffff;
	border-radius: 10px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	.notification_tab_container {
		padding-top: 5px;
		padding-right: 20px;
		padding-left: 20px;

		.tab {
			color: gray;

			&.active {
				color: #1c2e59 !important;
			}

			&.active,
			&:hover {
				position: relative;
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					transform: scale(1);
					height: 2px;
					background: #00abba !important;
				}
			}
		}
	}

	.notification-container {
		overflow: auto;
		height: calc(100vh - 180px);
		@include respond-to(mobile) {
			height: calc(100vh - 240px);
		}
		.notification_main_section {
			.notification_section {
				padding: 15px 20px;
				background-color: rgba(241, 246, 249, 0.39);
				.notification_title {
					font-size: 16px;
					font-weight: 600;
					color: #1c2e59;
				}
			}
		}
	}

	.tab-content {
		gap: 5px;
		.count-wrap {
			width: 21px;
			background: rgba(161, 161, 163, 0.13);
			border-radius: 4px;

			.count {
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				.count-value {
					font-size: 12px;
					font-weight: 600;
					&.active {
						color: #00abba !important;
					}
				}
			}
			.active {
				background: rgba(17, 161, 174, 0.08);
			}
		}
		.active {
			background: rgba(17, 161, 174, 0.08) !important;
		}
	}
}
