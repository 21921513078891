.tooltips {
	$padding: 15px;
	$borderWidth: 1px;
	$border: $white;
	$tipWidth: 13px;
	$tipHeight: 13px;
	$background: #33516a;
	$afterPosition: 9px;
	position: relative;
	@include flexbox();

	.tooltips-container {
		min-width: max-content;
		position: absolute;
		z-index: 1;
		&::after {
			content: '';
			position: absolute;
			width: $tipWidth;
			height: $tipHeight;
			background: $background;
			transform: rotate(45deg);
		}
	}

	.tooltips-top {
		bottom: 75%;
		left: 50%;
		padding-bottom: $padding;
		transform: translateX(-50%);

		&::after {
			border-bottom: $borderWidth solid $border;
			border-right: $borderWidth solid $border;
			bottom: $afterPosition;
			left: 50%;
			transform: translate(-50%) rotate(45deg);
		}

		&-left {
			bottom: 75%;
			padding-bottom: $padding;
			right: 0px;
			transform: translateX(5%);

			&::after {
				border-bottom: $borderWidth solid $border;
				border-right: $borderWidth solid $border;
				bottom: $afterPosition;
				right: 12px;
			}
		}
		&-right {
			bottom: 75%;
			left: 0px;
			padding-bottom: $padding;
			transform: translateX(-10%);

			&::after {
				border-bottom: $borderWidth solid $border;
				border-right: $borderWidth solid $border;
				bottom: $afterPosition;
				left: 12px;
			}

			&-sm {
				bottom: 75%;
				left: 20px;
				padding-bottom: $padding;
				transform: translateX(-10%);

				&::after {
					border-bottom: $borderWidth solid $border;
					border-right: $borderWidth solid $border;
					bottom: $afterPosition;
					left: 12px;
				}
			}
		}
	}

	.tooltips-bottom {
		top: 75%;
		left: 50%;
		padding-top: $padding;
		transform: translateX(-50%);

		&::after {
			border-top: $borderWidth solid $border;
			border-left: $borderWidth solid $border;
			top: $afterPosition;
			left: 50%;
			transform: translate(-50%) rotate(45deg);
		}

		&-right {
			top: 75%;
			left: 0px;
			padding-top: $padding;
			transform: translateX(-6%);
			&::after {
				border-top: $borderWidth solid $border;
				border-left: $borderWidth solid $border;
				top: $afterPosition;
				left: 13px;
			}
		}

		&-left {
			top: 75%;
			padding-top: $padding;
			right: 0px;
			transform: translateX(6%);
			&::after {
				border-top: $borderWidth solid $border;
				border-left: $borderWidth solid $border;
				top: $afterPosition;
				right: 13px;
			}
		}
	}

	.tooltips-left {
		top: 50%;
		right: 75%;
		padding-right: $padding;
		transform: translateY(-50%);

		&::after {
			border-top: $borderWidth solid $border;
			border-right: $borderWidth solid $border;
			top: 37%;
			right: $afterPosition;
		}
	}

	.tooltips-right {
		top: 50%;
		left: 75%;
		padding-left: $padding;
		transform: translateY(-50%);

		&::after {
			border-left: $borderWidth solid $border;
			border-bottom: $borderWidth solid $border;
			top: 37%;
			left: $afterPosition;
		}
	}

	.tooltips-body {
		background: $background;
		color: $white;
		font-size: 12.5px;
		font-weight: $semibold;
		line-height: 1.4;
		padding: 10px;
		text-align: center;
		border: 1px solid $border;
		border-radius: 5px;
		min-width: 60px;
		max-width: 250px;
		word-wrap: break-word;
		.more-links {
			padding: 5px 0;
			text-align: left;
		}
	}

	&.light {
		.tooltips-body {
			border: 1px solid #f5f5f5;
			font-weight: 500 !important;
			background: #fafbff;
			color: black;
			font-size: 1.4rem;
			padding: 5px 10px;
			word-wrap: break-word;
		}
		.tooltips-container {
			&::after {
				content: none;
			}
		}
	}

	.more {
		padding: 6px;
		margin-left: -13px;
		border: 3px solid $background;
		width: 40px;
		height: 40px;
		background: $border;
		border-radius: 50%;
	}
}

@media screen and (max-width: 425px) {
	.tooltips {
		.tooltips-right {
			left: 20%;
		}
	}
}
