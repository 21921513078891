.reset-pwd {
	@extend %flexbox;
	@include align-items(center);
	height: 100vh;
	border-top: 8px solid $primary-brand-color;
	&__login-header {
		width: 448px;
		margin: 100px auto 30px;
		.logo {
			display: block;
		}
	}
	&__container {
		width: 448px;
		padding: 5rem 5rem 10rem;
		border: 1px solid #d8dfff;
		background-color: #fffdfd;
		box-shadow: 0 4px 27px 0 #e3e3e3;
	}
	&__header {
		.heading-level1 {
			color: $primary-text-color;
			font-size: 2.4rem;
			font-weight: $medium;
			line-height: 3.1rem;
		}
	}
	&__body {
		.br-style {
			border-top: 1px solid #d8d9da;
		}
		.forget-pwd-txt {
			color: $primary-text-color;
			font-size: 1.3rem;
			font-weight: $medium;
			line-height: 1.6rem;
			> .link {
				font-size: 1.3rem;
			}
		}
	}
	input,
	.btn {
		font-size: 1.6rem;
		line-height: 1.9rem;
		padding: 1.2rem 1.6rem;
	}
	input::placeholder {
		font-size: 1.6rem;
	}
}
