.loader {
	height: 100%;
	width: 100%;
	margin: auto;
	.loader-icon {
		width: 45px;
		height: 45px;
		animation: rotation 0.8s infinite linear;
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
