.bot-account-edit {
	.section-body {
		padding: 3rem;
	}
}

.info-container {
	position: relative;
	top: -50px;
	.right {
		background: white;
		padding: 40px 30px 40px 32px;
		border-radius: 10px;
		box-shadow: 0 11px 12px 0 #c4cbc9;
		margin: 20px 20px 20px 55px;
		.info-copy-btn-wrap {
			background-color: transparent !important;
		}
		.info-copy-card {
			border: 2px solid #11a1ae;
			background-color: #e6eef4;
		}
		.info-heading {
			font-size: 20px;
			font-weight: 700;
		}
		.info-form-title {
			font-size: 18px !important;
			font-weight: 600 !important;
		}
		.info-form-value {
			font-size: 18px !important;
		}
		.info-form-sub-title {
			font-size: 16px !important;
		}
		.bot-learn-more {
			color: $primary-brand-color--light;
			font-weight: 700;
			i {
				font-size: 16px;
			}
		}
	}
	.enquirybox {
		background: white;
		padding: 30px 40px;
		height: 60vh;
		border-radius: 10px;
		box-shadow: 0 11px 12px 0 #c4cbc9;
		margin: 20px 20px 20px 55px;
		@media only screen and (max-width: 1440px) {
			height: 77vh;
		}
		@media only screen and (min-width: 1600px) and (max-width: 1800px) {
			height: 70vh;
		}
	}
}
