.motion {
	width: 775px;
	.menu-options__body {
		height: auto !important;
		overflow: initial !important;
	}
	&--edit {
		.motion-settings__body {
			padding-bottom: 3rem;
			border-bottom: 1px solid rgba($border-color, 0.5);
		}
	}
	&--view {
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			border-radius: 22px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 30px;
			background-color: $white;
			box-shadow: 0 1px 28px 0 rgba(59, 68, 164, 0.31);
			&:not(:first-of-type) {
				margin-top: 10px;
			}
			&__cont {
				color: var(--primary-text-color);
				font-weight: $medium;
				letter-spacing: 0.2px;
				line-height: 1.9rem;
			}
		}
	}
}
