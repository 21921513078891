.onboard-process {
	padding: 20px;
	overflow-x: auto;
	height: calc(100vh - 50px);
	&-body {
		background: #f8f8f8;
		position: relative;
		margin-top: 0 !important;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 20px;
		.onboard-container {
			position: relative;
			width: 70%;
			@include respond-to(wide-screen) {
				width: 90%;
			}
			@include respond-to(tablet) {
				width: 90%;
			}
			@include respond-to(mobile-ls) {
				width: 90%;
			}
			@include respond-to(mobile) {
				width: 100%;
			}
			.top-section {
				display: flex;
				margin: 23px 20px;
				margin-top: 0;
				padding-top: 26px;
				align-items: center;
				@include respond-to(mobile) {
					flex-direction: column;
					align-items: flex-start;
					padding-top: 22px;
					margin: 23px 0px;
					margin-top: 0;
					padding-top: 0px;
				}
				.right {
					width: 315px;
					font-size: 30px;
					flex-grow: 1;
				}
				.left {
					width: 310px;
					border: 2px solid #f8c674;
					padding: 1rem 1.5rem;
					border-radius: 5px;
					z-index: 10;
					background: #ffffff;
					margin-right: 135px;
					@include respond-to(mobile) {
						width: 280px;
						margin-top: 15px;
					}
					p {
						font-size: 12px;
						font-weight: bold;
						color: #646464;
						margin-bottom: 5px;
					}
					h5 {
						font-weight: bold;
						color: #1c1c1c;
						font-size: 14px;
						line-height: 16px;
					}
				}
			}
			.top-section-img-background {
				width: 140px;
				background-color: #00a4aa;
				display: flex;
				justify-content: center;
				position: absolute;
				height: 150px;
				right: 40px;
				border-radius: 50%;
				top: -70px;
				@include respond-to(mobile) {
					right: 15px;
					width: 114px;
					top: -74px;
				}
			}
			.top-section-img {
				width: 100px;
				position: absolute;
				right: 60px;
				top: -100px;
				@include respond-to(mobile) {
					right: 28px;
					width: 90px;
					top: -100px;
				}
			}
			.card {
				background: #fff;
				border-radius: 10px;
				margin: 0;
				box-shadow: 0 11px 12px 0 #c4cbc9;
				padding: 5rem 3rem;
				width: 100%;
				position: relative;
				@include respond-to(mobile) {
					padding: 3rem 1.5rem;
				}
				&-body {
					.card-container {
						margin-top: 0;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
						.finished {
							margin-left: 40px;
							margin-top: 10px;
							font-weight: $semibold;

							.link {
								color: #142bce;
								width: auto;
							}
						}
						&-section {
							width: 100%;
							position: relative;
							.stage {
								position: relative;
								display: flex;
								align-items: flex-start;
								margin-bottom: 2rem;
								position: relative;
								z-index: 9;
								&:not(:last-child) {
									.progress-bar {
										&::after {
											content: '';
											display: inline-block;
											background: #f0f4f7;
											z-index: 1;
											width: 3px;
											height: 100%;
											position: absolute;
											left: 14px;
											top: 30px;
										}
									}
								}
								.progress-bar {
									margin: 0;
									display: flex;
									justify-content: center;
									align-items: center;
									margin-top: 7px;
									box-sizing: border-box;
									.progress-state {
										@include flexbox();
										justify-content: center;
										align-items: center;
										font-size: 17px;
										color: white;
										background-color: #eae7e7;
										border-radius: 150px;
										-webkit-border-radius: 150px;
										-moz-border-radius: 150px;
										width: 30px;
										height: 30px;
										font-weight: 600;
										text-align: center;
										line-height: 30px;
										position: relative;
										box-sizing: border-box;
										&.active {
											background-color: #5bc553;
											&::after {
												content: '';
												position: absolute;
												left: -4px;
												display: block !important;
												z-index: 999;
												border-radius: 150px;
												-webkit-border-radius: 150px;
												-moz-border-radius: 150px;
												width: 100%;
												height: auto;
												padding: 17px;
												border: 0.2rem solid #5bc553;
												background: transparent;
												margin: auto;
												box-sizing: border-box;
											}
										}
										&.completed {
											background-color: #5bc553;
										}
									}
								}
								&-description {
									border: 2px solid #f4f6f9;
									border-radius: 7px;
									padding: 1.2rem 2rem;
									margin-left: 1.2rem;
									width: 100%;
									@include respond-to(mobile) {
										padding: 1.2rem 1rem;
									}
									&.active {
										border-color: #009db0;
										.expand {
											transform: rotate(90deg) !important;
											color: #009db0;
										}
										.slider-sub-container {
											max-height: max-content !important;
											padding-top: 1.3rem;
										}
										.stage-description-header {
											.title {
												color: #1c1c1c !important;
											}
										}
									}
									&.completed {
										.stage-description-header {
											.title {
												color: #a2a2a2;
											}
										}
									}
									&-header {
										display: flex;
										justify-content: center;
										align-items: center;
										cursor: pointer;
										.title {
											flex: 1;
											font-size: 17px;
											font-weight: bold;
											color: #1c1c1c;
										}
										.content {
											color: #646464;
											font-weight: 600;
										}
										.expand {
											color: #878787;
											transition: all 0.2s ease;
										}
									}
									.slider-sub-container {
										max-height: 0;
										width: 100%;
										overflow: hidden;
										.category {
											@extend %flexbox;
											@include align-items(center);
											padding: 1rem;
											flex: 50%;
											border-radius: 5px;
											border: 2px solid #f4f6f9;
											font-weight: bold;
											font-size: 14px;
											cursor: pointer;
											&:first-child {
												margin-right: 10px;
											}
											> p {
												margin-left: 10px;
											}
											.material-icons {
												font-size: 30px;
											}
											.done {
												display: none;
												margin-left: auto;
												font-weight: bold;
												font-size: 25px;
											}
											&.active {
												border-color: #009db0;
												color: #009db0;
												.done {
													display: block;
												}
											}
										}
										.content {
											margin-top: 10px;
											.title {
												font-size: 1.28rem;
												code {
													font-family: inherit;
												}
											}
											.btn--green {
												color: $white;
												font-weight: bold;
											}
											.watch-video {
												display: flex;
												.material-icons {
													font-size: 2.5rem;
													color: #5fb3b7;
												}
												.desc {
													margin-left: 5px;
													@include flexbox();
													@include justify-content(center);
													flex-direction: column;
													> .title {
														font-weight: bold;
														font-size: 14px;
														line-height: 19px;
														color: #5fb3b7;
													}
													> .sub-title {
														font-size: 12px;
														line-height: 12px;
													}
												}
											}
											&.add-bot {
												margin-top: 20px;
												.script {
													margin-top: 10px;
													background-color: #f0f3f8;
													padding: 1.2rem 2rem;
													font-size: 1.3rem;
													border-radius: 5px;
												}
											}
											&.developer-instructions {
												margin-top: 20px;
												.label {
													color: #d9d9d9;
													font-size: 13px;
													line-height: 19px;
													font-weight: 700;
													@include respond-to(mobile) {
														font-size: 1.45rem;
													}
													&-value {
														border: 2px solid #f0f4f7;
														border-radius: 5px;
														padding: 1rem;
														margin-bottom: 10px;
													}
												}
												.email-text {
													color: rgba(57, 63, 83, 0.9);
													font-size: 13px;
													line-height: 19px;
													padding: 0;
													border: 0;
													min-width: 120px;
													background: transparent;
													width: 100%;
													&::placeholder {
														line-height: 1.5rem;
													}
												}
												.email-pill {
													color: #393f53;
													font-size: 13px;
													line-height: 19px;
													border: 1px solid #e3e3e3;
													border-radius: 3px;
													background-color: #f4f4f4;
													padding: 1px 8px;
													margin-right: 5px;
													margin-bottom: 5px;
													@include respond-to(mobile) {
														font-size: 1.45rem;
													}
													.material-icons {
														font-size: 14px;
														color: #5e7281;
														margin-left: 5px;
													}
												}
												.add-more {
													color: rgba(#393f53, 0.5);
													font-size: 13px;
													line-height: 19px;
													padding: 0;
													border: 0;
													background: transparent;
												}
												.email-content {
													width: 100%;
													padding: 0;
													height: 100px;
													border: none;
												}
											}
											&.invite-team {
												.label {
													color: #d9d9d9;
													font-size: 13px;
													line-height: 19px;
													font-weight: 700;
													@include respond-to(mobile) {
														font-size: 1.45rem;
													}
													&-value {
														border: 2px solid #f0f4f7;
														border-radius: 5px;
														padding: 1rem;
														margin-bottom: 10px;
													}
												}
												.custom-select {
													border: 2px solid #f0f4f7;
													border-radius: 5px;
												}
												.baseline {
													padding-bottom: 1rem;
													margin-bottom: 1rem;
													border-bottom: 2px solid #f0f3f8;
												}
												.title {
													font-size: 1.7rem;
													font-weight: bold;
													color: #1c1c1c;
												}
												.footer {
													@include flexbox();
													justify-content: space-between;
													align-items: center;
													flex-wrap: wrap;
													margin-top: 10px;
												}
												.information {
													font-size: 1.4rem;
													line-height: 20px;
													@include respond-to(mobile-ls) {
														width: 100%;
														margin-bottom: 10px;
													}
													.material-icons {
														border-radius: 50%;
														color: $primary-brand-color--light;
														font-size: 22px;
													}
												}
												.empty-container {
													height: 200px;
													width: 100%;
												}
												.invite-text {
													color: rgba(57, 63, 83, 0.9);
													font-size: 13px;
													line-height: 19px;
													padding: 0;
													border: 0;
													min-width: 120px;
													background: transparent;
													width: 100%;
													&::placeholder {
														font-weight: bolder;
														color: #b8b8b8;
													}
												}
												.invite-btn {
													.add {
														background: $primary-brand-color--light;
														color: $white;
														padding: 0.1rem;
														border-radius: 5px;
													}
												}
												.invites-list {
													max-width: calc(100vw - 135px);
													overflow-x: scroll;
													.table {
														width: 100%;
														tr {
															display: flex;
															justify-content: space-between;
														}
														td {
															&:first-child {
																width: 40%;
																@include respond-to(mobile-ls) {
																	width: 50%;
																}
															}
															&:nth-child(2) {
																width: 40%;
																@include respond-to(mobile-ls) {
																	display: none;
																	width: 0%;
																}
															}
															padding: 10px;
															&:last-child {
																text-align: center;
																width: 20%;
																@include respond-to(mobile-ls) {
																	width: 50%;
																}
															}
														}
													}
												}
												.app_dropdown {
													&__menu-list {
														max-height: 95px;
													}
												}
											}
											&.tryout {
												display: flex;
												.btn--blue {
													font-weight: bold;
												}
												.description {
													padding-right: 50px;
												}
												.left {
													width: 60%;
													@include flexbox();
													flex-direction: column;
													justify-content: space-between;
												}
												.right {
													padding: 0 1rem;
													width: 40%;
													@include flexbox();
													justify-content: center;
													align-items: center;
													> img {
														cursor: pointer;
														width: 100%;
														height: 150px;
														object-fit: cover;
													}
												}
											}
											&.profile-setup {
												display: flex;
												.upload {
													width: 200px;
													height: 200px;
													min-height: 200px;
													min-width: 200px;
													&-profile {
														display: flex;
														flex-direction: column;
														justify-content: center;
														align-items: center;
														width: 100%;
														height: 100%;
														padding: 1rem;
														background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23BCD1EEFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e");
														background-color: #f8fcfd;
														.file-selector {
															text-align: center;
															font-weight: bold;
															padding-left: 3rem;
															padding-right: 3rem;
														}
														.title {
															font-weight: bold;
															font-size: 1.4rem;
														}
														.info {
															font-size: 1.2rem;
															color: #a0a0a1;
															font-weight: $semibold;
														}
														img {
															height: 65px;
															width: 65px;
														}
													}
													&-pic {
														width: 200px;
														height: 200px;
														min-height: 200px;
														min-width: 200px;
														object-fit: cover;
													}
													.change-image {
														position: relative;
														height: 100%;
														.overlay {
															display: none;
															height: 100%;
															width: 100%;
															position: absolute;
															background: rgba(0, 0, 0, 0.4);
															.icon {
																height: 50px;
																width: 50px;
																border-radius: 50%;
																background: #fafafa;
																@include flexbox();
																justify-content: center;
																align-items: center;
																opacity: 0.5;
																cursor: pointer;
																&:hover {
																	opacity: 1;
																}
															}
														}
														&:hover {
															.overlay {
																display: flex;
															}
														}
													}
												}
												.signature {
													@include flexbox();
													@include flex-direction(column);
													padding-left: 2rem;
													width: 100%;
													.title {
														font-weight: bold;
														font-size: 1.4rem;
													}
													.sub-title {
														font-size: 1.2rem;
														color: #a0a0a1;
													}
													&-content {
														background: #fafafa;
														padding: 1rem;
														border: 1px solid #f3f3f3;
													}
													.editor-container .editor-body {
														max-height: 60px;
														min-height: 60px;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.onboard-add-code-help_link-icon {
	font-size: 24px;
	color: #5fb3b7;
}
