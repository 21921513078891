.headway-badge {
	font-weight: $bold;
	color: $primary-brand-color--light;
	width: 100px;
	text-align: right;
	position: absolute;
	right: 0;
	top: -9px;
	.HW_badge_cont {
		right: 0px;
		position: absolute;
		top: -6px;
		width: 100px;
	}
}
