.modal-carousel {
	overflow: unset;

	.btn-prev {
		position: absolute;
		top: 371px;
		left: 62px;
		display: inline-block;
		cursor: pointer;
	}

	.arrow {
		border-radius: 50%;
		font-size: 48px;
		color: #fff;
		background-color: #7c7c7c;
		border: none;
	}

	.btn-next {
		position: absolute;
		float: right;
		display: inline-block;
		cursor: pointer;
		top: 370px;
		right: 52px;
	}

	.carousel-header {
		background-image: none;
		align-items: center;
		float: right;
		display: inline-flex;
		position: absolute;
		top: 30px;
		right: 56px;

		.download-modal {
			background-color: #fff;
			padding: 12px;
			color: gray;
			border-radius: 50%;
		}

		.close-modal {
			color: gray !important;
			border-radius: 50%;
			font-size: 28px;
			padding: 10px;
			background-color: #fff;
		}
	}

	.modal-container {
		background-color: transparent !important;
	}

	.carousel-container {
		position: relative;
		width: 800px;
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%);
		box-shadow: none;
		height: 400px !important;

		.showSlide {
			padding: 0;
			margin-bottom: 0 !important;
			width: 100%;
			height: auto;
			aspect-ratio: 16/9;
		}
	}

	@include respond-to(mobile) {
		.carousel-container {
			height: auto !important;
		}
		.btn-prev {
			top: 490px;
			left: 12px;
			z-index: 1;
		}

		.btn-next {
			top: 490px;
			right: 12px;
		}

		.carousel-header {
			z-index: 1;
			top: 46px;
			right: 26px;
		}
	}
}
