.report-page {
	padding: 30px;
	padding: 20px;
	background-color: white;
	height: 81vh;
	overflow-y: auto;
	.report-page-heading,
	.report-page-sub-heading {
		margin: 2rem 1rem;
		font-weight: bold;
		line-height: 2.3rem;
	}
	.report-page-heading {
		font-size: 1.6rem;
	}
	.report-page-header-card {
		margin-right: 1rem;
		padding: 15px;
		border: 1px solid #e9e9e9;
		cursor: pointer;
		> .header-image {
			width: 150px;
		}
		> .header-description {
			margin-left: 30px;
			margin-top: 10px;
		}
	}
	.report-page-second-header {
		margin: 2rem 1rem;
		&__title {
			font-size: 1.6rem;
			font-weight: 500;
			line-height: 2.3rem;
		}
	}
	.report-page-divider {
		position: relative;
		margin: 10px 10px;
		.report-page-divider-title {
			font-size: 12px;
			font-weight: 600;
			color: #11a1ae;
			margin: 50px 10px;
		}
		.report-page-divider1 {
			width: calc(100% - 200px);
			background-color: #e9e9e9;
			height: 1px;
		}
		.report-page-divider2 {
			width: calc(100% - 100px);
			background-color: #e9e9e9;
			height: 1px;
		}
	}
}

.reporting-stats-card {
	background-color: white;
	border: 1px solid #e9e9e9;
	margin: 1rem;
	.reporting-stats-metrics {
		text-align: center;
		font-size: 34px;
		width: 10%;
		max-width: 150px;
		font-weight: 800;
		border-right: 1px solid #e9e9e9;
		border-bottom: 1px solid #e9e9e9;
		background-color: #ebfffe;
		color: #00695c;
		overflow: auto;
		padding: 0 10px;
		span {
			width: 100%;
		}
	}
	.reporting-stats-description {
		border-bottom: 1px solid #e9e9e9;
		width: 90%;
		padding: 20px;
		font-size: 1.6rem;
	}
	.reporting-stats-increment {
		text-align: center;
		padding: 12px 2px;
		border-right: 1px solid #e9e9e9;
		width: 10%;
		max-width: 150px;
		font-weight: 500;
		> .trend-icon {
			font-size: 12px;
			margin-right: 2px;
			&.success {
				color: #12b13f;
			}
			&.fail {
				color: red;
			}
			&.neutral {
				color: orange;
			}
		}
	}
	.reporting-stats-increment-desc {
		padding: 12px 20px;
		width: 90%;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: 2.3rem;
	}
}

.reporting-sub-menu-card {
	background-color: white;
	border: 1px solid #e9e9e9;
	margin: 1rem;
	width: 100%;
	cursor: pointer;
	.reporting-menu-img-wrapper {
		padding: 15px;
		border-right: 1px solid #e9e9e9;
		background-color: #faffff;
		.reporting-menu-img {
			width: 40px;
		}
	}
	.reporting-menu-description {
		padding: 10px;
		.reporting-menu-description-title {
			font-size: 14px;
			font-weight: 700;
		}
		.reporting-menu-description-sub-title {
			font-size: 12px;
		}
	}
}

.reporting-page-root-header {
	border: 1px solid #e9e9e9;
	padding: 18px;
}

.report-sub-page {
	height: 80vh;
	overflow-y: auto;
	.call_report_page_back {
		border-bottom: 1px solid #e3e9f1;
		padding: 10px 20px;

		i {
			font-size: 30px;
		}
		p {
			font-weight: bold;
		}
	}
	.report-sub-page-graph {
		padding: 30px;
		border-bottom: 1px solid #e3e9f1;
	}
	.report-sub-page-header {
		.report-sub-page-header_top {
			border-bottom: 1px solid #e3e9f1;
			padding: 20px 30px;
			img {
				width: 40px;
			}
			.reporting-menu-page-header-top-t {
				margin-left: 10px;
			}
		}
		.report-sub-page-header_summary {
			padding: 20px 30px;
			border-bottom: 1px solid #e3e9f1;
			background: #f3f7f9;
		}
		.report-sub-page-header_trend {
			padding: 20px 30px;
			border-bottom: 1px solid #e3e9f1;
			.trend_img {
				width: 20px;
				margin-right: 10px;
			}
		}
		.report-sub-page-header_tittle {
			font-weight: bold;
			line-height: 2.3rem;
		}
		.report-sub-page-header-sub-title {
			font-size: 12px;
		}
		.select-wrap {
			width: 200px;
			min-width: 200px;
			.custom-select {
				background-color: #f3f7f9;
				height: 44px;
			}
		}
	}
	.time-of-day {
		margin-top: 50px;
		.days-of-week {
			font-weight: 600;
			font-size: 18px;
			min-width: 100px;
			width: 100px;
			margin-left: 60px;
		}
		.time-of-day-secondary {
			font-size: 1.4rem;
			line-height: 2rem;
			font-weight: 600;
			margin-bottom: 0.5rem;
			opacity: 0.6;
		}
	}
	.time-of-day-view {
		padding: 30px;
		border-bottom: 1px solid #e3e9f1;
		&__table {
			opacity: 0.7;
			thead {
				padding-bottom: 8px;
				border-bottom: 1px solid #e3e9f1;
				th {
					opacity: 0.7;
					padding: 10px 30px;
					text-align: center;
				}
				.day-head {
					text-align: left;
					width: 150px;
					min-width: 150px;
					padding-left: 10px;
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid #e3e9f1;
					text-align: center;
					.border-right {
						border-right: 1px solid #e3e9f1;
					}
					.day-name {
						text-align: left;
						opacity: 1;
						font-weight: 700;
						padding: 10px 10px 10px 10px;
					}
				}
			}
		}
	}
	.report_raw_data_container {
		padding: 30px;
		.report_raw_data {
			.all-bots-table--raw-data {
				width: 100%;
			}
			&_header {
				float: left;
				.view_data_sheet {
					.material-icons {
						font-size: 14px;
					}
				}
			}
		}
	}
}
.report_raw_data_header-btn {
	float: right;
	.material-icons {
		font-size: 15px;
	}
}
.graph_progress_bar {
	height: 40px;
	position: relative;
	width: 100%;
	border-radius: 4px;
	background-color: #f3f7f9;

	.graph_progress_bar_background {
		content: ' ';
		background: #48626f;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		white-space: pre;
		border-radius: 4px;
	}
}
.report-graph-type-1 {
	border: 1px solid #e3e9f1;
	.graph_field_name,
	.graph_field_value,
	.graph_field_percentage {
		padding: 10px;
		border-right: 1px solid #e3e9f1;
	}
	.graph_field_name {
		width: 30%;
		text-transform: capitalize;
		height: 40px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.graph_field_value {
		width: 8%;
	}
	.graph_field_percentage {
		width: 8%;
	}
	.graph_field_bar {
		margin: 10px;
		height: 22px;
		border-radius: 4px;
		width: 64%;
		position: relative;
	}
	.graph_field_bar_background {
		content: ' ';
		background: #53c3cb;
		position: absolute;
		top: 0;
		left: 0;
		height: 22px;
		white-space: pre;
		border-radius: 4px;
	}
}

.show_field_name {
	display: none;
	position: absolute;
	width: max-content;
	background: #fafbff;
	padding: 5px 10px;
	border-radius: 3px;
	color: #000;
	z-index: 1000000;
	transition: all 0.3s ease;
	white-space: initial;
}

.report-graph-type-1 .graph_field_name:hover .show_field_name {
	display: block;
}
