@mixin toast($background, $color) {
	background: $background;
	color: $color;
}

$infoColor: #11a1ae;
$warningColor: #fbbe4d;

.Toastify__toast-container {
	z-index: 99999999 !important;
	.Toastify__toast-body {
		font-weight: 600;
		text-align: center;
	}

	.successToast {
		@include toast($primary-brand-color--light, $white);
	}

	.infoToast {
		@include toast($infoColor, $white);
	}

	.warningToast {
		@include toast($warningColor, $white);
	}

	.errorToast {
		@include toast($red, $white);
	}
}
