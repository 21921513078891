.publish-settings {
	.section-body {
		padding: 3rem;
	}
	&__body {
		.cont-wrap {
			padding-bottom: 2rem;
			border-bottom: 1px solid $border-color;
		}
		.trail-period {
			margin-left: 3.4rem;
		}
	}
	.cont-item-copy {
		position: relative;
		@include flex-direction(column);
		margin-left: 3.4rem;
		.input-label {
			font-weight: $bold;
		}
		.btn-wrap {
			width: 99%;
			height: 65px;
			padding-top: 10px;
			position: absolute;
			left: 1px;
			bottom: 1px;
			background-color: $white;
			z-index: 1;
			> .btn--copy-code {
				position: relative;
				left: 15px;
				width: 134px;
			}
		}
	}
}
