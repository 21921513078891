.app_dropdown {
	&__dropdown-indicator {
		svg {
			path {
				color: #11a1ae;
			}
		}
		.material-icons {
			color: #11a1ae;
		}
	}
	&__control {
		background-color: #f3f7f9 !important;
	}
	&__indicator-separator {
		display: none !important;
	}
	&__menu {
		max-width: 99.8%;
	}
	&__menu-list {
		max-height: 225px;
	}
}

.app_dropdown-no-bg {
	&__dropdown-indicator {
		svg {
			path {
				color: #11a1ae;
			}
		}
		.material-icons {
			color: #11a1ae;
		}
	}
	&__indicator-separator {
		display: none !important;
	}
	&__menu {
		max-width: 99.8%;
	}
	&__menu-list {
		max-height: 225px;
	}
}

.multi-select {
	&-container {
		width: 100%;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		overflow-x: auto;
		margin-bottom: 10px;
		.selected-container {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 5px;
			margin-bottom: 5px;
			border: 1px solid #dadada;
			font-size: 14px;
			background-color: #fbfcfe;
			min-width: -webkit-max-content;
			min-width: -moz-max-content;
			min-width: max-content;
			flex-wrap: nowrap;
			.value {
				color: darken($primary-brand-color--light, 7%);
				font-size: 14px;
				font-weight: 700;
				display: inline-block;
				padding: 5px 10px;
				&-new {
					color: #5e7281;
				}
				&.sm {
					padding: 0px 10px;
					font-size: 12px;
					min-width: 0px;
				}
			}
			.remove {
				color: darken($primary-brand-color--light, 7%);
				font-size: 12px;
				font-weight: 800;
				padding: 5px 6px;
				border-left: 1px solid #dadada;
				&-new {
					color: #5e7281;
				}
				cursor: pointer;
				&.sm {
					padding: 0px 6px;
					font-size: 10px;
				}
			}
			&-light {
				border: 1px solid #f3f7f9;
				background-color: #f3f7f9;
				.value {
					color: darken(#738fa6, 7%) !important;
				}
				.remove {
					color: darken(#738fa6, 7%) !important;
				}
			}
			&-dark {
				background-color: #dbfeff;
				border-radius: 5px;
				border: none;
				.value {
					color: #777a82;
					font-weight: 500;
					padding: 1px 10px;
					padding-right: 1px;
				}
				.remove {
					color: #777a82;
					border: none;
					font-weight: 500;
					font-size: 10px;
					padding: 1px 6px;
					padding-right: 10px;
				}
			}
		}
	}
}
