.report {
	padding: 3rem 3rem;
	.section-header {
		&--raw {
			padding: 1rem 1.5rem;
			border-bottom: 1px solid #dadada;
		}
		.dropdown {
			max-height: 220px;
			top: 30px;
			left: 8px;
			padding: 10px;
			min-width: 180px;
			&::after {
				left: 51px;
				right: initial;
			}
			&::before {
				left: 51px;
				right: initial;
			}
			.links-wrap {
				max-height: 140px;
				overflow: auto;
			}
			&__item {
				white-space: nowrap;
			}
		}

		.public_report {
			padding: 2px 0px 2px 0px;
			right: 0px;
			left: -81px;
			top: 35px;
			height: 35px;
			max-height: 204px;
			min-width: 100px !important;
			&::after {
				left: 80px;
				right: initial;
			}
			&:before {
				left: 80px;
				right: initial;
			}
		}
	}
	.report-share-btn {
		background: #fff;
		height: 38px;
		width: 38px;
		border: 1px solid #dadada;
		border-radius: 8px;
		font-size: 3rem;
		color: #9197ad;
		padding: 2.5px;
		margin-left: 5px;
	}
	.public-report-section {
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 120px);
	}
	.section-header__right {
		.btn {
			font-weight: $regular;
		}
	}
	.section-body {
		height: calc(100vh - 214px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.date {
		position: relative;
		&-picker {
			position: absolute;
			right: 5px;
			top: 9px;
		}
	}
	&-message {
		position: relative;
		.tip {
			display: none;
			background: #fafbff;
			padding: 0px 10px;
			border-radius: 3px;
			color: black;
			z-index: 1000000;
			font-size: 1.2rem;
			right: -20px;
			&.chart {
				bottom: -2px;
			}
		}
		&:hover {
			.tip {
				display: block;
				position: absolute;
			}
		}
	}
	&-card {
		padding: 2rem;
		background-color: $white;
		box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
		&__title {
			color: #9197ad;
			font-size: 1.3rem;
			font-weight: $bold;
			line-height: 16px;
			text-transform: uppercase;
			padding-right: 0.5rem;
		}
		.icon-bg {
			@include avatar(45px, 45px, $secondary-brand-color, 4px);
			flex-shrink: 0;
		}
		.cont-wrap {
			margin-left: 2rem;
			.value {
				color: $primary-text-color;
				font-size: 3.4rem;
				font-weight: $bold;
				line-height: 43px;
			}
		}
		.percent {
			color: $primary-text-color;
			font-size: 1.8rem;
			line-height: 23px;
			margin-left: auto;
			align-self: flex-end;
		}
		&--graph {
			height: 300px;
		}
		.enquiry-channels {
			.channel-label {
				font-size: 14px;
				color: #202d56;
				margin-left: 10px;
				font-weight: 500;
			}
			.channel-count {
				font-weight: 500;
				font-size: 14px;
			}
			.channel_icon {
				width: 20px;
				height: 20px;
			}
		}
		.graph {
			&-body {
				height: calc(100% - 15px);
				&.secondary {
					height: calc(100% - 70px);
				}
				&.scroll {
					overflow-y: auto;
				}
			}
			&-total {
				position: relative;
				.number {
					position: absolute;
					top: 80px;
					left: 10px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					width: 100%;
					.text-container {
						position: relative;
						.text {
							color: #202d56;
							font-size: 3.4rem;
							font-weight: 700;
							line-height: 35px;
						}
						.tip {
							display: none;
							background: #fafbff;
							border: 1px solid #e7ecfd;
							padding: 0px 10px;
							border-radius: 3px;
							color: black;
							z-index: 1000000;
							font-size: 1.2rem;
							position: absolute;
							width: 120px;
							top: 50px;
						}
						&:hover {
							.tip {
								display: block;
							}
						}
					}
					.label {
						font-size: 1.3rem;
						font-weight: 700;
						line-height: 16px;
						color: #9197ad;
					}
				}
			}
			&--bar {
				padding-left: 10px;
				padding-bottom: 10px;
				width: 98%;
			}
		}
		&--type1 {
			min-height: 43px;
		}
	}
	&--type1 {
		.report-card {
			&__title {
				color: $primary-text-color;
				color: #9197ad;
			}
			.cont-wrap {
				margin-left: 0;
			}
			.percent {
				color: #28a25f;
			}
		}
	}
	.raw {
		&-container {
			background: white;
			height: calc(100vh - 100px);
		}
		&-body {
			.raw-item {
				padding: 1rem 1.5rem;
				font-size: 1.6rem;
				font-weight: 500;
			}
		}
	}
}

.legend {
	height: 235px;
	padding-right: 20px;
	&.secondary {
		height: 185px;
		overflow-y: auto;
	}
	&.scroll {
		overflow-y: auto;
	}
	&__items {
		padding-bottom: 10px;
		border-bottom: 1px solid #e5e5e5;
		margin-bottom: 10px;
		&_message {
			position: relative;
			.tip {
				display: none;
				background: #fafbff;
				padding: 0px 10px;
				border-radius: 3px;
				color: black;
				z-index: 1000000;
				font-size: 1.2rem;
			}
			&:hover {
				.tip {
					display: block;
					position: absolute;
					right: 0;
					bottom: -21px;
				}
			}
		}
		.indicator {
			display: inline-block;
			min-width: 15px;
			height: 15px;
			position: relative;
			left: 5px;
			top: 2px;
			&::after {
				content: '';
				display: inline-block;
				width: 7px;
				height: 7px;
				background: white;
				border-radius: 50%;
				position: absolute;
				left: 4px;
				top: 4px;
			}
			&.type1 {
				top: 0;
			}
		}
		.name {
			padding-left: 12px;
			padding-right: 10px;
			position: relative;
			font-weight: 500;
			flex: 3;
			color: $black;
			word-break: break-word;
			max-width: 200px;
			&::before {
				content: '';
				display: inline-block;
				width: 15px;
				height: 15px;
				position: absolute;
				left: 5px;
				top: 2px;
			}
			&::after {
				content: '';
				display: inline-block;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				position: absolute;
				left: 9px;
				top: 6px;
			}
			&--blue {
				&::before {
					background: #173db6;
				}
			}
			&--red {
				&::before {
					background: #ec3c5e;
				}
			}
			&--green {
				&::before {
					background: #54b644;
				}
			}
			&--yellow {
				&::before {
					background: #f9ca47;
				}
			}
		}
		.value {
			color: #1a2955;
			flex: 1;
			word-break: break-all;
			&-percent {
				min-width: 30px;
				color: #4e587a;
				text-align: right;
			}
		}
	}
}

.url-number {
	display: inline-block;
	width: 30px;
	height: 30px;
	min-width: 30px;
	min-height: 30px;
	border-radius: 50%;
	background: #d1d3ff;
	font-weight: bold;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	color: #202d56;
}
.url-link {
	word-break: break-all;
	padding-right: 30px;
	color: #1a2955;
}
.link-wrap {
	&:not(:last-child) {
		margin-bottom: 10px;
	}
}

.graphical-bar {
	&__list {
		width: 100%;
		height: 25px;
	}
	.graph-space {
		height: 100%;
		&--blue {
			background: #173db6;
		}
		&--red {
			background: #ec3c5e;
		}
		&--yellow {
			background: #f9ca47;
		}
		&--green {
			background: #54b644;
		}
	}
}
.pie-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
}

.leaderboard {
	&-wrap {
		padding: 5px 20px;
		margin-bottom: 5px;
	}
	&__user {
		.username {
			display: inline-block;
			width: 18px;
			height: 18px;
			border-radius: 50%;
			background: #511ae6;
			color: white;
			text-align: center;
			line-height: 19px;
			font-weight: 600;
			box-sizing: content-box;
			padding: 2px;
			font-size: 12px;
		}
		.user {
			&-image {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: white;
			}
			&-name {
				font-size: 14px;
				color: #202d56;
				margin-left: 10px;
			}
		}
	}
	&__price {
		width: 28%;
		height: 100%;
		border-left: 1px solid #979797;
		text-align: right;
		font-family: Helvetica;
		font-size: 16px;
		color: #202d56;
		font-weight: bold;
		white-space: nowrap;
		padding-left: 20px;
	}
}

.react-datepicker-popper {
	left: 10px !important;
	top: -7px !important;
}
.react-datepicker__input-container {
	input {
		width: 200px;
		padding-right: 3.5rem;
	}
}
.calendar {
	$font-size-calendar: 1.2rem;
	width: 190px;
	.react-datepicker__triangle {
		left: auto;
		right: 9px !important;
	}
	.react-datepicker__header {
		width: 188px;
		.react-datepicker__current-month {
			font-size: $font-size-calendar;
		}
		.react-datepicker__day-names {
			font-size: $font-size-calendar - 0.1;
		}
		.react-datepicker__day-name {
			display: inline-block;
			width: 2rem;
			line-height: 1.7rem;
			text-align: center;
			margin: 0.2rem;
		}
	}
	.react-datepicker__week {
		font-size: $font-size-calendar - 0.2;
		.react-datepicker__day {
			display: inline-block;
			width: 2.1rem;
			line-height: 2.1rem;
			text-align: center;
			margin: 0.2rem;
		}
	}
}
.tooltip {
	position: absolute;
	right: 0;
	width: 300px;
	max-width: 300px;
	width: max-content;
	background: #fafbff;
	padding: 5px 10px;
	border-radius: 3px;
	color: black;
	z-index: 1000000;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	border: 1px solid #f5f5f5;
	&-wrap {
		position: relative;
		cursor: pointer;
		font-size: 1.3rem;
		.material-icons {
			color: rgba($primary-text-color, 0.6);
			font-size: 18px;
		}
		&:hover {
			.tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	&.left {
		left: 0 !important;
	}
	&.right {
		right: 0 !important ;
	}
	&.top-left {
		&-12 {
			left: 0 !important;
			bottom: 12px !important;
		}
		&-14 {
			left: 0 !important;
			bottom: 14px !important;
		}
		&-16 {
			left: 0 !important;
			bottom: 16px !important;
		}
		&-18 {
			left: 0 !important;
			bottom: 18px !important;
		}
	}
	&.top-right {
		right: 0 !important ;
		&-12 {
			bottom: 12px !important;
		}
		&-14 {
			bottom: 12px !important;
		}
		&-16 {
			bottom: 12px !important;
		}
		&-18 {
			bottom: 12px !important;
		}
	}
}

.ag-row-hover {
	background-color: rgba(#dadada, 0.5) !important;
}
.referrer-url-count {
	display: inline-block;
	width: 30px;
	height: 30px;
	min-width: 30px;
	min-height: 30px;
	border-radius: 50%;
	font-size: 16px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	color: #202d56;
}
.pagination-control {
	background: white;
	border-color: #babfc7 !important;
	border: 1px solid;
	border-top: 0;
	position: relative;
	top: -2px;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}
.report-version-dropdown {
	.dropdown {
		right: 0px !important;
		left: initial !important;
		top: 55px !important;
		overflow: auto;
	}
	.dropdown::before,
	.dropdown::after {
		right: 8px !important;
		left: initial !important;
	}
}

.report_section {
	&__left {
		width: 23%;
		padding: 20px 26px;
		height: 100vh;
		background: #f3f7f9;

		&__header {
			h1 {
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 1rem;
			}
			p {
				font-size: 15px;
				margin-bottom: 1rem;
			}
			&__link {
				font-size: 13px;
				font-weight: bold;

				.link1 {
					color: #53c3cb;
				}
				.link2 {
					color: #8c50f7;
					i {
						font-size: 15px;
					}
				}
			}
		}
		&__body {
			.selected {
				border: 1.8px solid #419fab;
			}
		}
	}
	&__right {
		background: #ffffff;
		width: 78%;
		height: 100vh;
	}
}
.report_basis_card {
	background-color: #ffffff;
	border-radius: 3px;

	&__body {
		padding: 1.8rem 1.2rem;

		&__img {
			width: 70px;
		}

		&__desc {
			color: #393f53;
			h2 {
				font-size: 16px;
			}
			p {
				font-size: 13px;
			}
		}
	}
}
.bot_basis_report {
	&__left {
		width: 18%;
		border-right: 1px solid #e3e9f1;
		height: 100vh;
	}
	&__tabs {
		display: flex;
		flex-direction: column;
		font-weight: bold;
		.selected {
			color: #ffffff !important;
			background: #53c3cb;
		}
		.tabs_nav {
			padding: 1rem 1.5rem;
			color: #393f53;
		}
	}
	&__right {
		width: 82%;
		height: 100vh;
	}
}
