.partner-portal-title {
	padding-left: 3.5rem;
	h1 {
		color: #378daa;
		font-size: 20px;
	}
}
.partner-header-container {
	background-image: linear-gradient(90deg, #11a1ae, #50b8a9);
	padding: 2rem 3rem;

	.avatar-pic {
		@include avatar(50px, 50px, $border-color, 100%);
		text-align: center;
		line-height: 30px;
		font-weight: bold;
		background: #77858d;
		color: white;
		@include flexbox();
		align-items: center;
		justify-content: center;
		.user-info {
			font-size: 18px;
		}
		> img {
			@include avatar(100%, 100%, $border-color, 100%);
		}
	}
	.user-pic {
		border-radius: 50%;
		width: 48px;
		height: 48px;
		margin-right: 1rem;
		object-fit: cover;
	}
	.user-name {
		color: white;
		h1 {
			font-size: 26px;
			font-weight: bold;
			margin-bottom: 0.5rem;
		}
		p {
			font-weight: 500;
			font-size: 18px;
		}
	}
	.header-container-right {
		button {
			color: #378daa;
			font-weight: bold;
		}
	}
}
.partner-portal-section {
	display: flex;
	.content {
		height: calc(100vh - 214px);
	}

	&-full {
		min-width: 100%;
	}

	&-left {
		width: 60%;
		padding: 3rem 2rem 2rem 3rem;
		background: #ffffff;
		height: calc(100vh - 143px);
		.content {
			height: calc(100vh - 214px);
			padding: 2rem 0;
		}
		.tab {
			font-size: 20px;
		}
		.tabs-container {
			.material-icons {
				height: 32px;
				width: 32px;
				background-color: rgba(234, 234, 234, 0.4);
				border-radius: 5px;
				margin-right: 1rem;
				font-size: 2rem;
				color: #5e7281;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}
	}
	&-right {
		width: 40%;
		padding: 3rem 3rem 2rem 3rem;

		.content {
			height: calc(100vh - 214px);
			background: #ffffff;
		}
	}
}
.partner-portal-dashboard-content {
	.card-section {
		table {
			width: 100%;

			td {
				width: 40%;
			}
		}
		.card {
			background-color: #fafcfe;
			border: 1px solid #e9e9e9;
			&-top {
				padding: 1.5rem;
				border-bottom: 1px solid #e9e9e9;
				h1 {
					font-size: 20px;
					font-weight: 600;
				}
				p {
					font-weight: bold;
					color: #3b8640;
				}
			}
			&-bottom {
				padding: 1.5rem;
			}
		}
	}
	.info-card-container {
		display: flex;
		flex-wrap: wrap;
	}
	.info-card {
		padding: 1rem;
		background-color: #fafcfe;
		border: 1px solid #e9e9e9;
		min-width: 160px;
		margin-right: 12px;
		border-radius: 4px;
		margin-bottom: 10px;
		&.active {
			background-color: #e9f3ed;
			border: 1px solid $green;
			.link-title {
				color: $green;
			}
		}
		&.wide {
			min-width: 220px;
		}
		&.medium {
			min-width: 190px;
		}
		.material-icons-info {
			color: #c7d3de;
			font-size: 16px;
		}
		.cont-wrap {
			.title {
				font-size: 13px;
				font-weight: 600;
			}
			.info {
				margin-top: 15px;
				&-value {
					font-size: 22px;
					font-weight: 600;
				}
			}
		}
		.link-title {
			font-size: 1.2rem;
			font-weight: 600;
			margin-left: 15px;
			color: #378daa;
		}
		.number-indicator {
			height: 20px;
			width: 30px;
			padding: 0.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			&.won {
				color: $green;
				font-weight: bold;
				font-size: 1rem;
				background-color: #e9f3ed;
			}
			&.lost {
				color: $red;
				font-weight: bold;
				font-size: 1rem;
				background-color: #ffe0e0;
			}
		}
	}
	.form-info {
		font-size: 1.4rem;
		color: #9a9b9c;
		display: flex;
		justify-content: center;
		&-asterick {
			font-size: 1.8rem;
		}
	}
	.license-tabs-container {
		margin-top: 20px;
		max-height: calc(100vh - 60%);
		.eb-table_coloured .inner-table {
			max-height: calc(100vh - 500px);
		}
	}
	.detail-section {
		margin: 3rem 0;
		&-header {
			font-size: 18px;
			margin-bottom: 2rem;
			font-weight: 600;
		}

		.detail-card {
			border: 1px solid #84c9cf;
			padding: 2rem;
			width: 49%;

			.detail-card-top {
				border-bottom: 1px solid #e9e9e9;
				min-height: 115px;

				display: flex;

				.detail-card-top-left {
					padding-bottom: 2rem;
					min-height: 120px;
					h1 {
						font-weight: 600;
					}
					p {
						color: #767676;
					}
				}
				.detail-card-top-right {
					min-width: 100px;
				}
			}
			.detail-card-bottom {
				margin-top: 1.5rem;
				button,
				a {
					color: #378daa;
					font-weight: bold;
				}
			}
		}
	}
}
.partner-portal-activity-list {
	.empty-list {
		width: 300px;
		text-align: center;
		margin-top: 70px;
		color: #767676;
	}
	.content {
		height: auto;
	}
	.activity-container {
		padding: 0.2rem 0rem 1rem 2.9rem !important;
	}
	.activity-bullet {
		border: 1px solid #e9e9e9 !important;
		background-color: #f0f6f8 !important;
		.material-icons {
			color: #c2d0dd !important;
		}
	}
	.activity-bullet::after {
		left: 9.5px !important;
		background: #f0f6f8 !important;
	}
}
.partner-portal-plan-container {
	height: calc(100vh - 225px);
	padding-bottom: 2rem;
	overflow: auto;
	.partner-portal-plan {
		overflow: scroll;
		&-card {
			width: 385px;
			background-color: #fafcfe;
			border: 1px solid #e9e9e9;
			height: 440px;
			margin-bottom: 5rem;
			position: relative;

			.card-header {
				background-image: linear-gradient(90deg, #11a1ae, #50b8a9);
				padding: 2rem 3rem;
				color: #ffffff;
				h1 {
					font-size: 20px;
				}
				.material-icons {
					font-size: 36px;
				}
			}
			.card-body {
				padding: 0 3rem;

				.body-description {
					padding: 2rem 0;
					font-size: 16px;
					color: #767676;
				}
				.body-benefits {
					font-size: 16px;
					.benifits-comming-soon {
						position: absolute;
						bottom: 0;
						.left-section {
							h1 {
								font-size: 20px;
							}
							button {
								font-size: 18px;
								font-weight: 700;
								color: #378daa;
								margin: 2rem 0;
								padding: 0.9rem 3rem;
								border-color: #378daa;
							}
						}
					}
				}
			}
		}
		&-bottom-card {
			width: 48.5%;
			background-color: #fafcfe;
			border: 1px solid #e9e9e9;
			padding: 2rem 3rem;
			min-height: 200px;

			button {
				font-size: 18px;
				color: #378daa;
				font-weight: 700;
			}

			.bottom-card-header {
				.header-left {
					.material-icons {
						color: #378daa;
					}
					h1 {
						font-size: 18px;
					}
				}
			}
			.bottom-card-body {
				p {
					padding-top: 1rem;
					font-size: 16px;
					color: #767676;
				}
			}
		}
	}
}
.partner-leads-table {
	.request-re-register {
		cursor: pointer;
		font-weight: 700;
		color: #378daa;
	}
}

.partner-portal-modal-container {
	width: 440px;

	.modal__body {
		padding: 3rem 3rem 0 3rem;
		margin-bottom: 0;
	}
	.modal__footer {
		padding-bottom: 3rem;
		.btn {
			min-width: 100px;
			border-radius: 4px;
		}
		.btn--cancel {
			border-color: #11a1ae;
			color: #11a1ae;
		}
	}
}
