.client-profile {
	&--edit {
		.item-wrap {
			.label-heading {
				color: rgba(#393f53, 0.6);
				font-size: 1.3rem;
				font-weight: $medium;
				line-height: 1.6rem;
			}
		}
	}
	&--view {
		.right-sec {
			width: calc(100% - 146px);
			margin-left: 1.8rem;
		}
		.btn--box-wrap {
			@include avatar(40px, 40px, $white, 3px);
			border: 1px solid #dbd9e4;
			> .material-icons {
				color: rgba($black, 0.5);
				font-size: 2rem;
			}
		}
		.header-section {
			margin-bottom: 1.7rem;
			.heading-level1 {
				margin-bottom: 0.6rem;
			}
			.website {
				color: $primary-text-color;
				font-size: 1.4rem;
				line-height: 1.7rem;
				margin-bottom: 0.8rem;
			}
			.add-info {
				color: rgba(#646a7c, 0.8);
				font-size: 1.4rem;
				font-weight: $medium;
				line-height: 1.7rem;
			}
		}
		.table-info {
			border-bottom: 1px solid rgba($border-color, 0.47);
			border-top: 1px solid rgba($border-color, 0.47);
			&__header {
				padding: 1rem 0;
				border-bottom: 1px solid rgba($border-color, 0.47);
				.heading {
					color: $primary-text-color;
					font-size: 1.4rem;
					font-weight: $medium;
					line-height: 1.7rem;
				}
			}
			&__body {
				.heading-level4 {
					margin: 1.8rem 0 2.4rem;
				}
				.cont-item {
					@include flexbox();
					margin-bottom: 1.7rem;
					.title,
					.desc {
						color: #393f53;
						font-size: 1.4rem;
						font-weight: $medium;
						line-height: 2rem;
						width: 50%;
						@include word-wrap();
					}
					.desc {
						color: rgba(#393f53, 0.8);
						padding-left: 2rem;
					}
				}
			}
		}
	}
	.avatar-pic {
		@include avatar(124px, 124px, transparent, 100%);
		> img {
			@include avatar(124px, 124px, transparent, 100%);
			object-fit: cover;
		}
	}
	.border-right {
		border-right: 1px solid rgba($border-color, 0.47);
	}
	.user-pic {
		position: relative;
		@include avatar(124px, 124px, rgba(32, 45, 86, 0.04), 100%);
		border: 1px dashed rgba(32, 45, 86, 0.33);
		cursor: pointer;
		.plus-icon {
			color: $primary-brand-color;
			font-size: 4.5rem;
			text-align: center;
		}
		> img {
			@include avatar(100%, 100%, transparent, 100%);
			object-fit: cover;
		}
	}
}
.billing-info-table {
	width: 100%;
	background: #fff;
	table-layout: fixed;
	tr {
		border-bottom: 1px solid rgba(166, 167, 167, 0.31);
		border-radius: 1px;
	}
}
.billing-info-thead {
	th {
		text-align: left;
		padding: 1rem 2rem;
		word-break: break-word;
		font-weight: 600;
	}
}
.billing-info-tbody {
	td {
		text-align: left;
		padding: 1rem 2rem;
		word-break: break-word;
	}
	.material-icons {
		color: #5e7281;
	}
}
