.pipeline-deal {
	.nav-tertiary {
		display: flex;
		flex-wrap: wrap;
		border-bottom: 1px solid rgba($border-secondary, 0.5);
		&__item {
			position: relative;
			color: #393f53;
			font-size: 1.4rem;
			font-weight: $medium;
			line-height: 1.7rem;
			padding-bottom: 1rem;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0px;
				width: 100%;
				height: 4px;
				background-color: #fbbe4d;
				opacity: 0;
				visibility: hidden;
				transition: opacity 300ms;
			}
			&:not(:last-child) {
				margin-right: 2.5rem;
			}
			&.active {
				font-weight: $bold;
				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
	.section-body {
		height: calc(100vh - 155px);
	}
	.section-container {
		height: 100%;
	}
	.sub-col {
		background: $white;
		height: calc(50% - 10px);
		border-radius: 5px;
		@include respond-to(mobile) {
			height: initial;
		}
		&:first-child {
			margin-bottom: 20px;
		}
		.notes {
			padding-bottom: 1.2rem;
			margin-bottom: 1.2rem;
			border-bottom: 1px solid rgba(#979797, 0.2);
			&__container {
				flex: 0.95;
			}
			&__content {
				font-size: 1.4rem;
				color: #22243d;
				line-height: 2.1rem;
			}
			&__time {
				font-size: 12px;
				color: rgba(#1c2e59, 0.45);
			}
			&-icon {
				font-size: 20px;
				line-height: 32px;
				cursor: pointer;
				color: #78858d;
				&:hover {
					color: #000;
				}
			}
		}
		&.activity-log {
			.notes__content {
				color: rgba(#22243d, 0.7);
				font-weight: 800;
			}
		}
	}
	.section-row {
		width: 100%;
		height: 100%;
		overflow: auto;
		@include respond-to(mobile) {
			@include flex-wrap(wrap);
		}
	}
	.section-col {
		position: relative;
		width: calc(100% / 3 - 20px);
		flex-shrink: 0;
		margin: 0 10px;
		background: $white;
		border-radius: 5px;
		overflow: hidden;
		&--type2 {
			border: none;
			background: none;
		}
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		@include respond-to(mobile) {
			width: 100%;
			margin-right: 0;
		}
		.cont-header {
			padding: 1.5rem 2.5rem;
			height: 48px;
			border-bottom: 1px solid rgba(151, 151, 151, 0.4);
			.heading {
				font-size: 1.4rem;
				color: #393f53;
				text-transform: uppercase;
			}
			.edit-icon {
				display: inline-block;
				width: 32px;
				height: 32px;
				background: #ffffff;
				border: 1px solid #5e7281;
				border-radius: 2px;
				font-size: 20px;
				text-align: center;
				line-height: 32px;
				cursor: pointer;
				color: #5e7281;
			}
			.edit-icon-reply {
				display: inline-block;
				width: 50px;
				height: 32px;
				background: #ffffff;
				border: 1px solid #5e7281;
				border-radius: 2px;
				font-size: 14px;
				text-align: center;
				line-height: 32px;
				cursor: pointer;
				color: #5e7281;
			}
		}
		.cont-body {
			padding: 2.5rem;
			height: calc(100% - 48px);
			overflow: auto;
			@include respond-to(mobile) {
				height: initial;
			}
			.user-details {
				margin: 0.5rem 0 4rem 0;
				padding-bottom: 1.5rem;
				border-bottom: 1px solid rgba(#979797, 0.34);
				.name {
					font-size: 2.2rem;
					color: #393f53;
					font-weight: 600;
					margin-bottom: 0.6rem;
					line-height: 2.8rem;
				}
				.phone,
				.email {
					font-size: 1.4rem;
					color: #393f53;
					@include align-items(flex-end);
					margin-bottom: 0.6rem;
					.material-icons {
						color: #5e7281;
						font-size: 20px;
						margin-right: 15px;
					}
				}
			}
			.user-info {
				margin-top: auto;
				@include avatar(24px, 24px, #78858d, 50%);
				min-width: 24px;
				min-height: 24px;
				text-align: center;
				font-size: 1rem;
				color: #ffffff;
				line-height: 24px;
				font-weight: 600;
				> img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}
			.w-50 {
				width: 50%;
			}
			.label {
				font-size: 1.4rem;
				color: rgba(#393f53, 0.7);
				margin-bottom: 0.6rem;
			}
			.value {
				font-size: 1.4rem;
				color: #393f53;
				word-break: break-all;
			}
			.user-image {
				width: 24px;
				height: 24px;
				min-width: 24px;
				min-height: 24px;
				border-radius: 50%;
				object-fit: cover;
			}
			.custom-fields {
				.head {
					font-size: 1.4rem;
					color: #393f53;
					font-weight: bold;
					padding-bottom: 0.6rem;
					border-bottom: 1px solid rgba(#979797, 0.34);
				}
			}
		}
	}
}

.enquiry-info {
	@include respond-to(mobile) {
		.d-flex-v-center {
			@include flex-wrap(wrap);
		}
		.w-50 {
			width: 100% !important;
		}
	}
}

.btn--save {
	@include respond-to(mobile) {
		margin-left: 16px !important;
	}
}
.popup-modal {
	z-index: 99999;
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	right: 0px;
	top: 0px;
	cursor: default;
}
.popup-modal-container {
	background: white;
	border-radius: 10px;
	box-shadow: 0 1px 12px 0 #000000;
	margin: 0 auto;
	max-height: 700px;
	min-height: 100px;
	width: 50%;
	top: 10%;
	position: relative;
	overflow-y: scroll;
	&-filter {
		width: 40%;
	}
	@include respond-to(mobile) {
		width: 100%;
		top: 0px;
		height: 100%;
		max-height: 100%;
		border-radius: 0px;
	}

	.popup-modal-header {
		color: #11a1ae;
	}

	.modal-list-content {
		max-height: 530px;
		overflow: auto;
	}

	.selected-list-item {
		border-color: #11a1ae !important;
	}

	.option {
		display: flex;
		border: 1px solid #dbd9e4;
		justify-content: space-between;
		padding: 7px;
		border-radius: 9px;

		.option-header {
			padding: 1rem 1.2rem;
		}
	}
}
.popup-modal-overlay {
	opacity: 0.5;
	background-color: #000000;
	position: absolute;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
}
.circular-uncheck-icon {
	background: #616161;
	border-radius: 50%;
	font-size: 12px;
	padding: 4px;
	font-weight: bold;
	color: #fff;
}
.circular-checked-icon {
	background: #12b13f;
	border-radius: 50%;
	font-size: 14px;
	font-weight: bold;
	padding: 3px;
	color: #fff;
}

.pipeline-card-section-separator {
	border-bottom: 1px solid #e9e9e9;
	margin: 10px -25px 10px -15px;
}
.pipeline-card-head {
	span {
		color: #606676;
	}
}
.cont-body-pipeline {
	height: calc(100vh - 170px) !important;
	display: flex;
	flex-wrap: wrap;
	padding: 0 !important;
	margin-top: 1.5rem !important;
}
.card-item-pipeline {
	max-width: 247px;
	box-shadow: none !important;
	border-radius: 6px !important;
	margin-right: 2rem;
	margin-bottom: 1rem;
	width: 243px;
	height: 227px;
	overflow-x: visible;
	@include respond-to(mobile) {
		max-width: 100%;
		width: 100%;
		margin-right: 0;
	}
	.card-body {
		height: 195px;
		overflow-y: auto;
	}
}
.card-item-pipeline:not(:first-of-type) {
	margin-top: 0 !important;
}
.published-pipeline {
	padding-right: 10px;
	padding-bottom: 10px;
}
.br-t-pipeline-2 {
	border-top: 2px solid #e9e9e9;
}
.stage-quick-filter {
	height: 100%;
	margin-right: 10px;
	padding-right: 10px;
	border-right: 1px solid #dadada;
	.filters {
		.filters-dropdown-wrap {
			.drop-down-icon,
			.label-txt {
				color: rgba(57, 63, 83, 0.6) !important;
			}
		}
	}
}
