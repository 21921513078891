.action-main-container {
	.notes-items-container {
		margin-top: 15px;
		background: #fff;
		display: -moz-flex;
		display: flex;

		.right {
			padding: 10px;
			width: 100%;
			padding-right: 40px;
			border-bottom: 2px solid #f3f7f9;

			.note-header {
				display: flex;
				justify-content: space-between;
				margin-top: 3px;

				.note-header-left {
					display: flex;
					align-items: flex-start;

					.value {
						padding-left: 20px;
					}
				}
			}
		}
	}

	.icon-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #f3f7f9;
		border-radius: 50%;
		height: 23px;
		width: 23px;
		min-height: 23px;
		min-width: 23px;
		border: 0.5px solid #eff0f1;
		cursor: pointer;

		.material-icons {
			color: #6d90ad;
		}
	}

	.icon-wrap-high {
		height: 26px;
		width: 26px;
		min-height: 26px;
		min-width: 26px;

		.material-icons {
			font-size: 16px;
		}

		&.active {
			background: #28a25f;
			border: 0.5px solid #eff0f1;

			.material-icons {
				color: #fff;
				font-weight: bold;
			}
		}
	}

	.title-completed {
		color: #8d9199;
		position: relative;
		text-decoration: line-through;
	}

	.value {
		color: #393f53;
		font-size: 15px;
		font-weight: 500;
		line-height: 22px;
	}

	.status-view {
		display: -moz-flex;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 46px;

		.status--pill {
			color: #33516a;
			font-weight: bold;
			font-size: 1rem;
			background-color: #e9f3ed;
			border: 2px solid #28a25f;
			padding: 0px 14px;
			white-space: nowrap;
			overflow: hidden;
		}
		.status--pill--due {
			border: 2px solid #e56369;
			background-color: #fceaeb;
		}
	}

	.expand {
		color: #11a1ae;
		transition: all 0.2s ease;
	}

	.open {
		transform: rotate(90deg);
	}
	.menu-options__body {
		height: calc(100vh - 280px) !important;
	}
}
