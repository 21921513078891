.global-settings {
	.plan-container {
		display: flex;
		flex-wrap: wrap;
		height: calc(100vh - 54px);
		width: calc(100vw - 363px);
		.plan-pro-feature {
			flex-basis: 40%;
		}
		.plan-section {
			flex-basis: 60%;
			border-right: 1px solid #dadada;
		}
		.plan-section,
		.plan-pro-feature {
			overflow-y: scroll;
			height: 100%;
			position: relative;
			padding: 2rem;

			.create-container {
				display: flex;
				align-items: center;
				flex-direction: column;
				margin-top: 25%;
				.create-img {
					height: 150px;
					width: auto;
				}
			}
			&_header {
				justify-content: space-between;
				.header-wrap {
					.head {
						font-size: 1.8rem;
						font-weight: 700;
					}

					.desc {
						font-size: 1.5rem;
						color: rgba(57, 63, 83, 0.8);
						font-weight: 600;
					}

					.feature-head {
						font-size: 16px;
						font-weight: 600;
					}
				}

				.edit-form-wrap {
					border: 1px solid #11a1ae;
					color: #11a1ae;
					padding: 0.9rem 1.6rem;
				}
				.start-plan-wrap {
					display: flex;
					align-items: center;
					height: 30px;
					padding: 1rem 1rem 1rem 0.4rem;
					cursor: pointer;
					background-color: #edfff5;
					border: 1px solid #49a25f;
					border-radius: 2px;

					.play-icon {
						font-weight: 700;
						color: #49a25f;
						font-size: 1.8rem;
					}

					.start-plan {
						font-weight: 700;
						color: #49a25f;
					}
				}
			}

			&_cards {
				width: 100%;
				display: flex;
				overflow: auto;
				.plan-card {
					display: flex;
					flex-direction: column;
					min-width: 190px;
					cursor: pointer;
					border: 2px solid #e9e9e9;
					border-radius: 4px;
					background-color: #f3f7f7;

					.plan-card_header {
						display: flex;
						padding: 0.8rem 1rem;
						justify-content: space-between;

						.plan_name {
							background-color: #54b1bc;
							color: #fff;
							font-weight: 800;
							padding: 4px 10px;
							border-radius: 4px;
							white-space: nowrap;
							text-overflow: ellipsis;
							max-width: 115px;
							overflow: hidden;
							&.active {
								background-color: #49a25f;
							}
						}

						.tooltip-plan-wrap {
							position: static;
							.tooltip-plan {
								top: 1px;
								left: 0px;
								max-width: 450px;
							}
						}
						.icon-check {
							color: #49a25f;
						}
					}

					.plan-card_value {
						display: flex;
						padding: 0.8rem 1rem;
						justify-content: space-between;

						&_wrap {
							.plan-cost {
								font-weight: 600;
								font-size: 18px;
							}

							.plan-duration {
								font-size: 12px;
							}
						}

						&_edit {
							color: #49a25f;
							font-weight: 600;
							cursor: pointer;
						}
					}

					&.active {
						border: 2px solid #49a25f;
						background-color: #edfff5;
					}
				}
			}

			.custom-radio {
				.item {
					.radio-button {
						.icon {
							width: 15px;
							height: 15px;
							min-height: 15px;
							min-width: 15px;
							border: 2px solid;
						}

						.label-txt {
							color: #606676;
						}
					}
				}
			}

			.custom-radio input:checked + label .icon:after {
				content: '';
				width: 13px;
				height: 13px;
				min-height: 13px;
				min-width: 13px;
				background-color: #12b13f;
				border-radius: 100%;
				position: absolute;
				top: -1px;
				left: -1px;
			}

			.input-title {
				font-size: 1.4rem;
				line-height: 2rem;
				font-weight: 600;
			}

			.plan-sections-form {
				.plan-section-input {
					padding: 1.4rem 0;
					.custom-input-select {
						width: 137px !important;
					}
					.input-label-wrap {
						align-items: center;
						position: relative;

						.input-label {
							font-weight: 600;
						}

						.sub-label {
							font-weight: 100;
							font-size: 1.2rem;
							margin-left: 5px;
						}

						.input-field {
							background-color: #f3f7f7;
							line-height: 2.2rem;
							width: 138px;
						}

						.custom-select {
							background-color: #f3f7f7;
						}
						.payment-input-field {
							width: 418px;
							padding: 1rem 9rem 1rem 1.6rem;
						}

						.copy-cta {
							position: absolute;
							right: 20px;
							background-color: #54b1bc;
							color: #fff;
							padding: 4px 18px;
							font-weight: 800;
							cursor: pointer;
							border-radius: 2px;
						}

						.w-200 {
							width: 224px !important;
						}
					}
				}
			}

			.plan-feature-sec {
				.title {
					padding: 1.4rem 0;
					font-size: 1.6rem;
					font-weight: 700;
					color: #11a1ae;
				}

				.plan-feature-item {
					padding-top: 1.4rem;

					.feature-title {
						font-size: 1.5rem;
						font-weight: 600;
					}

					.chat-counter {
						display: flex;
						width: 60px;
						justify-content: space-between;

						.count-btn {
							font-size: 2rem;
							font-weight: 700;
							cursor: pointer;
						}
						.count-value {
							border: 1px solid #dadada;
							border-radius: 2px;
							width: 30px;
							padding: 0px 6px;
							text-align: center;
						}
					}
				}

				.payment-content-wrap {
					padding: 3.4rem 0 2.8rem 0;
					text-align: center;

					.desc {
						font-size: 1.8rem;
					}
				}

				.modal-header-wrap {
					font-size: 18px;
					.header-text {
						font-weight: 700;
					}
				}

				.payment-button-wrap {
					display: flex;
					justify-content: center;

					.btn-free-access {
						color: #11a1ae;
						border: 2px solid #11a1ae;
						border-radius: 4px;
						font-weight: 600;
					}
				}
			}
		}
		.plan-pro-feature {
			&:first-child:after {
				content: '';
				position: absolute;
				bottom: 0;
				right: 0;
				width: 1.2px;
				top: 0px;
				background: #dadada;
				opacity: 0.4;
			}
			.summary-body {
				height: calc(100% - 140px);
				min-height: 425px;
				overflow: auto;

				.summary-price-break {
					padding: 2rem 2rem 0.5rem;
				}
				.field {
					font-weight: 500;
				}
				.field-1 {
					font-weight: 700;
				}
				.field,
				.field-1 {
					color: #777a82;
					padding: 1rem;
				}
				.title {
					padding: 1rem 1rem 0;
					color: #11a1ae;
					font-weight: 700;
				}
				.field,
				.title {
					span {
						color: #777a82;
						font-weight: 400;
					}
				}
				.date {
					display: flex;
					margin-left: auto;
				}
			}
		}

		.pro-feature-sec {
			background: #f3f7f9;

			.tabs-container {
				border-bottom: 1px solid #d8d9da;

				.tab {
					padding: 10px 0px;
					font-size: 1.5rem;
					&.active,
					&:hover {
						color: darken($primary-brand-color--light, 7%);
						&::after {
							background: $primary-brand-color--light;
						}
					}
				}
			}

			.activity {
				&-item {
					margin-top: 10px;
					position: relative;
					.content {
						margin-left: 30px;
						margin-bottom: 15px;
						padding: 10px 15px;
						background: $white;
						box-shadow: 0px 0px 6px 0px rgba(57, 114, 120, 0.18);
					}
					&:not(:last-child) {
						.activity-bullet {
							&::after {
								content: '';
								background: #bbc4c4;
								z-index: 0;
								width: 1px;
								height: 100%;
								position: absolute;
								left: 10px;
								top: 30px;
							}
						}
					}
				}
				&-bullet {
					float: left;
					height: 20px;
					width: 20px;
					margin-top: 10px;
					border: 1px solid $primary-brand-color--light;
					background-color: $comment-background;
					border-radius: 50%;
					@include flexbox();
					justify-content: center;
					align-items: center;
					.material-icons {
						color: $primary-brand-color--light;
						font-size: 13px;
						font-weight: 800;
					}
				}
			}

			.subscription-notes {
				padding-top: 1.5rem;
				height: calc(100% - 50px);
				overflow-y: auto;

				.home {
					height: 100%;
					.no-note {
						height: 100px;
					}
				}
				.notes-list {
					.add {
						width: 100%;
						font-size: 14px;
						font-weight: bold;
						color: darken($primary-brand-color--light, 7%);
						.material-icons {
							font-size: 18px;
						}
					}
					.notes-form {
						margin: 0px;
						margin-top: 20px;
					}
				}
			}

			.active-plans {
				height: calc(100% - 94px);
				margin-top: 10px;
				overflow-y: auto;
				.cards {
					padding: 10px 15px;
					background: $white;
					border: 1px solid #dadada;
					border-radius: 4px;
					@include flexbox();
					justify-content: space-between;
					.information {
						.title {
							font-size: 1.4rem;
							font-weight: $semibold;
							color: $black;
						}
						.tagline {
							font-size: 1.4rem;
						}
						.sub-title {
							font-size: 1.2rem;
							font-weight: $semibold;
							color: darken($primary-brand-color--light, 7%);
						}
						.sub-tagline {
							font-size: 1.2rem;
						}
					}
					.actions {
						cursor: pointer;
						text-transform: lowercase;
						font-size: 1.4rem;
						font-weight: $semibold;
						color: darken($primary-brand-color--light, 7%);
					}
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}
			}
			.pro-feature-list {
				.custom-select {
					font-size: 1.4rem;
					line-height: 2rem;
					padding: 0.9rem;
					background-color: #fff;
					background: #fff right 10px center no-repeat;
					border: 1px solid #dadada;
					border-radius: 2px;
					-webkit-appearance: none;
					position: relative;
					width: 41%;
					background-color: #f3f7f7;
				}
				.field {
					padding-bottom: 14px;
					border-bottom: 1px solid #eff0f1;
				}
			}
		}
		.copy-payment-link {
			.link-label {
				margin-right: 2rem;
				min-width: 105px;
			}
			.link-content {
				border: 1px solid green;
				padding: 1rem;
				min-width: 300px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.link-text {
					width: calc(100% - 55px);
					overflow: auto;
					white-space: nowrap;
				}
				.copy-link-btn {
					background-color: #54b1bc;
					color: #fff;
					font-weight: 800;
					padding: 4px 10px;
					border-radius: 4px;
					float: right;
				}
			}
		}
	}
}

.react-datepicker-popper {
	left: 10px !important;
	top: -7px !important;
}
.react-datepicker__input-container {
	input {
		width: 200px;
		padding-right: 3.5rem;
	}
}
.calendar {
	$font-size-calendar: 1.2rem;
	width: 190px;
	.react-datepicker__triangle {
		left: auto;
		right: 9px !important;
	}
	.react-datepicker__header {
		width: 188px;
		.react-datepicker__current-month {
			font-size: $font-size-calendar;
		}
		.react-datepicker__day-names {
			font-size: $font-size-calendar - 0.1;
		}
		.react-datepicker__day-name {
			display: inline-block;
			width: 2rem;
			line-height: 1.7rem;
			text-align: center;
			margin: 0.2rem;
		}
	}
	.react-datepicker__week {
		font-size: $font-size-calendar - 0.2;
		.react-datepicker__day {
			display: inline-block;
			width: 2.1rem;
			line-height: 2.1rem;
			text-align: center;
			margin: 0.2rem;
		}
	}
}
