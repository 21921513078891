.loader-overlay {
	position: fixed;
	@include flexbox();
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0);
	z-index: 99999999999;
	cursor: default;
	.loader-label {
		color: white;
		font-weight: 600;
		z-index: 99999999999;
	}
	.loader-icon {
		z-index: 99999999999;
	}
}

.loader {
	.lds-ring {
		width: 50px;
		height: 50px;
		div {
			width: 34px;
			height: 34px;
			border-width: 5px;
		}
	}
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid $primary-brand-color--light;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: $primary-brand-color--light transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
