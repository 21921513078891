:root {
	--primary-bg-color: #3856de;
	--secondary-bg-color: #fbbe4d;
	--secondary-bg-light: #fff4e1;
	--primary-text-color: #202d56;
	--secondary-text-color: #fff;
}

.theme-settings {
	.cont-wrap {
		.theme-label {
			color: rgba($primary-text-color, 0.6);
			font-weight: $medium;
			line-height: 1.7rem;
			margin-bottom: 1rem;
		}
		.input-desc {
			color: rgba($primary-text-color, 0.5);
			font-size: 1.5rem;
			font-weight: $medium;
		}
		.theme-color {
			padding: 1.5rem;
			border: 3px solid #cfcccc;
			background-color: var(--primary-bg-color);
			&--input {
				width: 10px;
				height: 10px;
				border-radius: 50%;
			}
			&--bg-secondary {
				background-color: var(--secondary-bg-color);
			}
			&--text-primary {
				background-color: var(--primary-text-color);
			}
			&--text-secondary {
				background-color: var(--secondary-text-color);
			}
		}
		.theme-input {
			position: absolute;
			top: 0;
			width: calc(100% - 50px);
			margin-left: 45px;
			padding: 0.7rem 1rem;
		}
	}
	&__body {
		padding-bottom: 5rem;
		border-bottom: 1px solid rgba(#979797, 0.35);
	}
}
.theme {
	.menu-options--type2 {
		padding: 0 2rem;
		background-color: $white;
		border: 1px solid #dadada;
	}
	.menu-options__body {
		height: calc(100vh - 238px) !important;
		.button-label {
			color: $primary-text-color;
			font-weight: 500;
			font-size: 1.4rem;
			cursor: pointer;
		}
		.delete {
			font-size: 2rem;
			color: #5e7281;
		}
	}
}
.theme-preview {
	width: 450px;
	border: 1px solid #dbdbdb;
	background-color: $white;
	&__header {
		padding: 1.2rem;
		&.cta {
			border-bottom: 1px solid #dadada;
		}
	}
	&__body {
		max-height: calc(100% - 45px);
		overflow: auto;
		height: calc(100% - 97px);
		background-size: cover !important;
		display: flex;
		justify-content: flex-end;
		&.cta {
			min-height: 151px;
			background: none;
			border-bottom: 1px solid #dadada;
			padding: 2rem 2.5rem;
			display: block;
			height: auto;
		}
		.container-widget-header {
			height: 60px;
			padding: 0 1rem;

			.bot-title {
				font-size: 18px;
				color: white;
			}
		}
		.style-text {
			padding-bottom: 70px !important;
			&:focus {
				border-color: #dadada !important;
			}
		}
		.chat-container {
			width: 425px;
			overflow: auto;
			height: 100%;

			background-size: cover !important;
		}
	}
	.item-left,
	.item-right {
		width: 100%;
	}
	.item-right {
		@include flexbox();
		@include justify-content(flex-end);
		.chat-bubble-container {
			width: 100% !important;
			margin-left: 0 !important;
			.chat-bubble {
				border-bottom-left-radius: 22px;
				border-top-left-radius: 22px;
				border-bottom-right-radius: 0;
				background-color: var(--secondary-bg-light);
			}
		}
	}
	.chat-container {
		max-width: 60%;
		.chat-bubble {
			display: inline-block;
			padding: 1.4rem 2rem;
			border-radius: 18px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 18px;
			background-color: $white;
			&:not(:first-of-type) {
				margin-top: 10px;
			}
			&__cont {
				color: var(--primary-text-color);
				font-weight: $medium;
				letter-spacing: 0.2px;
				line-height: 1.9rem;
			}
			&-container {
				width: calc(100% - 56px);
				margin-left: 0.8rem;
			}
		}
	}
	.bot-eb-trigger-container {
		position: absolute;
		bottom: 10px;
		right: 1rem;
	}
	.bot-eb-trigger {
		position: relative;
		cursor: pointer;
		background: var(--primary-bg-color);
		outline: none;
		height: 60px;
		width: 217px;
		opacity: 1;
		border-radius: 40px;
		transition: all 0.3s ease;
		vertical-align: middle;

		.bot-e-text {
			position: absolute;
			top: 0px;
			left: 20px;
			width: 135px;
			opacity: 1;
			display: flex;
			align-items: center;
			height: 55px;
			margin: 0;
			padding: 0;
			box-sizing: border-box;
			.bot-text {
				color: white;
				font-family: proxima-nova, sans-serif !important;
				font-size: 16px;
				font-weight: 500 !important;
				font-style: unset !important;
				text-decoration-style: unset !important;
				-webkit-font-smoothing: antialiased !important;
				-moz-osx-font-smoothing: grayscale !important;
				line-height: 1.4 !important;
			}
		}
	}
	.count {
		display: inline-block;
		height: 20px;
		width: 20px;
		background: rgb(235, 56, 94);
		font-size: 10px;
		border-radius: 50%;
		color: white;
		position: absolute;
		top: 1px;
		right: 1px;
		vertical-align: middle;
		line-height: 20px;
		z-index: 3;
		text-align: center;
	}
	.round-container {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
		width: 50px;
		border-radius: 25px;
		background: rgba(0, 0, 0, 0.4);
		box-sizing: border-box;
		padding: 14px;
		position: absolute;
		top: 6px;
		right: 3px;

		.material-icons {
			color: white;
			font-size: 38px;
		}
	}
	.powered {
		position: absolute;
		background: #eceff1;
		padding: 4px 9px;
		border-radius: 20px;
		min-width: 120px;
		width: -moz-max-content;
		width: -webkit-max-content;
		width: -o-max-content;
		width: -ms-max-content;
		color: var(--pop-over-bg);
		font-size: 12px;
		text-decoration: none;
		font-family: proxima-nova, sans-serif !important;
		font-weight: 600 !important;
		font-style: unset !important;
		text-transform: none !important;
		text-decoration-style: unset !important;
		text-decoration: none !important;
		-webkit-font-smoothing: antialiased !important;
		-moz-osx-font-smoothing: grayscale !important;
		line-height: 1.4 !important;
		transition: all 0.8s ease;
		right: 65px;
		top: 19px;
		z-index: 9;

		.power {
			color: var(--pop-over-bg);
			font-size: 12px;
			font-family: proxima-nova, sans-serif !important;
			display: inline;
			font-style: unset !important;
			text-transform: none !important;
			text-decoration-style: unset !important;
			-webkit-font-smoothing: antialiased !important;
			-moz-osx-font-smoothing: grayscale !important;
			text-decoration: none !important;
		}
		.power::before {
			content: '\26A1';
			margin-left: -1px;
			margin-right: 1px;
			font-size: 10px;
		}
	}
	.btn--sample {
		color: var(--secondary-text-color);
		background-color: var(--primary-bg-color);
		border-radius: 30px;
	}
	.btn--submit {
		width: 80%;
		color: var(--primary-text-color);
		background-color: var(--secondary-bg-color);
		border-radius: 8px;
	}
	.input-wrap {
		position: relative;
		.bot-input-field {
			padding: 1.4rem 5.5rem 1.4rem 5rem;
			background-color: $white;
			border-radius: 30px;
		}
		.email-icon {
			position: absolute;
			left: 15px;
			top: 13px;
			color: rgba($primary-text-color, 0.5);
		}
		.btn-send-msg {
			position: absolute;
			top: 6px;
			right: 6px;
		}
	}
}

// bot image
.bot-avatar-wrap {
	max-width: 48px;
	max-height: 48px;
	img {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		object-fit: cover;
		background-color: var(--primary-bg-color);
	}
}

.color-picker {
	&-wrap {
		position: relative;
	}
	&-component {
		position: absolute;
		top: 0;
		width: 100%;
		opacity: 0;
		height: 100%;
		cursor: pointer;
	}
}

.custom-button {
	width: 100%;
	background-color: #3856de;
	color: #ffffff;
	border-color: #3658b1;
	border-radius: 8px;
	font-size: 15px;
	line-height: 20px;
	font-weight: 500;
	text-align: center;
}
.theme-settings-speed {
	padding: 2rem;
	.speed {
		&-count {
			background-color: #f3f7f9;
			padding: 0.4rem 1.2rem;
			font-weight: 600;
			border: 1px solid #cbd6e2;
			&.active {
				color: #43985b;
				border-color: #43985b !important;
				background-color: #e3f8f0 !important;
			}
		}

		&-tag {
			font-weight: 600;
		}
		&-ruler {
			color: #f1efef;
			width: 100%;
			background-color: #f1efef;
			height: 1px;
			border-width: 0;
			margin: 0 5px;
		}
	}
}

.themes {
	&-body {
		height: 100%;
	}
	.themes-tabs-container {
		background: #fff;
		padding: 0 1rem;
	}
	&-left {
		width: 700px;
		padding: 1.8rem 2rem;
		&-container {
			height: 97%;
			overflow-y: auto;
		}

		.Section {
			background: white;
			box-shadow: 0 0 1px 0 #d0d3e1;
			margin-bottom: 1.5rem;

			.tooltip {
				width: 220px;
			}
			&-title {
				color: #4e4e4e;
				font-size: 1.5rem;
				font-weight: 700;
			}
			&-header {
				padding: 1.2rem 2rem;
				.material-icons {
					color: #11a1ae;
					font-size: 26px;
					transition: all 0.2s ease;
					cursor: pointer;
					&.active {
						transform: rotate(90deg);
					}
				}
			}
			&-body {
				padding: 1.2rem 2rem;
				border-top: 1px solid #f1efef;
				.field {
					padding-bottom: 1.5rem;
					margin-top: 1.5rem;
					.label-title {
						font-size: 1.2rem;
						font-weight: 500;
					}
					.onFocusThumb {
						input[type='range']::-webkit-slider-thumb {
							border: 1px solid #11a1ae !important;
						}
						input[type='range']::-moz-range-thumb {
							border: 1px solid #11a1ae !important;
						}
					}
					.range {
						width: 100%;
						position: relative;
						input[type='range'] {
							width: 100%;
							height: 8px;
							-webkit-appearance: none;
							outline: none;
							border: 10px;
							margin: 8px 0;
							-moz-appearance: none;
							padding: 0;
						}
						.filler {
							pointer-events: none;
							height: 5px;
							background: #11a1ae;
							position: relative;
							top: -14px;
							border-radius: 6px;
							transition: all 0.1s ease;
							box-sizing: border-box;
							opacity: 0;
						}
						.bubble-value {
							background: #555;
							color: #fff;
							padding: 1px 6px;
							position: absolute;
							font-size: 12px;
							font-weight: 700;
							border-radius: 4px;
							left: 50%;
							transform: translateX(-50%);
							z-index: 999;
							opacity: 0;
						}
						.bubble-value:after {
							content: '';
							position: absolute;
							width: 5px;
							height: 5px;
							background: #555;
							top: -3px;
							left: 40%;
							transform: rotate(45deg);
						}

						input[type='range'] {
							width: 100%;
							height: 8px; /* thumbHeight + (2 x thumbBorderWidth)*/
							-webkit-appearance: none; /*remove the line*/
							outline: none;
							border: 10px; /*for firefox on android*/
							margin: 8px 0;
							-moz-appearance: none;
							padding: 0;
						}

						input[type='range']:disabled {
							cursor: none !important;
							-moz-appearance: none;
							-webkit-appearance: none;
						}

						input[type='range']::-webkit-slider-runnable-track {
							background: #ccc; /*trackColor*/
							height: 5px; /*trackHeight*/
							border-radius: 6px; /*trackHeight*/
							transition: 0.3s;
						}

						// slider button
						input[type='range']::-webkit-slider-thumb {
							-webkit-appearance: none;
							width: 18px; /* thumbHeight + (2 x thumbBorderWidth)*/
							height: 18px; /* thumbHeight + (2 x thumbBorderWidth)*/
							border-radius: 50%;
							margin-top: -8px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
							cursor: pointer;
							transition: 0.3s;
							z-index: 10;
							position: relative;
							background: #ffffff;
							border: 1px solid #dcdcdc;
							box-shadow: 0 2px 4px 0 rgba(108, 105, 105, 0.5);
							left: 0;
						}

						input[type='range']::-moz-focus-outer {
							border: 0;
						}

						input[type='range']::-moz-range-track {
							background: #ccc; /*trackColor*/
							position: relative;
							height: 15px; /*trackHeight*/
							border-radius: 4px; /*trackHeight*/
						}

						input[type='range']::-moz-range-track + .filler {
							position: relative;
							display: none;
						}

						input[type='range']::-moz-range-progress {
							background-color: #3856de;
							height: 15px;
							border-radius: 6px;
						}

						input[type='range']::-moz-range-thumb {
							width: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
							height: 28px; /* thumbHeight + (2 x thumbBorderWidth)*/
							border-radius: 50%;
							margin-top: -8px; /* -[thumbHeight + (2 x thumbBorderWidth) - trackHeight]/2*/
							cursor: pointer;
							border: 4px solid #fff; /*border-width should be equal to thumbBorderWidth if you want same border width across all browsers and border-color should match the background*/
							transition: 0.3s;
							z-index: 10;
							position: relative;
							background: #ffffff;
							border: 1px solid #dcdcdc;
							box-shadow: 0 2px 4px 0 rgba(108, 105, 105, 0.5);
						}

						input[type='range']::-moz-focus-outer {
							border: 0;
						}

						input[type='range']::-moz-range-track {
							background: #ccc; /*trackColor*/
							position: relative;
							height: 15px; /*trackHeight*/
							border-radius: 4px; /*trackHeight*/
						}
					}
					input {
						background-color: #f3f7f9;
					}
					.label-desc {
						font-size: 1.2rem;
						color: rgba(57, 63, 83, 0.6);
						font-weight: normal;
					}
				}
			}
		}
	}
}
.preview-page-setting,
.widget-position-setting {
	.preview-page-background-type,
	.widget-position-offset-type {
		.selected {
			border-color: #11a1ae;
			color: #11a1ae;
		}
		button {
			color: #6f6f6f;
			width: 100%;
			font-size: 1.5rem;
			line-height: 2rem;
			font-weight: 500;
			padding: 0.9rem 1.6rem;
			background: #fff;
			border: 1px solid;
			border-color: #dbd9e4;
			border-radius: 2px;
			cursor: pointer;
			transition: all 0.3s ease;
		}
		button:hover {
			border-color: #11a1ae;
			color: #11a1ae;
		}
	}
	.upload-image-btn {
		width: 100%;
		color: #4e4e4e;
		font-size: 1.5rem;
		font-weight: 700;
	}
}
.theme-colours {
	.color-picker-wrap {
		margin-right: -13px;
	}
	.theme-color--input {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		padding: 1.5rem;
		border: 1px solid #cfcccc;
		float: right;
	}
}

.custom-checkbox-16-px {
	.checkbox-icon-wrap {
		width: 16px;
		height: 16px;
		min-height: 10px;
		min-width: 10px;
		position: relative;
	}
	.checkbox-icon-wrap::before {
		content: '\e5ca';
		color: #fff;
		position: absolute;
		font-size: 14px;
	}
}
.custom-radio-16-px {
	.icon {
		width: 16px !important;
		height: 16px !important;
		min-height: 16px !important;
		min-width: 16px !important;
	}
	.icon::after {
		width: 8px !important;
		height: 8px !important;
		min-height: 8px !important;
		min-width: 8px !important;
		top: 2px !important;
		left: 2px !important;
	}
}
.widget-CTA {
	background-color: #f3f7f9;
	border-radius: 20px;
	min-height: 40px;
	padding: 3px;
	p {
		padding: 0px 15px;
	}
}
.widget-icon {
	border-radius: 50%;
	padding: 5px;
}
.widget-chat-icon {
	padding: 2rem 2rem;
	border: 1px solid #f1efef;
	background: #e8e6e6;
	img {
		width: 25px;
		height: 25px;
	}
}
.avatar-section {
	.avatar-list {
		display: flex;
		flex-wrap: wrap;
	}
	.Section-body {
		border: none;
	}
	.upload_avatar {
		background: #53c3cb;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		padding: 13px;
		align-items: center;
		color: #fff;
		max-height: 50px;
	}
	.uploaded-avater {
		.img {
			position: relative;
			cursor: pointer;
			img {
				border-radius: 50%;
				width: 50px;
				height: 50px;
				margin-bottom: 1rem;
				margin-right: 1rem;
				object-fit: cover;
			}
		}
		.img:hover::after {
			content: '';
			background: #2928289e;
			color: #fff;
			position: absolute;
			left: 0;
			padding: 15px;
			border-radius: 50%;
			width: 50px;
			height: 50px;
		}
		.selected::after,
		.selected:hover::after {
			content: '\2713' !important;
			background: #2928289e;
			color: #fff;
			position: absolute;
			left: 0;
			padding: 15px;
			border-radius: 50%;
			font-size: 24px;
			width: 50px;
		}
	}
}
.widget-chat-icon {
	.selected::after,
	.selected:hover::after {
		content: '\2713' !important;
		background: #29282861;
		color: #fff;
		position: absolute;
		left: -3px;
		padding: 9px;
		border-radius: 50%;
		font-size: 16px;
		width: 32px;
		height: 34px;
		top: -4px;
	}
	i:hover::after {
		content: '';
		background: #29282861;
		color: #fff;
		position: absolute;
		left: -3px;
		padding: 9px;
		border-radius: 50%;
		font-size: 16px;
		width: 32px;
		height: 34px;
		top: -4px;
	}
}
.Screen-overlay {
	.Section-body {
		.tooltip-wrap {
			height: 14px;
			.material-icons {
				font-size: 14px;
			}
		}
	}
}
