.eb-table {
	margin-top: 3rem;
	height: initial;
	overflow-y: auto;
	max-height: calc(100vh - 160px);

	.table {
		width: 100%;
		background: #fff;
		table-layout: fixed;

		thead {
			position: relative;
		}
		tr {
			border: 1px solid rgba(166, 167, 167, 0.31);
			border-radius: 1px;
		}
		th,
		td {
			text-align: center;
			padding: 1rem 2rem;
			word-break: break-all;
			&:first-child,
			&:nth-child(2) {
				text-align: left;
			}
		}
		th {
			font-size: 1.3rem;
			text-transform: uppercase;
			font-weight: $medium;
		}
		.table-actions {
			height: 32px;
			width: 32px;
			background-color: rgba(234, 234, 234, 0.4);
			border-radius: 5px;
			margin-right: 1rem;
			font-size: 2rem;
			color: #5e7281;
		}
		.table-actions_no-background {
			border-radius: 5px;
			margin-right: 1rem;
			font-size: 2rem;
			color: #8895a5;
		}
	}

	&_coloured {
		thead {
			background-color: #f3f7f9;
			color: #22243d;
		}
		tr {
			border: 0 !important;
		}
		.inner-table {
			max-height: calc(100vh - 335px);
			overflow-y: auto;
		}
		tr:nth-child(even) {
			background-color: #f3f7f9;
		}
	}
}
