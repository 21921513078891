.variables {
	.bot-builder {
		height: auto !important;
		background-color: transparent !important;
	}
	&__main {
		width: 390px;
		height: 350px;
		padding: 2rem 2.5rem;
		margin-left: 2rem;
		border-radius: 6px;
		background: linear-gradient(132.22deg, #524cb7 0%, #415acc 53.2%, #3856de 100%);
		box-shadow: 0 5px 7px 0 rgba(22, 158, 248, 0.26), 0 2px 3px 0 rgba(176, 173, 173, 0.5);
		overflow: auto;
		.main-heading,
		.sub-heading {
			color: $white;
			font-size: 1.6rem;
			font-weight: $medium;
			line-height: 2.1rem;
		}
		.sub-heading {
			font-size: 1.4rem;
		}
		.desc {
			color: rgba($white, 0.48);
			font-size: 1.4rem;
			line-height: 1.8rem;
		}
	}
}
.menu-options {
	width: 390px;
	&--type2 {
		padding: 0 2rem;
		background-color: $white;
		border: 1px solid $border-color;
		.menu-options__header {
			padding: 1.2rem 0 !important;
			border-bottom: 1px solid $border-color;
			min-height: 65px;
			box-shadow: none !important;
		}
		.menu-options__body {
			padding: 0 !important;
			&.ar {
				overflow-y: scroll;
				height: calc(100vh - 182px);
				max-height: calc(100vh - 182px);
			}
		}
	}
}
.all-bots-table {
	height: initial;
	.action-item-icon2 {
		padding: 6px;
	}
	.action-item-icon {
		padding-top: 6px;
	}
}
.all-bots-table--type2 {
	.table th:first-child,
	.table td:first-child {
		padding-left: 0 !important;
	}
	.table tr {
		border: 0;
		border-bottom: 1px solid $border-color;
	}
	.table-actions {
		width: initial !important;
		height: initial !important;
		background-color: transparent !important;
		transition: color 300ms;
		&:hover {
			color: $primary-text-color;
		}
	}
}

.all-clients-table {
	height: calc(100vh - 246px) !important;
}

.warning-section {
	list-style: inside !important;
	.alert {
		position: relative;
		padding: 1rem 1rem;
		margin-bottom: 1rem;
		border: 1px solid transparent;
		border-radius: 0.25rem;
	}
	.alert-danger {
		color: #842029;
		background-color: #f8d7da;
		border-color: #f5c2c7;
	}
	.variables_name {
		font-weight: bold;
		font-size: 15px;
	}
}
