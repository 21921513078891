.tier {
	.custom-select {
		padding: 0.5rem 3rem 0.5rem 1.6rem;
	}
	.input-text {
		width: 120px;
		height: 32px;
		font-size: 1.4rem;
	}
	.add {
		cursor: pointer;
	}
	.equal-btn {
		width: 90px;
	}
	.percentage {
		position: relative;
		&-holder {
			position: absolute;
			right: 15px;
			top: 7px;
			font-weight: $semibold;
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		.input-text {
			-moz-appearance: textfield;
			&-short {
				padding: 5px 20px 5px 5px;
				width: 70px;
			}
		}
	}
	.btn {
		height: 31px !important;
		padding: 0.5rem 1rem;
		margin-bottom: 1px;
	}
}
.comission-plan-amount-input {
	padding: 0.9rem 2rem !important;
	padding-left: 3.7rem !important;
}
.plan-currency-icon {
	position: absolute;
	top: 11px;
	left: 8px;
	font-weight: 400;
	font-style: normal;
	font-size: 1.4rem;
	text-transform: none;
	line-height: 1.3;
}
.invoice-data {
	font-size: 12px;
	position: relative;
	left: -29px;
	top: 10px;
	width: 530px;
	.div-table {
		.div-thead {
			.div-tr {
				border-top: 2px solid #c2c2c2;
				display: flex;
				.div-th {
					font-weight: bold;
					padding: 5px;
					width: 89px;
				}
			}
		}
		.div-tbody {
			.div-tr {
				border-bottom: 2px solid #c2c2c2;
				display: flex;
				.div-td {
					padding: 5px;
					width: 89px;
					word-break: break-word;
				}
			}
		}
	}
}
